export const kingPowerDataset = [
  {
    id: "4",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9999891519546509,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.19214753806591034,
          y: 0.27039527893066406,
        },
        {
          x: 0.34205830097198486,
          y: 0.27039527893066406,
        },
        {
          x: 0.34205830097198486,
          y: 0.2771236300468445,
        },
        {
          x: 0.19214753806591034,
          y: 0.2771236300468445,
        },
      ],
    },
  },
  {
    id: "5",
    key: "DESCRIPTION",
    resultText: "LE BLANC BRIGHTENING CONCEALER STICK SPF40/PA+++ B10",
    confidence: 0.9999222755432129,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16299821436405182,
          y: 0.3587048053741455,
        },
        {
          x: 0.48839977383613586,
          y: 0.3587048053741455,
        },
        {
          x: 0.48839977383613586,
          y: 0.36543312668800354,
        },
        {
          x: 0.16299821436405182,
          y: 0.36543312668800354,
        },
      ],
    },
  },
  {
    id: "6",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE MELODIEUSE",
    confidence: 0.9999603033065796,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.3839360773563385,
        },
        {
          x: 0.3188578188419342,
          y: 0.3839360773563385,
        },
        {
          x: 0.3188578188419342,
          y: 0.39024388790130615,
        },
        {
          x: 0.16478286683559418,
          y: 0.39024388790130615,
        },
      ],
    },
  },
  {
    id: "7",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE INSAISISSABLE",
    confidence: 0.9999943971633911,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.40958788990974426,
        },
        {
          x: 0.3283759653568268,
          y: 0.40958788990974426,
        },
        {
          x: 0.3283759653568268,
          y: 0.41547518968582153,
        },
        {
          x: 0.16478286683559418,
          y: 0.41547518968582153,
        },
      ],
    },
  },
  {
    id: "8",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE ROUGE BRULANT",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.43523970246315,
        },
        {
          x: 0.3414634168148041,
          y: 0.43523970246315,
        },
        {
          x: 0.3414634168148041,
          y: 0.4411270022392273,
        },
        {
          x: 0.16478286683559418,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "9",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE A DEMI-MOT",
    confidence: 0.9999949932098389,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.460470974445343,
        },
        {
          x: 0.3140987455844879,
          y: 0.460470974445343,
        },
        {
          x: 0.3140987455844879,
          y: 0.4663582742214203,
        },
        {
          x: 0.16478286683559418,
          y: 0.4663582742214203,
        },
      ],
    },
  },
  {
    id: "10",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE ILLUSION",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.4861227869987488,
        },
        {
          x: 0.2980368733406067,
          y: 0.4861227869987488,
        },
        {
          x: 0.2980368733406067,
          y: 0.49201008677482605,
        },
        {
          x: 0.16478286683559418,
          y: 0.49201008677482605,
        },
      ],
    },
  },
  {
    id: "11",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE SUBTILE",
    confidence: 0.999983549118042,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.5117745995521545,
        },
        {
          x: 0.294467568397522,
          y: 0.5117745995521545,
        },
        {
          x: 0.294467568397522,
          y: 0.517241358757019,
        },
        {
          x: 0.16478286683559418,
          y: 0.517241358757019,
        },
      ],
    },
  },
  {
    id: "12",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET LA FAVORITE",
    confidence: 0.9999963045120239,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.5445752739906311,
        },
        {
          x: 0.3622843623161316,
          y: 0.5445752739906311,
        },
        {
          x: 0.3622843623161316,
          y: 0.5508831143379211,
        },
        {
          x: 0.16478286683559418,
          y: 0.5508831143379211,
        },
      ],
    },
  },
  {
    id: "13",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET ROUGE CHARNEL",
    confidence: 0.9999953508377075,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.5702270865440369,
        },
        {
          x: 0.38607972860336304,
          y: 0.5702270865440369,
        },
        {
          x: 0.38607972860336304,
          y: 0.5761143565177917,
        },
        {
          x: 0.16478286683559418,
          y: 0.5761143565177917,
        },
      ],
    },
  },
  {
    id: "14",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET ROUGE FEU",
    confidence: 0.9978722333908081,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.5950378179550171,
        },
        {
          x: 0.35574063658714294,
          y: 0.5950378179550171,
        },
        {
          x: 0.35574063658714294,
          y: 0.6013456583023071,
        },
        {
          x: 0.16478286683559418,
          y: 0.6013456583023071,
        },
      ],
    },
  },
  {
    id: "15",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET ABSTRAIT",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.6206896305084229,
        },
        {
          x: 0.3456276059150696,
          y: 0.6206896305084229,
        },
        {
          x: 0.3456276059150696,
          y: 0.6265769600868225,
        },
        {
          x: 0.16478286683559418,
          y: 0.6265769600868225,
        },
      ],
    },
  },
  {
    id: "16",
    key: "DESCRIPTION",
    resultText: "LES 4 OMBRES TISSE RIVOLI",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.1635930985212326,
          y: 0.6459209322929382,
        },
        {
          x: 0.31171920895576477,
          y: 0.6459209322929382,
        },
        {
          x: 0.31171920895576477,
          y: 0.6522287726402283,
        },
        {
          x: 0.1635930985212326,
          y: 0.6522287726402283,
        },
      ],
    },
  },
  {
    id: "17",
    key: "DESCRIPTION",
    resultText: "LES 4 OMBRES CLAIR-OBSCUR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.674095869064331,
        },
        {
          x: 0.32480666041374207,
          y: 0.674095869064331,
        },
        {
          x: 0.32480666041374207,
          y: 0.6795626282691956,
        },
        {
          x: 0.1641879826784134,
          y: 0.6795626282691956,
        },
      ],
    },
  },
  {
    id: "18",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO COCO",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.7014297842979431,
        },
        {
          x: 0.2718620002269745,
          y: 0.7014297842979431,
        },
        {
          x: 0.2718620002269745,
          y: 0.7068965435028076,
        },
        {
          x: 0.16478286683559418,
          y: 0.7068965435028076,
        },
      ],
    },
  },
  {
    id: "19",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO LEGENDE",
    confidence: 0.9999964237213135,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.7287636399269104,
        },
        {
          x: 0.29089826345443726,
          y: 0.7287636399269104,
        },
        {
          x: 0.29089826345443726,
          y: 0.7342304587364197,
        },
        {
          x: 0.16478286683559418,
          y: 0.7342304587364197,
        },
      ],
    },
  },
  {
    id: "20",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO MARIE",
    confidence: 0.9999973773956299,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.7560975551605225,
        },
        {
          x: 0.27424153685569763,
          y: 0.7560975551605225,
        },
        {
          x: 0.27424153685569763,
          y: 0.761564314365387,
        },
        {
          x: 0.16478286683559418,
          y: 0.761564314365387,
        },
      ],
    },
  },
  {
    id: "21",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO ARTHUR",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.7834314703941345,
        },
        {
          x: 0.2849494218826294,
          y: 0.7834314703941345,
        },
        {
          x: 0.2849494218826294,
          y: 0.788898229598999,
        },
        {
          x: 0.16478286683559418,
          y: 0.788898229598999,
        },
      ],
    },
  },
  {
    id: "22",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO GABRIELLE",
    confidence: 0.9999948740005493,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.8107653260231018,
        },
        {
          x: 0.29922664165496826,
          y: 0.8107653260231018,
        },
        {
          x: 0.29922664165496826,
          y: 0.8162321448326111,
        },
        {
          x: 0.16478286683559418,
          y: 0.8162321448326111,
        },
      ],
    },
  },
  {
    id: "23",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO MICHELE",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.8372582197189331,
        },
        {
          x: 0.2879238426685333,
          y: 0.8372582197189331,
        },
        {
          x: 0.2879238426685333,
          y: 0.8435660004615784,
        },
        {
          x: 0.16478286683559418,
          y: 0.8435660004615784,
        },
      ],
    },
  },
  {
    id: "24",
    key: "DESCRIPTION",
    resultText: "OMBRE PREMIERE LAQUE VASTNESS",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.8654331564903259,
        },
        {
          x: 0.35930994153022766,
          y: 0.8654331564903259,
        },
        {
          x: 0.35930994153022766,
          y: 0.8713204264640808,
        },
        {
          x: 0.1641879826784134,
          y: 0.8713204264640808,
        },
      ],
    },
  },
  {
    id: "25",
    key: "INVOICE_NO",
    resultText: "SIN0064393",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8185603618621826,
          y: 0.15054668486118317,
        },
        {
          x: 0.8762641549110413,
          y: 0.15054668486118317,
        },
        {
          x: 0.8762641549110413,
          y: 0.15643398463726044,
        },
        {
          x: 0.8185603618621826,
          y: 0.15643398463726044,
        },
      ],
    },
  },
  {
    id: "26",
    key: "ITEM",
    resultText: "0134650",
    confidence: 0.9999955892562866,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.3591253161430359,
        },
        {
          x: 0.11362284421920776,
          y: 0.3591253161430359,
        },
        {
          x: 0.11362284421920776,
          y: 0.3645921051502228,
        },
        {
          x: 0.06960142403841019,
          y: 0.3645921051502228,
        },
      ],
    },
  },
  {
    id: "27",
    key: "ITEM",
    resultText: "0160136",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.3843565881252289,
        },
        {
          x: 0.11362284421920776,
          y: 0.3843565881252289,
        },
        {
          x: 0.11362284421920776,
          y: 0.38982337713241577,
        },
        {
          x: 0.06900654733181,
          y: 0.38982337713241577,
        },
      ],
    },
  },
  {
    id: "28",
    key: "ITEM",
    resultText: "0160152",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.40958788990974426,
        },
        {
          x: 0.11362284421920776,
          y: 0.40958788990974426,
        },
        {
          x: 0.11362284421920776,
          y: 0.41505467891693115,
        },
        {
          x: 0.06960142403841019,
          y: 0.41505467891693115,
        },
      ],
    },
  },
  {
    id: "29",
    key: "ITEM",
    resultText: "0160191",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.4356602132320404,
        },
        {
          x: 0.11302796006202698,
          y: 0.4356602132320404,
        },
        {
          x: 0.11302796006202698,
          y: 0.4411270022392273,
        },
        {
          x: 0.06960142403841019,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "30",
    key: "ITEM",
    resultText: "0160196",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.4608915150165558,
        },
        {
          x: 0.11362284421920776,
          y: 0.4608915150165558,
        },
        {
          x: 0.11362284421920776,
          y: 0.4663582742214203,
        },
        {
          x: 0.06960142403841019,
          y: 0.4663582742214203,
        },
      ],
    },
  },
  {
    id: "31",
    key: "ITEM",
    resultText: "0160206",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.07019630819559097,
          y: 0.4861227869987488,
        },
        {
          x: 0.11362284421920776,
          y: 0.4861227869987488,
        },
        {
          x: 0.11362284421920776,
          y: 0.49158957600593567,
        },
        {
          x: 0.07019630819559097,
          y: 0.49158957600593567,
        },
      ],
    },
  },
  {
    id: "32",
    key: "ITEM",
    resultText: "0160211",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5121951103210449,
        },
        {
          x: 0.11124330759048462,
          y: 0.5121951103210449,
        },
        {
          x: 0.11124330759048462,
          y: 0.5168208479881287,
        },
        {
          x: 0.06960142403841019,
          y: 0.5168208479881287,
        },
      ],
    },
  },
  {
    id: "33",
    key: "ITEM",
    resultText: "0162430",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5449957847595215,
        },
        {
          x: 0.11302796006202698,
          y: 0.5449957847595215,
        },
        {
          x: 0.11302796006202698,
          y: 0.550462543964386,
        },
        {
          x: 0.06960142403841019,
          y: 0.550462543964386,
        },
      ],
    },
  },
  {
    id: "34",
    key: "ITEM",
    resultText: "0162560",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5702270865440369,
        },
        {
          x: 0.11362284421920776,
          y: 0.5702270865440369,
        },
        {
          x: 0.11362284421920776,
          y: 0.5756938457489014,
        },
        {
          x: 0.06960142403841019,
          y: 0.5756938457489014,
        },
      ],
    },
  },
  {
    id: "35",
    key: "ITEM",
    resultText: "0162570",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.07019630819559097,
          y: 0.5958788990974426,
        },
        {
          x: 0.11421772837638855,
          y: 0.5958788990974426,
        },
        {
          x: 0.11421772837638855,
          y: 0.6013456583023071,
        },
        {
          x: 0.07019630819559097,
          y: 0.6013456583023071,
        },
      ],
    },
  },
  {
    id: "36",
    key: "ITEM",
    resultText: "0162690",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.6206896305084229,
        },
        {
          x: 0.11362284421920776,
          y: 0.6206896305084229,
        },
        {
          x: 0.11362284421920776,
          y: 0.6265769600868225,
        },
        {
          x: 0.06960142403841019,
          y: 0.6265769600868225,
        },
      ],
    },
  },
  {
    id: "37",
    key: "ITEM",
    resultText: "0164226",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.6467620134353638,
        },
        {
          x: 0.11421772837638855,
          y: 0.6467620134353638,
        },
        {
          x: 0.11421772837638855,
          y: 0.6522287726402283,
        },
        {
          x: 0.06900654733181,
          y: 0.6522287726402283,
        },
      ],
    },
  },
  {
    id: "38",
    key: "ITEM",
    resultText: "0164308",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.6736753582954407,
        },
        {
          x: 0.11302796006202698,
          y: 0.6736753582954407,
        },
        {
          x: 0.11302796006202698,
          y: 0.6791421175003052,
        },
        {
          x: 0.06960142403841019,
          y: 0.6791421175003052,
        },
      ],
    },
  },
  {
    id: "39",
    key: "ITEM",
    resultText: "0172416",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7010092735290527,
        },
        {
          x: 0.11302796006202698,
          y: 0.7010092735290527,
        },
        {
          x: 0.11302796006202698,
          y: 0.7064760327339172,
        },
        {
          x: 0.06960142403841019,
          y: 0.7064760327339172,
        },
      ],
    },
  },
  {
    id: "40",
    key: "ITEM",
    resultText: "0172428",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7291842103004456,
        },
        {
          x: 0.11362284421920776,
          y: 0.7291842103004456,
        },
        {
          x: 0.11362284421920776,
          y: 0.7338099479675293,
        },
        {
          x: 0.06960142403841019,
          y: 0.7338099479675293,
        },
      ],
    },
  },
  {
    id: "41",
    key: "ITEM",
    resultText: "0172430",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7556770443916321,
        },
        {
          x: 0.11362284421920776,
          y: 0.7556770443916321,
        },
        {
          x: 0.11362284421920776,
          y: 0.7611438035964966,
        },
        {
          x: 0.06960142403841019,
          y: 0.7611438035964966,
        },
      ],
    },
  },
  {
    id: "42",
    key: "ITEM",
    resultText: "0172440",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7830109596252441,
        },
        {
          x: 0.11362284421920776,
          y: 0.7830109596252441,
        },
        {
          x: 0.11362284421920776,
          y: 0.788898229598999,
        },
        {
          x: 0.06960142403841019,
          y: 0.788898229598999,
        },
      ],
    },
  },
  {
    id: "43",
    key: "ITEM",
    resultText: "0172444",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.8107653260231018,
        },
        {
          x: 0.11302796006202698,
          y: 0.8107653260231018,
        },
        {
          x: 0.11302796006202698,
          y: 0.8158116340637207,
        },
        {
          x: 0.06960142403841019,
          y: 0.8158116340637207,
        },
      ],
    },
  },
  {
    id: "44",
    key: "ITEM",
    resultText: "0172468",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.8380992412567139,
        },
        {
          x: 0.11421772837638855,
          y: 0.8380992412567139,
        },
        {
          x: 0.11421772837638855,
          y: 0.8435660004615784,
        },
        {
          x: 0.06960142403841019,
          y: 0.8435660004615784,
        },
      ],
    },
  },
  {
    id: "45",
    key: "ITEM",
    resultText: "0175032",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.8658536672592163,
        },
        {
          x: 0.11481261253356934,
          y: 0.8658536672592163,
        },
        {
          x: 0.11481261253356934,
          y: 0.8713204264640808,
        },
        {
          x: 0.06960142403841019,
          y: 0.8713204264640808,
        },
      ],
    },
  },
  {
    id: "46",
    key: "MADE_IN",
    resultText: "JP",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8857823014259338,
          y: 0.3591253161430359,
        },
        {
          x: 0.898274838924408,
          y: 0.3591253161430359,
        },
        {
          x: 0.898274838924408,
          y: 0.36501261591911316,
        },
        {
          x: 0.8857823014259338,
          y: 0.36501261591911316,
        },
      ],
    },
  },
  {
    id: "47",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.38477712869644165,
        },
        {
          x: 0.898274838924408,
          y: 0.38477712869644165,
        },
        {
          x: 0.898274838924408,
          y: 0.38982337713241577,
        },
        {
          x: 0.8839976191520691,
          y: 0.38982337713241577,
        },
      ],
    },
  },
  {
    id: "48",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.41000840067863464,
        },
        {
          x: 0.898274838924408,
          y: 0.41000840067863464,
        },
        {
          x: 0.898274838924408,
          y: 0.41547518968582153,
        },
        {
          x: 0.8845925331115723,
          y: 0.41547518968582153,
        },
      ],
    },
  },
  {
    id: "49",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.4356602132320404,
        },
        {
          x: 0.898274838924408,
          y: 0.4356602132320404,
        },
        {
          x: 0.898274838924408,
          y: 0.4411270022392273,
        },
        {
          x: 0.8845925331115723,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "50",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.4608915150165558,
        },
        {
          x: 0.898274838924408,
          y: 0.4608915150165558,
        },
        {
          x: 0.898274838924408,
          y: 0.4663582742214203,
        },
        {
          x: 0.8845925331115723,
          y: 0.4663582742214203,
        },
      ],
    },
  },
  {
    id: "51",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.48654332756996155,
        },
        {
          x: 0.898274838924408,
          y: 0.48654332756996155,
        },
        {
          x: 0.898274838924408,
          y: 0.49201008677482605,
        },
        {
          x: 0.8845925331115723,
          y: 0.49201008677482605,
        },
      ],
    },
  },
  {
    id: "52",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.5117745995521545,
        },
        {
          x: 0.898274838924408,
          y: 0.5117745995521545,
        },
        {
          x: 0.898274838924408,
          y: 0.517241358757019,
        },
        {
          x: 0.8839976191520691,
          y: 0.517241358757019,
        },
      ],
    },
  },
  {
    id: "53",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5449957847595215,
        },
        {
          x: 0.898274838924408,
          y: 0.5449957847595215,
        },
        {
          x: 0.898274838924408,
          y: 0.550462543964386,
        },
        {
          x: 0.8845925331115723,
          y: 0.550462543964386,
        },
      ],
    },
  },
  {
    id: "54",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5706475973129272,
        },
        {
          x: 0.898274838924408,
          y: 0.5706475973129272,
        },
        {
          x: 0.898274838924408,
          y: 0.5761143565177917,
        },
        {
          x: 0.8845925331115723,
          y: 0.5761143565177917,
        },
      ],
    },
  },
  {
    id: "55",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.5958788990974426,
        },
        {
          x: 0.898274838924408,
          y: 0.5958788990974426,
        },
        {
          x: 0.898274838924408,
          y: 0.6013456583023071,
        },
        {
          x: 0.8839976191520691,
          y: 0.6013456583023071,
        },
      ],
    },
  },
  {
    id: "56",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.621110200881958,
        },
        {
          x: 0.898274838924408,
          y: 0.621110200881958,
        },
        {
          x: 0.898274838924408,
          y: 0.6265769600868225,
        },
        {
          x: 0.8845925331115723,
          y: 0.6265769600868225,
        },
      ],
    },
  },
  {
    id: "57",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999998807907104,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.888161838054657,
          y: 0.6467620134353638,
        },
        {
          x: 0.898274838924408,
          y: 0.6467620134353638,
        },
        {
          x: 0.898274838924408,
          y: 0.6518082618713379,
        },
        {
          x: 0.888161838054657,
          y: 0.6518082618713379,
        },
      ],
    },
  },
  {
    id: "58",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.674095869064331,
        },
        {
          x: 0.898274838924408,
          y: 0.674095869064331,
        },
        {
          x: 0.898274838924408,
          y: 0.6791421175003052,
        },
        {
          x: 0.8887566924095154,
          y: 0.6791421175003052,
        },
      ],
    },
  },
  {
    id: "59",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.7014297842979431,
        },
        {
          x: 0.898274838924408,
          y: 0.7014297842979431,
        },
        {
          x: 0.898274838924408,
          y: 0.7068965435028076,
        },
        {
          x: 0.8845925331115723,
          y: 0.7068965435028076,
        },
      ],
    },
  },
  {
    id: "60",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.7287636399269104,
        },
        {
          x: 0.898274838924408,
          y: 0.7287636399269104,
        },
        {
          x: 0.898274838924408,
          y: 0.7342304587364197,
        },
        {
          x: 0.8845925331115723,
          y: 0.7342304587364197,
        },
      ],
    },
  },
  {
    id: "61",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8834027647972107,
          y: 0.7556770443916321,
        },
        {
          x: 0.898274838924408,
          y: 0.7556770443916321,
        },
        {
          x: 0.898274838924408,
          y: 0.761564314365387,
        },
        {
          x: 0.8834027647972107,
          y: 0.761564314365387,
        },
      ],
    },
  },
  {
    id: "62",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.7834314703941345,
        },
        {
          x: 0.898274838924408,
          y: 0.7834314703941345,
        },
        {
          x: 0.898274838924408,
          y: 0.788898229598999,
        },
        {
          x: 0.8845925331115723,
          y: 0.788898229598999,
        },
      ],
    },
  },
  {
    id: "63",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.8107653260231018,
        },
        {
          x: 0.898274838924408,
          y: 0.8107653260231018,
        },
        {
          x: 0.898274838924408,
          y: 0.8162321448326111,
        },
        {
          x: 0.8845925331115723,
          y: 0.8162321448326111,
        },
      ],
    },
  },
  {
    id: "64",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.8380992412567139,
        },
        {
          x: 0.898274838924408,
          y: 0.8380992412567139,
        },
        {
          x: 0.898274838924408,
          y: 0.8435660004615784,
        },
        {
          x: 0.8845925331115723,
          y: 0.8435660004615784,
        },
      ],
    },
  },
  {
    id: "65",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.8658536672592163,
        },
        {
          x: 0.898274838924408,
          y: 0.8658536672592163,
        },
        {
          x: 0.898274838924408,
          y: 0.8713204264640808,
        },
        {
          x: 0.8845925331115723,
          y: 0.8713204264640808,
        },
      ],
    },
  },
  {
    id: "66",
    key: "QTY",
    resultText: "24",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5812016725540161,
          y: 0.35954582691192627,
        },
        {
          x: 0.5966686606407166,
          y: 0.35954582691192627,
        },
        {
          x: 0.5966686606407166,
          y: 0.36501261591911316,
        },
        {
          x: 0.5812016725540161,
          y: 0.36501261591911316,
        },
      ],
    },
  },
  {
    id: "67",
    key: "QTY",
    resultText: "84",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.38477712869644165,
        },
        {
          x: 0.597263514995575,
          y: 0.38477712869644165,
        },
        {
          x: 0.597263514995575,
          y: 0.39024388790130615,
        },
        {
          x: 0.5817965269088745,
          y: 0.39024388790130615,
        },
      ],
    },
  },
  {
    id: "68",
    key: "QTY",
    resultText: "102",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5764425992965698,
          y: 0.4104289412498474,
        },
        {
          x: 0.5960737466812134,
          y: 0.4104289412498474,
        },
        {
          x: 0.5960737466812134,
          y: 0.41505467891693115,
        },
        {
          x: 0.5764425992965698,
          y: 0.41505467891693115,
        },
      ],
    },
  },
  {
    id: "69",
    key: "QTY",
    resultText: "240",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.4360807538032532,
        },
        {
          x: 0.5960737466812134,
          y: 0.4360807538032532,
        },
        {
          x: 0.5960737466812134,
          y: 0.4407064616680145,
        },
        {
          x: 0.5758476853370667,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "70",
    key: "QTY",
    resultText: "70",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.46131202578544617,
        },
        {
          x: 0.5960737466812134,
          y: 0.46131202578544617,
        },
        {
          x: 0.5960737466812134,
          y: 0.4659377634525299,
        },
        {
          x: 0.5823914408683777,
          y: 0.4659377634525299,
        },
      ],
    },
  },
  {
    id: "71",
    key: "QTY",
    resultText: "36",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.48696383833885193,
        },
        {
          x: 0.595478892326355,
          y: 0.48696383833885193,
        },
        {
          x: 0.595478892326355,
          y: 0.4911690354347229,
        },
        {
          x: 0.5823914408683777,
          y: 0.4911690354347229,
        },
      ],
    },
  },
  {
    id: "72",
    key: "QTY",
    resultText: "420",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5752528309822083,
          y: 0.5117745995521545,
        },
        {
          x: 0.5960737466812134,
          y: 0.5117745995521545,
        },
        {
          x: 0.5960737466812134,
          y: 0.5168208479881287,
        },
        {
          x: 0.5752528309822083,
          y: 0.5168208479881287,
        },
      ],
    },
  },
  {
    id: "73",
    key: "QTY",
    resultText: "84",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.5454162955284119,
        },
        {
          x: 0.595478892326355,
          y: 0.5454162955284119,
        },
        {
          x: 0.595478892326355,
          y: 0.5500420331954956,
        },
        {
          x: 0.5817965269088745,
          y: 0.5500420331954956,
        },
      ],
    },
  },
  {
    id: "74",
    key: "QTY",
    resultText: "36",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.5710681080818176,
        },
        {
          x: 0.5960737466812134,
          y: 0.5710681080818176,
        },
        {
          x: 0.5960737466812134,
          y: 0.5756938457489014,
        },
        {
          x: 0.5817965269088745,
          y: 0.5756938457489014,
        },
      ],
    },
  },
  {
    id: "75",
    key: "QTY",
    resultText: "90",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.5958788990974426,
        },
        {
          x: 0.5960737466812134,
          y: 0.5958788990974426,
        },
        {
          x: 0.5960737466812134,
          y: 0.6013456583023071,
        },
        {
          x: 0.5817965269088745,
          y: 0.6013456583023071,
        },
      ],
    },
  },
  {
    id: "76",
    key: "QTY",
    resultText: "200",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5764425992965698,
          y: 0.621110200881958,
        },
        {
          x: 0.5966686606407166,
          y: 0.621110200881958,
        },
        {
          x: 0.5966686606407166,
          y: 0.6261564493179321,
        },
        {
          x: 0.5764425992965698,
          y: 0.6261564493179321,
        },
      ],
    },
  },
  {
    id: "77",
    key: "QTY",
    resultText: "60",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.6467620134353638,
        },
        {
          x: 0.5960737466812134,
          y: 0.6467620134353638,
        },
        {
          x: 0.5960737466812134,
          y: 0.6518082618713379,
        },
        {
          x: 0.5817965269088745,
          y: 0.6518082618713379,
        },
      ],
    },
  },
  {
    id: "78",
    key: "QTY",
    resultText: "270",
    confidence: 0.9999998807907104,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5752528309822083,
          y: 0.674095869064331,
        },
        {
          x: 0.5966686606407166,
          y: 0.674095869064331,
        },
        {
          x: 0.5966686606407166,
          y: 0.6791421175003052,
        },
        {
          x: 0.5752528309822083,
          y: 0.6791421175003052,
        },
      ],
    },
  },
  {
    id: "79",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.7018502950668335,
        },
        {
          x: 0.5960737466812134,
          y: 0.7018502950668335,
        },
        {
          x: 0.5960737466812134,
          y: 0.7064760327339172,
        },
        {
          x: 0.5823914408683777,
          y: 0.7064760327339172,
        },
      ],
    },
  },
  {
    id: "80",
    key: "QTY",
    resultText: "12",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.7291842103004456,
        },
        {
          x: 0.5966686606407166,
          y: 0.7291842103004456,
        },
        {
          x: 0.5966686606407166,
          y: 0.7338099479675293,
        },
        {
          x: 0.5823914408683777,
          y: 0.7338099479675293,
        },
      ],
    },
  },
  {
    id: "81",
    key: "QTY",
    resultText: "75",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.7560975551605225,
        },
        {
          x: 0.5960737466812134,
          y: 0.7560975551605225,
        },
        {
          x: 0.5960737466812134,
          y: 0.7611438035964966,
        },
        {
          x: 0.5823914408683777,
          y: 0.7611438035964966,
        },
      ],
    },
  },
  {
    id: "82",
    key: "QTY",
    resultText: "70",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.7834314703941345,
        },
        {
          x: 0.5960737466812134,
          y: 0.7834314703941345,
        },
        {
          x: 0.5960737466812134,
          y: 0.7884777188301086,
        },
        {
          x: 0.5817965269088745,
          y: 0.7884777188301086,
        },
      ],
    },
  },
  {
    id: "83",
    key: "QTY",
    resultText: "10",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.811185896396637,
        },
        {
          x: 0.5960737466812134,
          y: 0.811185896396637,
        },
        {
          x: 0.5960737466812134,
          y: 0.8158116340637207,
        },
        {
          x: 0.5823914408683777,
          y: 0.8158116340637207,
        },
      ],
    },
  },
  {
    id: "84",
    key: "QTY",
    resultText: "24",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5812016725540161,
          y: 0.8385197520256042,
        },
        {
          x: 0.5960737466812134,
          y: 0.8385197520256042,
        },
        {
          x: 0.5960737466812134,
          y: 0.843145489692688,
        },
        {
          x: 0.5812016725540161,
          y: 0.843145489692688,
        },
      ],
    },
  },
  {
    id: "85",
    key: "QTY",
    resultText: "12",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.8658536672592163,
        },
        {
          x: 0.5966686606407166,
          y: 0.8658536672592163,
        },
        {
          x: 0.5966686606407166,
          y: 0.8708999156951904,
        },
        {
          x: 0.5823914408683777,
          y: 0.8708999156951904,
        },
      ],
    },
  },
  {
    id: "86",
    key: "UNIT_PRICE",
    resultText: "700.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.35954582691192627,
        },
        {
          x: 0.7204045057296753,
          y: 0.35954582691192627,
        },
        {
          x: 0.7204045057296753,
          y: 0.3645921051502228,
        },
        {
          x: 0.6870909929275513,
          y: 0.3645921051502228,
        },
      ],
    },
  },
  {
    id: "87",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.38477712869644165,
        },
        {
          x: 0.7209994196891785,
          y: 0.38477712869644165,
        },
        {
          x: 0.7209994196891785,
          y: 0.38982337713241577,
        },
        {
          x: 0.6864961385726929,
          y: 0.38982337713241577,
        },
      ],
    },
  },
  {
    id: "88",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.41000840067863464,
        },
        {
          x: 0.7204045057296753,
          y: 0.41000840067863464,
        },
        {
          x: 0.7204045057296753,
          y: 0.41505467891693115,
        },
        {
          x: 0.6864961385726929,
          y: 0.41505467891693115,
        },
      ],
    },
  },
  {
    id: "89",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.4356602132320404,
        },
        {
          x: 0.7204045057296753,
          y: 0.4356602132320404,
        },
        {
          x: 0.7204045057296753,
          y: 0.4407064616680145,
        },
        {
          x: 0.6870909929275513,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "90",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.4608915150165558,
        },
        {
          x: 0.7204045057296753,
          y: 0.4608915150165558,
        },
        {
          x: 0.7204045057296753,
          y: 0.4659377634525299,
        },
        {
          x: 0.6864961385726929,
          y: 0.4659377634525299,
        },
      ],
    },
  },
  {
    id: "91",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.48654332756996155,
        },
        {
          x: 0.7204045057296753,
          y: 0.48654332756996155,
        },
        {
          x: 0.7204045057296753,
          y: 0.49158957600593567,
        },
        {
          x: 0.6870909929275513,
          y: 0.49158957600593567,
        },
      ],
    },
  },
  {
    id: "92",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.5117745995521545,
        },
        {
          x: 0.7209994196891785,
          y: 0.5117745995521545,
        },
        {
          x: 0.7209994196891785,
          y: 0.517241358757019,
        },
        {
          x: 0.6864961385726929,
          y: 0.517241358757019,
        },
      ],
    },
  },
  {
    id: "93",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.5449957847595215,
        },
        {
          x: 0.7209994196891785,
          y: 0.5449957847595215,
        },
        {
          x: 0.7209994196891785,
          y: 0.5500420331954956,
        },
        {
          x: 0.6870909929275513,
          y: 0.5500420331954956,
        },
      ],
    },
  },
  {
    id: "94",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.5706475973129272,
        },
        {
          x: 0.7204045057296753,
          y: 0.5706475973129272,
        },
        {
          x: 0.7204045057296753,
          y: 0.5756938457489014,
        },
        {
          x: 0.6870909929275513,
          y: 0.5756938457489014,
        },
      ],
    },
  },
  {
    id: "95",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.5958788990974426,
        },
        {
          x: 0.7209994196891785,
          y: 0.5958788990974426,
        },
        {
          x: 0.7209994196891785,
          y: 0.6009251475334167,
        },
        {
          x: 0.6870909929275513,
          y: 0.6009251475334167,
        },
      ],
    },
  },
  {
    id: "96",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.6206896305084229,
        },
        {
          x: 0.7204045057296753,
          y: 0.6206896305084229,
        },
        {
          x: 0.7204045057296753,
          y: 0.6261564493179321,
        },
        {
          x: 0.6870909929275513,
          y: 0.6261564493179321,
        },
      ],
    },
  },
  {
    id: "97",
    key: "UNIT_PRICE",
    resultText: "1,185.00",
    confidence: 0.9999978542327881,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6775728464126587,
          y: 0.6463414430618286,
        },
        {
          x: 0.7204045057296753,
          y: 0.6463414430618286,
        },
        {
          x: 0.7204045057296753,
          y: 0.6526492834091187,
        },
        {
          x: 0.6775728464126587,
          y: 0.6526492834091187,
        },
      ],
    },
  },
  {
    id: "98",
    key: "UNIT_PRICE",
    resultText: "1,185.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.6736753582954407,
        },
        {
          x: 0.7215942740440369,
          y: 0.6736753582954407,
        },
        {
          x: 0.7215942740440369,
          y: 0.6804037094116211,
        },
        {
          x: 0.6781677603721619,
          y: 0.6804037094116211,
        },
      ],
    },
  },
  {
    id: "99",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.7014297842979431,
        },
        {
          x: 0.7204045057296753,
          y: 0.7014297842979431,
        },
        {
          x: 0.7204045057296753,
          y: 0.7064760327339172,
        },
        {
          x: 0.6870909929275513,
          y: 0.7064760327339172,
        },
      ],
    },
  },
  {
    id: "100",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.72834312915802,
        },
        {
          x: 0.7204045057296753,
          y: 0.72834312915802,
        },
        {
          x: 0.7204045057296753,
          y: 0.7338099479675293,
        },
        {
          x: 0.6870909929275513,
          y: 0.7338099479675293,
        },
      ],
    },
  },
  {
    id: "101",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.7556770443916321,
        },
        {
          x: 0.7204045057296753,
          y: 0.7556770443916321,
        },
        {
          x: 0.7204045057296753,
          y: 0.7611438035964966,
        },
        {
          x: 0.6870909929275513,
          y: 0.7611438035964966,
        },
      ],
    },
  },
  {
    id: "102",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.7834314703941345,
        },
        {
          x: 0.7204045057296753,
          y: 0.7834314703941345,
        },
        {
          x: 0.7204045057296753,
          y: 0.7884777188301086,
        },
        {
          x: 0.6864961385726929,
          y: 0.7884777188301086,
        },
      ],
    },
  },
  {
    id: "103",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.8107653260231018,
        },
        {
          x: 0.7209994196891785,
          y: 0.8107653260231018,
        },
        {
          x: 0.7209994196891785,
          y: 0.8158116340637207,
        },
        {
          x: 0.6870909929275513,
          y: 0.8158116340637207,
        },
      ],
    },
  },
  {
    id: "104",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.8380992412567139,
        },
        {
          x: 0.7209994196891785,
          y: 0.8380992412567139,
        },
        {
          x: 0.7209994196891785,
          y: 0.8435660004615784,
        },
        {
          x: 0.6864961385726929,
          y: 0.8435660004615784,
        },
      ],
    },
  },
  {
    id: "105",
    key: "UNIT_PRICE",
    resultText: "635.00",
    confidence: 0.9999973773956299,
    hightLightZone: {
      page: "1",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.8654331564903259,
        },
        {
          x: 0.7215942740440369,
          y: 0.8654331564903259,
        },
        {
          x: 0.7215942740440369,
          y: 0.8713204264640808,
        },
        {
          x: 0.6864961385726929,
          y: 0.8713204264640808,
        },
      ],
    },
  },
  {
    id: "110",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9999896287918091,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.19274242222309113,
          y: 0.27039527893066406,
        },
        {
          x: 0.34205830097198486,
          y: 0.27039527893066406,
        },
        {
          x: 0.34205830097198486,
          y: 0.2771236300468445,
        },
        {
          x: 0.19274242222309113,
          y: 0.2771236300468445,
        },
      ],
    },
  },
  {
    id: "111",
    key: "DESCRIPTION",
    resultText:
      "LE BLANC BRIGHTENING COMPACT FOUNDATION LONG-LASTING\nRADIANCE-PROTECTION-THERMAL COMFORT SPF25/PA+++ B10",
    confidence: 0.9618327617645264,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16240333020687103,
          y: 0.31707316637039185,
        },
        {
          x: 0.5026769638061523,
          y: 0.31707316637039185,
        },
        {
          x: 0.5026769638061523,
          y: 0.332632452249527,
        },
        {
          x: 0.16240333020687103,
          y: 0.332632452249527,
        },
      ],
    },
  },
  {
    id: "112",
    key: "DESCRIPTION",
    resultText:
      "LE BLANC BRIGHTENING COMPACT FOUNDATION LONG-LASTING\nRADIANCE-PROTECTION-THERMAL COMFORT SPF25/PA+++ BR12",
    confidence: 0.999876856803894,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.1635930985212326,
          y: 0.35281750559806824,
        },
        {
          x: 0.5038667321205139,
          y: 0.35281750559806824,
        },
        {
          x: 0.5038667321205139,
          y: 0.36879730224609375,
        },
        {
          x: 0.1635930985212326,
          y: 0.36879730224609375,
        },
      ],
    },
  },
  {
    id: "113",
    key: "DESCRIPTION",
    resultText:
      "LE BLANC BRIGHTENING COMPACT FOUNDATION LONG-LASTING\nRADIANCE-PROTECTION-THERMAL COMFORT SPF25/PA+++ B20",
    confidence: 0.9999030828475952,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16240333020687103,
          y: 0.38856181502342224,
        },
        {
          x: 0.5026769638061523,
          y: 0.38856181502342224,
        },
        {
          x: 0.5026769638061523,
          y: 0.40454164147377014,
        },
        {
          x: 0.16240333020687103,
          y: 0.40454164147377014,
        },
      ],
    },
  },
  {
    id: "114",
    key: "DESCRIPTION",
    resultText:
      "LE BLANC BRIGHTENING COMPACT FOUNDATION LONG-LASTING\nRADIANCE-PROTECTION-THERMAL COMFORT SPF25/PA+++ B30",
    confidence: 0.9998645782470703,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16299821436405182,
          y: 0.42430615425109863,
        },
        {
          x: 0.5026769638061523,
          y: 0.42430615425109863,
        },
        {
          x: 0.5026769638061523,
          y: 0.44028595089912415,
        },
        {
          x: 0.16299821436405182,
          y: 0.44028595089912415,
        },
      ],
    },
  },
  {
    id: "115",
    key: "DESCRIPTION",
    resultText:
      "LE BLANC BRIGHTENING COMPACT FOUNDATION LONG-LASTING\nRADIANCE-PROTECTION-THERMAL COMFORT SPF25/PA+++ BD01",
    confidence: 0.9998481273651123,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16240333020687103,
          y: 0.4608915150165558,
        },
        {
          x: 0.5026769638061523,
          y: 0.4608915150165558,
        },
        {
          x: 0.5026769638061523,
          y: 0.4768713116645813,
        },
        {
          x: 0.16240333020687103,
          y: 0.4768713116645813,
        },
      ],
    },
  },
  {
    id: "116",
    key: "DESCRIPTION",
    resultText: "OMBRE ESSENTIELLE BLE D'OR ANTIQUE",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.5050462484359741,
        },
        {
          x: 0.3783462345600128,
          y: 0.5050462484359741,
        },
        {
          x: 0.3783462345600128,
          y: 0.5113540887832642,
        },
        {
          x: 0.1641879826784134,
          y: 0.5113540887832642,
        },
      ],
    },
  },
  {
    id: "117",
    key: "DESCRIPTION",
    resultText: "LA PALETTE SOURCILS DARK",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.5306980609893799,
        },
        {
          x: 0.31647828221321106,
          y: 0.5306980609893799,
        },
        {
          x: 0.31647828221321106,
          y: 0.5361648201942444,
        },
        {
          x: 0.16478286683559418,
          y: 0.5361648201942444,
        },
      ],
    },
  },
  {
    id: "118",
    key: "DESCRIPTION",
    resultText: "CRAYON SOURCILS SCULPTING EYEBROW PENCIL 50 BISTRE 1g",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16299821436405182,
          y: 0.5563498735427856,
        },
        {
          x: 0.5002974271774292,
          y: 0.5563498735427856,
        },
        {
          x: 0.5002974271774292,
          y: 0.5630782246589661,
        },
        {
          x: 0.16299821436405182,
          y: 0.5630782246589661,
        },
      ],
    },
  },
  {
    id: "119",
    key: "DESCRIPTION",
    resultText: "CALLIGRAPHIE DE CHANEL HYPERBLACK",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.1635930985212326,
          y: 0.5811606645584106,
        },
        {
          x: 0.3795360028743744,
          y: 0.5811606645584106,
        },
        {
          x: 0.3795360028743744,
          y: 0.5870479345321655,
        },
        {
          x: 0.1635930985212326,
          y: 0.5870479345321655,
        },
      ],
    },
  },
  {
    id: "120",
    key: "DESCRIPTION",
    resultText: "SIGNATURE DE CHANEL NOIR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.6068124771118164,
        },
        {
          x: 0.319452702999115,
          y: 0.6068124771118164,
        },
        {
          x: 0.319452702999115,
          y: 0.6122792363166809,
        },
        {
          x: 0.16478286683559418,
          y: 0.6122792363166809,
        },
      ],
    },
  },
  {
    id: "121",
    key: "DESCRIPTION",
    resultText: "STYLO SOURCILS WATERPROOF BRUN CLAIR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16478286683559418,
          y: 0.632043719291687,
        },
        {
          x: 0.40392622351646423,
          y: 0.632043719291687,
        },
        {
          x: 0.40392622351646423,
          y: 0.6379310488700867,
        },
        {
          x: 0.16478286683559418,
          y: 0.6379310488700867,
        },
      ],
    },
  },
  {
    id: "122",
    key: "DESCRIPTION",
    resultText: "LES BEIGES HEALTHY GLOW NATURAL EYESHADOW PALETTE\nDEEP",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16240333020687103,
          y: 0.6572750210762024,
        },
        {
          x: 0.48958954215049744,
          y: 0.6572750210762024,
        },
        {
          x: 0.48958954215049744,
          y: 0.6732548475265503,
        },
        {
          x: 0.16240333020687103,
          y: 0.6732548475265503,
        },
      ],
    },
  },
  {
    id: "123",
    key: "DESCRIPTION",
    resultText: "LES BEIGES HEALTHY GLOW NATURAL EYESHADOW PALETTE\nWARM",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.16180844604969025,
          y: 0.7018502950668335,
        },
        {
          x: 0.48958954215049744,
          y: 0.7018502950668335,
        },
        {
          x: 0.48958954215049744,
          y: 0.717409610748291,
        },
        {
          x: 0.16180844604969025,
          y: 0.717409610748291,
        },
      ],
    },
  },
  {
    id: "124",
    key: "DESCRIPTION",
    resultText: "LES BEIGES HEALTHY GLOW GEL TOUCH FOUNDATION\nSPF30/PA+++ B10",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.7396972179412842,
        },
        {
          x: 0.4527067244052887,
          y: 0.7396972179412842,
        },
        {
          x: 0.4527067244052887,
          y: 0.7548360228538513,
        },
        {
          x: 0.1641879826784134,
          y: 0.7548360228538513,
        },
      ],
    },
  },
  {
    id: "125",
    key: "DESCRIPTION",
    resultText:
      "LES BEIGES HEALTHY GLOW GEL TOUCH FOUNDATION\nSPF30/PA+++ BR12",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.7838519811630249,
        },
        {
          x: 0.4521118402481079,
          y: 0.7838519811630249,
        },
        {
          x: 0.4521118402481079,
          y: 0.7998318076133728,
        },
        {
          x: 0.1641879826784134,
          y: 0.7998318076133728,
        },
      ],
    },
  },
  {
    id: "126",
    key: "DESCRIPTION",
    resultText:
      "LES BEIGES HEALTHY GLOW GEL TOUCH FOUNDATION\nSPF30/PA+++ BD21",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.8280067443847656,
        },
        {
          x: 0.4521118402481079,
          y: 0.8280067443847656,
        },
        {
          x: 0.4521118402481079,
          y: 0.8439865708351135,
        },
        {
          x: 0.1641879826784134,
          y: 0.8439865708351135,
        },
      ],
    },
  },
  {
    id: "127",
    key: "INVOICE_NO",
    resultText: "SIN0064393",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8185603618621826,
          y: 0.15054668486118317,
        },
        {
          x: 0.8762641549110413,
          y: 0.15054668486118317,
        },
        {
          x: 0.8762641549110413,
          y: 0.15643398463726044,
        },
        {
          x: 0.8185603618621826,
          y: 0.15643398463726044,
        },
      ],
    },
  },
  {
    id: "128",
    key: "ITEM",
    resultText: "0175410",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.31707316637039185,
        },
        {
          x: 0.11362284421920776,
          y: 0.31707316637039185,
        },
        {
          x: 0.11362284421920776,
          y: 0.32253995537757874,
        },
        {
          x: 0.06900654733181,
          y: 0.32253995537757874,
        },
      ],
    },
  },
  {
    id: "129",
    key: "ITEM",
    resultText: "0175420",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.3532380163669586,
        },
        {
          x: 0.11421772837638855,
          y: 0.3532380163669586,
        },
        {
          x: 0.11421772837638855,
          y: 0.3587048053741455,
        },
        {
          x: 0.06960142403841019,
          y: 0.3587048053741455,
        },
      ],
    },
  },
  {
    id: "130",
    key: "ITEM",
    resultText: "0175430",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.3889823257923126,
        },
        {
          x: 0.11362284421920776,
          y: 0.3889823257923126,
        },
        {
          x: 0.11362284421920776,
          y: 0.3948696255683899,
        },
        {
          x: 0.06960142403841019,
          y: 0.3948696255683899,
        },
      ],
    },
  },
  {
    id: "131",
    key: "ITEM",
    resultText: "0175465",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.424726665019989,
        },
        {
          x: 0.11362284421920776,
          y: 0.424726665019989,
        },
        {
          x: 0.11362284421920776,
          y: 0.4306139647960663,
        },
        {
          x: 0.06900654733181,
          y: 0.4306139647960663,
        },
      ],
    },
  },
  {
    id: "132",
    key: "ITEM",
    resultText: "0175470",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.46131202578544617,
        },
        {
          x: 0.11362284421920776,
          y: 0.46131202578544617,
        },
        {
          x: 0.11362284421920776,
          y: 0.46677881479263306,
        },
        {
          x: 0.06960142403841019,
          y: 0.46677881479263306,
        },
      ],
    },
  },
  {
    id: "133",
    key: "ITEM",
    resultText: "0181224",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5058873295783997,
        },
        {
          x: 0.11362284421920776,
          y: 0.5058873295783997,
        },
        {
          x: 0.11362284421920776,
          y: 0.5105130076408386,
        },
        {
          x: 0.06960142403841019,
          y: 0.5105130076408386,
        },
      ],
    },
  },
  {
    id: "134",
    key: "ITEM",
    resultText: "0182470",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5302775502204895,
        },
        {
          x: 0.11362284421920776,
          y: 0.5302775502204895,
        },
        {
          x: 0.11362284421920776,
          y: 0.535744309425354,
        },
        {
          x: 0.06960142403841019,
          y: 0.535744309425354,
        },
      ],
    },
  },
  {
    id: "135",
    key: "ITEM",
    resultText: "0183055",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5559293627738953,
        },
        {
          x: 0.11421772837638855,
          y: 0.5559293627738953,
        },
        {
          x: 0.11421772837638855,
          y: 0.5618166327476501,
        },
        {
          x: 0.06960142403841019,
          y: 0.5618166327476501,
        },
      ],
    },
  },
  {
    id: "136",
    key: "ITEM",
    resultText: "0183650",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.5811606645584106,
        },
        {
          x: 0.11362284421920776,
          y: 0.5811606645584106,
        },
        {
          x: 0.11362284421920776,
          y: 0.5870479345321655,
        },
        {
          x: 0.06960142403841019,
          y: 0.5870479345321655,
        },
      ],
    },
  },
  {
    id: "137",
    key: "ITEM",
    resultText: "0183750",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.6063919067382812,
        },
        {
          x: 0.11362284421920776,
          y: 0.6063919067382812,
        },
        {
          x: 0.11362284421920776,
          y: 0.6122792363166809,
        },
        {
          x: 0.06960142403841019,
          y: 0.6122792363166809,
        },
      ],
    },
  },
  {
    id: "138",
    key: "ITEM",
    resultText: "0183808",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.632043719291687,
        },
        {
          x: 0.11362284421920776,
          y: 0.632043719291687,
        },
        {
          x: 0.11362284421920776,
          y: 0.6379310488700867,
        },
        {
          x: 0.06960142403841019,
          y: 0.6379310488700867,
        },
      ],
    },
  },
  {
    id: "139",
    key: "ITEM",
    resultText: "0184188",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.6576955318450928,
        },
        {
          x: 0.11362284421920776,
          y: 0.6576955318450928,
        },
        {
          x: 0.11362284421920776,
          y: 0.6627417802810669,
        },
        {
          x: 0.06960142403841019,
          y: 0.6627417802810669,
        },
      ],
    },
  },
  {
    id: "140",
    key: "ITEM",
    resultText: "0184189",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7018502950668335,
        },
        {
          x: 0.11362284421920776,
          y: 0.7018502950668335,
        },
        {
          x: 0.11362284421920776,
          y: 0.7077375650405884,
        },
        {
          x: 0.06960142403841019,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "141",
    key: "ITEM",
    resultText: "0185118",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7396972179412842,
        },
        {
          x: 0.11362284421920776,
          y: 0.7396972179412842,
        },
        {
          x: 0.11362284421920776,
          y: 0.7455845475196838,
        },
        {
          x: 0.06960142403841019,
          y: 0.7455845475196838,
        },
      ],
    },
  },
  {
    id: "142",
    key: "ITEM",
    resultText: "0185122",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.7846930027008057,
        },
        {
          x: 0.11421772837638855,
          y: 0.7846930027008057,
        },
        {
          x: 0.11421772837638855,
          y: 0.7897392511367798,
        },
        {
          x: 0.06960142403841019,
          y: 0.7897392511367798,
        },
      ],
    },
  },
  {
    id: "143",
    key: "ITEM",
    resultText: "0185128",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.8288477659225464,
        },
        {
          x: 0.11421772837638855,
          y: 0.8288477659225464,
        },
        {
          x: 0.11421772837638855,
          y: 0.8338940143585205,
        },
        {
          x: 0.06900654733181,
          y: 0.8338940143585205,
        },
      ],
    },
  },
  {
    id: "144",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.3174937069416046,
        },
        {
          x: 0.8976799249649048,
          y: 0.3174937069416046,
        },
        {
          x: 0.8976799249649048,
          y: 0.3229604661464691,
        },
        {
          x: 0.8839976191520691,
          y: 0.3229604661464691,
        },
      ],
    },
  },
  {
    id: "145",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.3532380163669586,
        },
        {
          x: 0.898274838924408,
          y: 0.3532380163669586,
        },
        {
          x: 0.898274838924408,
          y: 0.3587048053741455,
        },
        {
          x: 0.8845925331115723,
          y: 0.3587048053741455,
        },
      ],
    },
  },
  {
    id: "146",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.3894028663635254,
        },
        {
          x: 0.898274838924408,
          y: 0.3894028663635254,
        },
        {
          x: 0.898274838924408,
          y: 0.3948696255683899,
        },
        {
          x: 0.8845925331115723,
          y: 0.3948696255683899,
        },
      ],
    },
  },
  {
    id: "147",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.424726665019989,
        },
        {
          x: 0.898274838924408,
          y: 0.424726665019989,
        },
        {
          x: 0.898274838924408,
          y: 0.4306139647960663,
        },
        {
          x: 0.8839976191520691,
          y: 0.4306139647960663,
        },
      ],
    },
  },
  {
    id: "148",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.46131202578544617,
        },
        {
          x: 0.898274838924408,
          y: 0.46131202578544617,
        },
        {
          x: 0.898274838924408,
          y: 0.46677881479263306,
        },
        {
          x: 0.8839976191520691,
          y: 0.46677881479263306,
        },
      ],
    },
  },
  {
    id: "149",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5054667592048645,
        },
        {
          x: 0.898274838924408,
          y: 0.5054667592048645,
        },
        {
          x: 0.898274838924408,
          y: 0.5109335780143738,
        },
        {
          x: 0.8845925331115723,
          y: 0.5109335780143738,
        },
      ],
    },
  },
  {
    id: "150",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5306980609893799,
        },
        {
          x: 0.898274838924408,
          y: 0.5306980609893799,
        },
        {
          x: 0.898274838924408,
          y: 0.5361648201942444,
        },
        {
          x: 0.8845925331115723,
          y: 0.5361648201942444,
        },
      ],
    },
  },
  {
    id: "151",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5563498735427856,
        },
        {
          x: 0.898274838924408,
          y: 0.5563498735427856,
        },
        {
          x: 0.898274838924408,
          y: 0.5618166327476501,
        },
        {
          x: 0.8845925331115723,
          y: 0.5618166327476501,
        },
      ],
    },
  },
  {
    id: "152",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.581581175327301,
        },
        {
          x: 0.898274838924408,
          y: 0.581581175327301,
        },
        {
          x: 0.898274838924408,
          y: 0.5866274237632751,
        },
        {
          x: 0.8887566924095154,
          y: 0.5866274237632751,
        },
      ],
    },
  },
  {
    id: "153",
    key: "MADE_IN",
    resultText: "JP",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.6068124771118164,
        },
        {
          x: 0.898274838924408,
          y: 0.6068124771118164,
        },
        {
          x: 0.898274838924408,
          y: 0.6122792363166809,
        },
        {
          x: 0.8851873874664307,
          y: 0.6122792363166809,
        },
      ],
    },
  },
  {
    id: "154",
    key: "MADE_IN",
    resultText: "JP",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8857823014259338,
          y: 0.6324642300605774,
        },
        {
          x: 0.898274838924408,
          y: 0.6324642300605774,
        },
        {
          x: 0.898274838924408,
          y: 0.6379310488700867,
        },
        {
          x: 0.8857823014259338,
          y: 0.6379310488700867,
        },
      ],
    },
  },
  {
    id: "155",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.6576955318450928,
        },
        {
          x: 0.898274838924408,
          y: 0.6576955318450928,
        },
        {
          x: 0.898274838924408,
          y: 0.663162350654602,
        },
        {
          x: 0.8845925331115723,
          y: 0.663162350654602,
        },
      ],
    },
  },
  {
    id: "156",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.7022708058357239,
        },
        {
          x: 0.898274838924408,
          y: 0.7022708058357239,
        },
        {
          x: 0.898274838924408,
          y: 0.7077375650405884,
        },
        {
          x: 0.8845925331115723,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "157",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.7401177287101746,
        },
        {
          x: 0.898274838924408,
          y: 0.7401177287101746,
        },
        {
          x: 0.898274838924408,
          y: 0.7455845475196838,
        },
        {
          x: 0.8839976191520691,
          y: 0.7455845475196838,
        },
      ],
    },
  },
  {
    id: "158",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.7842724919319153,
        },
        {
          x: 0.898274838924408,
          y: 0.7842724919319153,
        },
        {
          x: 0.898274838924408,
          y: 0.7897392511367798,
        },
        {
          x: 0.8845925331115723,
          y: 0.7897392511367798,
        },
      ],
    },
  },
  {
    id: "159",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.8839976191520691,
          y: 0.828427255153656,
        },
        {
          x: 0.898274838924408,
          y: 0.828427255153656,
        },
        {
          x: 0.898274838924408,
          y: 0.8343145251274109,
        },
        {
          x: 0.8839976191520691,
          y: 0.8343145251274109,
        },
      ],
    },
  },
  {
    id: "160",
    key: "QTY",
    resultText: "330",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5764425992965698,
          y: 0.3174937069416046,
        },
        {
          x: 0.5966686606407166,
          y: 0.3174937069416046,
        },
        {
          x: 0.5966686606407166,
          y: 0.32253995537757874,
        },
        {
          x: 0.5764425992965698,
          y: 0.32253995537757874,
        },
      ],
    },
  },
  {
    id: "161",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.3532380163669586,
        },
        {
          x: 0.5960737466812134,
          y: 0.3532380163669586,
        },
        {
          x: 0.5960737466812134,
          y: 0.35828426480293274,
        },
        {
          x: 0.5817965269088745,
          y: 0.35828426480293274,
        },
      ],
    },
  },
  {
    id: "162",
    key: "QTY",
    resultText: "170",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.38982337713241577,
        },
        {
          x: 0.5960737466812134,
          y: 0.38982337713241577,
        },
        {
          x: 0.5960737466812134,
          y: 0.3944491147994995,
        },
        {
          x: 0.5758476853370667,
          y: 0.3944491147994995,
        },
      ],
    },
  },
  {
    id: "163",
    key: "QTY",
    resultText: "20",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5806067585945129,
          y: 0.424726665019989,
        },
        {
          x: 0.5966686606407166,
          y: 0.424726665019989,
        },
        {
          x: 0.5966686606407166,
          y: 0.4301934540271759,
        },
        {
          x: 0.5806067585945129,
          y: 0.4301934540271759,
        },
      ],
    },
  },
  {
    id: "164",
    key: "QTY",
    resultText: "630",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.46131202578544617,
        },
        {
          x: 0.5960737466812134,
          y: 0.46131202578544617,
        },
        {
          x: 0.5960737466812134,
          y: 0.4663582742214203,
        },
        {
          x: 0.5758476853370667,
          y: 0.4663582742214203,
        },
      ],
    },
  },
  {
    id: "165",
    key: "QTY",
    resultText: "12",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.5054667592048645,
        },
        {
          x: 0.5960737466812134,
          y: 0.5054667592048645,
        },
        {
          x: 0.5960737466812134,
          y: 0.5105130076408386,
        },
        {
          x: 0.5823914408683777,
          y: 0.5105130076408386,
        },
      ],
    },
  },
  {
    id: "166",
    key: "QTY",
    resultText: "12",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.5311185717582703,
        },
        {
          x: 0.5966686606407166,
          y: 0.5311185717582703,
        },
        {
          x: 0.5966686606407166,
          y: 0.535744309425354,
        },
        {
          x: 0.5823914408683777,
          y: 0.535744309425354,
        },
      ],
    },
  },
  {
    id: "167",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.5563498735427856,
        },
        {
          x: 0.5966686606407166,
          y: 0.5563498735427856,
        },
        {
          x: 0.5966686606407166,
          y: 0.5618166327476501,
        },
        {
          x: 0.5817965269088745,
          y: 0.5618166327476501,
        },
      ],
    },
  },
  {
    id: "168",
    key: "QTY",
    resultText: "10",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.5820016860961914,
        },
        {
          x: 0.5960737466812134,
          y: 0.5820016860961914,
        },
        {
          x: 0.5960737466812134,
          y: 0.5866274237632751,
        },
        {
          x: 0.5823914408683777,
          y: 0.5866274237632751,
        },
      ],
    },
  },
  {
    id: "169",
    key: "QTY",
    resultText: "110",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.6063919067382812,
        },
        {
          x: 0.595478892326355,
          y: 0.6063919067382812,
        },
        {
          x: 0.595478892326355,
          y: 0.6118587255477905,
        },
        {
          x: 0.5758476853370667,
          y: 0.6118587255477905,
        },
      ],
    },
  },
  {
    id: "170",
    key: "QTY",
    resultText: "120",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.6324642300605774,
        },
        {
          x: 0.5960737466812134,
          y: 0.6324642300605774,
        },
        {
          x: 0.5960737466812134,
          y: 0.6375105381011963,
        },
        {
          x: 0.5758476853370667,
          y: 0.6375105381011963,
        },
      ],
    },
  },
  {
    id: "171",
    key: "QTY",
    resultText: "90",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5829862952232361,
          y: 0.6576955318450928,
        },
        {
          x: 0.595478892326355,
          y: 0.6576955318450928,
        },
        {
          x: 0.595478892326355,
          y: 0.663162350654602,
        },
        {
          x: 0.5829862952232361,
          y: 0.663162350654602,
        },
      ],
    },
  },
  {
    id: "172",
    key: "QTY",
    resultText: "1200",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.7022708058357239,
        },
        {
          x: 0.5960737466812134,
          y: 0.7022708058357239,
        },
        {
          x: 0.5960737466812134,
          y: 0.7077375650405884,
        },
        {
          x: 0.5710886120796204,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "173",
    key: "QTY",
    resultText: "840",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.7405382394790649,
        },
        {
          x: 0.5960737466812134,
          y: 0.7405382394790649,
        },
        {
          x: 0.5960737466812134,
          y: 0.7451639771461487,
        },
        {
          x: 0.5758476853370667,
          y: 0.7451639771461487,
        },
      ],
    },
  },
  {
    id: "174",
    key: "QTY",
    resultText: "140",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.7842724919319153,
        },
        {
          x: 0.595478892326355,
          y: 0.7842724919319153,
        },
        {
          x: 0.595478892326355,
          y: 0.7897392511367798,
        },
        {
          x: 0.5758476853370667,
          y: 0.7897392511367798,
        },
      ],
    },
  },
  {
    id: "175",
    key: "QTY",
    resultText: "10",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.8288477659225464,
        },
        {
          x: 0.5966686606407166,
          y: 0.8288477659225464,
        },
        {
          x: 0.5966686606407166,
          y: 0.8338940143585205,
        },
        {
          x: 0.5823914408683777,
          y: 0.8338940143585205,
        },
      ],
    },
  },
  {
    id: "176",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.31707316637039185,
        },
        {
          x: 0.7215942740440369,
          y: 0.31707316637039185,
        },
        {
          x: 0.7215942740440369,
          y: 0.3233810067176819,
        },
        {
          x: 0.6781677603721619,
          y: 0.3233810067176819,
        },
      ],
    },
  },
  {
    id: "177",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.3532380163669586,
        },
        {
          x: 0.7215942740440369,
          y: 0.3532380163669586,
        },
        {
          x: 0.7215942740440369,
          y: 0.3591253161430359,
        },
        {
          x: 0.6781677603721619,
          y: 0.3591253161430359,
        },
      ],
    },
  },
  {
    id: "178",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.3894028663635254,
        },
        {
          x: 0.7215942740440369,
          y: 0.3894028663635254,
        },
        {
          x: 0.7215942740440369,
          y: 0.39529016613960266,
        },
        {
          x: 0.6781677603721619,
          y: 0.39529016613960266,
        },
      ],
    },
  },
  {
    id: "179",
    key: "UNIT_PRICE",
    resultText: "825.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.4251471757888794,
        },
        {
          x: 0.7215942740440369,
          y: 0.4251471757888794,
        },
        {
          x: 0.7215942740440369,
          y: 0.4301934540271759,
        },
        {
          x: 0.6864961385726929,
          y: 0.4301934540271759,
        },
      ],
    },
  },
  {
    id: "180",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6775728464126587,
          y: 0.46131202578544617,
        },
        {
          x: 0.72218918800354,
          y: 0.46131202578544617,
        },
        {
          x: 0.72218918800354,
          y: 0.46719932556152344,
        },
        {
          x: 0.6775728464126587,
          y: 0.46719932556152344,
        },
      ],
    },
  },
  {
    id: "181",
    key: "UNIT_PRICE",
    resultText: "675.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.5054667592048645,
        },
        {
          x: 0.7215942740440369,
          y: 0.5054667592048645,
        },
        {
          x: 0.7215942740440369,
          y: 0.5109335780143738,
        },
        {
          x: 0.6864961385726929,
          y: 0.5109335780143738,
        },
      ],
    },
  },
  {
    id: "182",
    key: "UNIT_PRICE",
    resultText: "890.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.5302775502204895,
        },
        {
          x: 0.7198096513748169,
          y: 0.5302775502204895,
        },
        {
          x: 0.7198096513748169,
          y: 0.535744309425354,
        },
        {
          x: 0.6864961385726929,
          y: 0.535744309425354,
        },
      ],
    },
  },
  {
    id: "183",
    key: "UNIT_PRICE",
    resultText: "465.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6853063702583313,
          y: 0.5559293627738953,
        },
        {
          x: 0.72218918800354,
          y: 0.5559293627738953,
        },
        {
          x: 0.72218918800354,
          y: 0.5618166327476501,
        },
        {
          x: 0.6853063702583313,
          y: 0.5618166327476501,
        },
      ],
    },
  },
  {
    id: "184",
    key: "UNIT_PRICE",
    resultText: "570.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6876859068870544,
          y: 0.581581175327301,
        },
        {
          x: 0.7204045057296753,
          y: 0.581581175327301,
        },
        {
          x: 0.7204045057296753,
          y: 0.5866274237632751,
        },
        {
          x: 0.6876859068870544,
          y: 0.5866274237632751,
        },
      ],
    },
  },
  {
    id: "185",
    key: "UNIT_PRICE",
    resultText: "655.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.6063919067382812,
        },
        {
          x: 0.7215942740440369,
          y: 0.6063919067382812,
        },
        {
          x: 0.7215942740440369,
          y: 0.6122792363166809,
        },
        {
          x: 0.6864961385726929,
          y: 0.6122792363166809,
        },
      ],
    },
  },
  {
    id: "186",
    key: "UNIT_PRICE",
    resultText: "720.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6870909929275513,
          y: 0.6324642300605774,
        },
        {
          x: 0.7209994196891785,
          y: 0.6324642300605774,
        },
        {
          x: 0.7209994196891785,
          y: 0.6375105381011963,
        },
        {
          x: 0.6870909929275513,
          y: 0.6375105381011963,
        },
      ],
    },
  },
  {
    id: "187",
    key: "UNIT_PRICE",
    resultText: "1,185.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.6572750210762024,
        },
        {
          x: 0.7215942740440369,
          y: 0.6572750210762024,
        },
        {
          x: 0.7215942740440369,
          y: 0.6635828614234924,
        },
        {
          x: 0.6781677603721619,
          y: 0.6635828614234924,
        },
      ],
    },
  },
  {
    id: "188",
    key: "UNIT_PRICE",
    resultText: "1,185.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.7018502950668335,
        },
        {
          x: 0.7209994196891785,
          y: 0.7018502950668335,
        },
        {
          x: 0.7209994196891785,
          y: 0.7081581354141235,
        },
        {
          x: 0.6781677603721619,
          y: 0.7081581354141235,
        },
      ],
    },
  },
  {
    id: "189",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.7401177287101746,
        },
        {
          x: 0.7215942740440369,
          y: 0.7401177287101746,
        },
        {
          x: 0.7215942740440369,
          y: 0.7460050582885742,
        },
        {
          x: 0.6781677603721619,
          y: 0.7460050582885742,
        },
      ],
    },
  },
  {
    id: "190",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6775728464126587,
          y: 0.7842724919319153,
        },
        {
          x: 0.72218918800354,
          y: 0.7842724919319153,
        },
        {
          x: 0.72218918800354,
          y: 0.7901598215103149,
        },
        {
          x: 0.6775728464126587,
          y: 0.7901598215103149,
        },
      ],
    },
  },
  {
    id: "191",
    key: "UNIT_PRICE",
    resultText: "1,270.00",
    confidence: 1,
    hightLightZone: {
      page: "2",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.8280067443847656,
        },
        {
          x: 0.7209994196891785,
          y: 0.8280067443847656,
        },
        {
          x: 0.7209994196891785,
          y: 0.8351556062698364,
        },
        {
          x: 0.6781677603721619,
          y: 0.8351556062698364,
        },
      ],
    },
  },
  {
    id: "196",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9999879598617554,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.19214753806591034,
          y: 0.27039527893066406,
        },
        {
          x: 0.34205830097198486,
          y: 0.27039527893066406,
        },
        {
          x: 0.34205830097198486,
          y: 0.2771236300468445,
        },
        {
          x: 0.19214753806591034,
          y: 0.2771236300468445,
        },
      ],
    },
  },
  {
    id: "197",
    key: "DESCRIPTION",
    resultText: "LES BEIGES HEALTHY GLOW SUN-KISSED POWDER LIGHT CORAL\n15g",
    confidence: 0.9973020553588867,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.16240333020687103,
          y: 0.31665265560150146,
        },
        {
          x: 0.5074360370635986,
          y: 0.31665265560150146,
        },
        {
          x: 0.5074360370635986,
          y: 0.3334735035896301,
        },
        {
          x: 0.16240333020687103,
          y: 0.3334735035896301,
        },
      ],
    },
  },
  {
    id: "198",
    key: "DESCRIPTION",
    resultText: "LES BEIGES HEALTHY GLOW SUN-KISSED POWDER MEDIUM\nCORAL 15g",
    confidence: 0.9998823404312134,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.16240333020687103,
          y: 0.35281750559806824,
        },
        {
          x: 0.4788816273212433,
          y: 0.35281750559806824,
        },
        {
          x: 0.4788816273212433,
          y: 0.3700588643550873,
        },
        {
          x: 0.16240333020687103,
          y: 0.3700588643550873,
        },
      ],
    },
  },
  {
    id: "199",
    key: "DESCRIPTION",
    resultText: "LES BEIGES HEALTHY GLOW LIP BALM MEDIUM",
    confidence: 0.9999957084655762,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.3889823257923126,
        },
        {
          x: 0.41106483340263367,
          y: 0.3889823257923126,
        },
        {
          x: 0.41106483340263367,
          y: 0.3948696255683899,
        },
        {
          x: 0.1641879826784134,
          y: 0.3948696255683899,
        },
      ],
    },
  },
  {
    id: "200",
    key: "DESCRIPTION",
    resultText: "STYLO YEUX WATERPROOF ESPRESSO",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.41547518968582153,
        },
        {
          x: 0.3712076246738434,
          y: 0.41547518968582153,
        },
        {
          x: 0.3712076246738434,
          y: 0.4213624894618988,
        },
        {
          x: 0.1641879826784134,
          y: 0.4213624894618988,
        },
      ],
    },
  },
  {
    id: "201",
    key: "DESCRIPTION",
    resultText: "STYLO YEUX WATERPROOF NOIR INTENSE",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.44196805357933044,
        },
        {
          x: 0.3890541195869446,
          y: 0.44196805357933044,
        },
        {
          x: 0.3890541195869446,
          y: 0.4478553533554077,
        },
        {
          x: 0.1641879826784134,
          y: 0.4478553533554077,
        },
      ],
    },
  },
  {
    id: "202",
    key: "DESCRIPTION",
    resultText: "STYLO YEUX WATERPROOF BRUN AGAPE",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.46888139843940735,
        },
        {
          x: 0.3831053078174591,
          y: 0.46888139843940735,
        },
        {
          x: 0.3831053078174591,
          y: 0.474768728017807,
        },
        {
          x: 0.1641879826784134,
          y: 0.474768728017807,
        },
      ],
    },
  },
  {
    id: "203",
    key: "DESCRIPTION",
    resultText: "NOIR ALLURE MASCARA 10 NOIR",
    confidence: 0.9999947547912598,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.49579477310180664,
        },
        {
          x: 0.33432480692863464,
          y: 0.49579477310180664,
        },
        {
          x: 0.33432480692863464,
          y: 0.5012615919113159,
        },
        {
          x: 0.1641879826784134,
          y: 0.5012615919113159,
        },
      ],
    },
  },
  {
    id: "204",
    key: "DESCRIPTION",
    resultText: "LE VOLUME STRETCH EXTREME NOIR",
    confidence: 0.9999179840087891,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.1641879826784134,
          y: 0.5218670964241028,
        },
        {
          x: 0.3622843623161316,
          y: 0.5218670964241028,
        },
        {
          x: 0.3622843623161316,
          y: 0.5281749367713928,
        },
        {
          x: 0.1641879826784134,
          y: 0.5281749367713928,
        },
      ],
    },
  },
  {
    id: "205",
    key: "INVOICE_NO",
    resultText: "SIN0064393",
    confidence: 0.999996542930603,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8185603618621826,
          y: 0.15054668486118317,
        },
        {
          x: 0.8762641549110413,
          y: 0.15054668486118317,
        },
        {
          x: 0.8762641549110413,
          y: 0.15643398463726044,
        },
        {
          x: 0.8185603618621826,
          y: 0.15643398463726044,
        },
      ],
    },
  },
  {
    id: "206",
    key: "ITEM",
    resultText: "0186362",
    confidence: 0.9999971389770508,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.31707316637039185,
        },
        {
          x: 0.11421772837638855,
          y: 0.31707316637039185,
        },
        {
          x: 0.11421772837638855,
          y: 0.32253995537757874,
        },
        {
          x: 0.06960142403841019,
          y: 0.32253995537757874,
        },
      ],
    },
  },
  {
    id: "207",
    key: "ITEM",
    resultText: "0186366",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.35281750559806824,
        },
        {
          x: 0.11362284421920776,
          y: 0.35281750559806824,
        },
        {
          x: 0.11362284421920776,
          y: 0.35828426480293274,
        },
        {
          x: 0.06960142403841019,
          y: 0.35828426480293274,
        },
      ],
    },
  },
  {
    id: "208",
    key: "ITEM",
    resultText: "0186880",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.3889823257923126,
        },
        {
          x: 0.11362284421920776,
          y: 0.3889823257923126,
        },
        {
          x: 0.11362284421920776,
          y: 0.3944491147994995,
        },
        {
          x: 0.06960142403841019,
          y: 0.3944491147994995,
        },
      ],
    },
  },
  {
    id: "209",
    key: "ITEM",
    resultText: "0187016",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.4158957004547119,
        },
        {
          x: 0.11362284421920776,
          y: 0.4158957004547119,
        },
        {
          x: 0.11362284421920776,
          y: 0.4213624894618988,
        },
        {
          x: 0.06960142403841019,
          y: 0.4213624894618988,
        },
      ],
    },
  },
  {
    id: "210",
    key: "ITEM",
    resultText: "0187026",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.4423885643482208,
        },
        {
          x: 0.11421772837638855,
          y: 0.4423885643482208,
        },
        {
          x: 0.11421772837638855,
          y: 0.44743481278419495,
        },
        {
          x: 0.06960142403841019,
          y: 0.44743481278419495,
        },
      ],
    },
  },
  {
    id: "211",
    key: "ITEM",
    resultText: "0187032",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06960142403841019,
          y: 0.4693019390106201,
        },
        {
          x: 0.11362284421920776,
          y: 0.4693019390106201,
        },
        {
          x: 0.11362284421920776,
          y: 0.474768728017807,
        },
        {
          x: 0.06960142403841019,
          y: 0.474768728017807,
        },
      ],
    },
  },
  {
    id: "212",
    key: "ITEM",
    resultText: "0190010",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.4949537515640259,
        },
        {
          x: 0.11362284421920776,
          y: 0.4949537515640259,
        },
        {
          x: 0.11362284421920776,
          y: 0.5012615919113159,
        },
        {
          x: 0.06900654733181,
          y: 0.5012615919113159,
        },
      ],
    },
  },
  {
    id: "213",
    key: "ITEM",
    resultText: "0191815",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.06900654733181,
          y: 0.5222876071929932,
        },
        {
          x: 0.11421772837638855,
          y: 0.5222876071929932,
        },
        {
          x: 0.11421772837638855,
          y: 0.5281749367713928,
        },
        {
          x: 0.06900654733181,
          y: 0.5281749367713928,
        },
      ],
    },
  },
  {
    id: "214",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999657869338989,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.31707316637039185,
        },
        {
          x: 0.898274838924408,
          y: 0.31707316637039185,
        },
        {
          x: 0.898274838924408,
          y: 0.32253995537757874,
        },
        {
          x: 0.8887566924095154,
          y: 0.32253995537757874,
        },
      ],
    },
  },
  {
    id: "215",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999935626983643,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.35281750559806824,
        },
        {
          x: 0.898274838924408,
          y: 0.35281750559806824,
        },
        {
          x: 0.898274838924408,
          y: 0.3587048053741455,
        },
        {
          x: 0.8887566924095154,
          y: 0.3587048053741455,
        },
      ],
    },
  },
  {
    id: "216",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999957084655762,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.3894028663635254,
        },
        {
          x: 0.898274838924408,
          y: 0.3894028663635254,
        },
        {
          x: 0.898274838924408,
          y: 0.3948696255683899,
        },
        {
          x: 0.8845925331115723,
          y: 0.3948696255683899,
        },
      ],
    },
  },
  {
    id: "217",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.4158957004547119,
        },
        {
          x: 0.898274838924408,
          y: 0.4158957004547119,
        },
        {
          x: 0.898274838924408,
          y: 0.4209419786930084,
        },
        {
          x: 0.8887566924095154,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "218",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999973773956299,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.4423885643482208,
        },
        {
          x: 0.8988696932792664,
          y: 0.4423885643482208,
        },
        {
          x: 0.8988696932792664,
          y: 0.4478553533554077,
        },
        {
          x: 0.8887566924095154,
          y: 0.4478553533554077,
        },
      ],
    },
  },
  {
    id: "219",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 0.9999947547912598,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8887566924095154,
          y: 0.4693019390106201,
        },
        {
          x: 0.8988696932792664,
          y: 0.4693019390106201,
        },
        {
          x: 0.8988696932792664,
          y: 0.474768728017807,
        },
        {
          x: 0.8887566924095154,
          y: 0.474768728017807,
        },
      ],
    },
  },
  {
    id: "220",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.49579477310180664,
        },
        {
          x: 0.898274838924408,
          y: 0.49579477310180664,
        },
        {
          x: 0.898274838924408,
          y: 0.5012615919113159,
        },
        {
          x: 0.8845925331115723,
          y: 0.5012615919113159,
        },
      ],
    },
  },
  {
    id: "221",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5227081775665283,
        },
        {
          x: 0.898274838924408,
          y: 0.5227081775665283,
        },
        {
          x: 0.898274838924408,
          y: 0.5281749367713928,
        },
        {
          x: 0.8845925331115723,
          y: 0.5281749367713928,
        },
      ],
    },
  },
  {
    id: "222",
    key: "QTY",
    resultText: "60",
    confidence: 0.9999939203262329,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5817965269088745,
          y: 0.3174937069416046,
        },
        {
          x: 0.5960737466812134,
          y: 0.3174937069416046,
        },
        {
          x: 0.5960737466812134,
          y: 0.32253995537757874,
        },
        {
          x: 0.5817965269088745,
          y: 0.32253995537757874,
        },
      ],
    },
  },
  {
    id: "223",
    key: "QTY",
    resultText: "230",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.353658527135849,
        },
        {
          x: 0.5960737466812134,
          y: 0.353658527135849,
        },
        {
          x: 0.5960737466812134,
          y: 0.35828426480293274,
        },
        {
          x: 0.5758476853370667,
          y: 0.35828426480293274,
        },
      ],
    },
  },
  {
    id: "224",
    key: "QTY",
    resultText: "36",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.38982337713241577,
        },
        {
          x: 0.5966686606407166,
          y: 0.38982337713241577,
        },
        {
          x: 0.5966686606407166,
          y: 0.3944491147994995,
        },
        {
          x: 0.5823914408683777,
          y: 0.3944491147994995,
        },
      ],
    },
  },
  {
    id: "225",
    key: "QTY",
    resultText: "140",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.4158957004547119,
        },
        {
          x: 0.595478892326355,
          y: 0.4158957004547119,
        },
        {
          x: 0.595478892326355,
          y: 0.4213624894618988,
        },
        {
          x: 0.5758476853370667,
          y: 0.4213624894618988,
        },
      ],
    },
  },
  {
    id: "226",
    key: "QTY",
    resultText: "120",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5758476853370667,
          y: 0.4423885643482208,
        },
        {
          x: 0.5960737466812134,
          y: 0.4423885643482208,
        },
        {
          x: 0.5960737466812134,
          y: 0.44743481278419495,
        },
        {
          x: 0.5758476853370667,
          y: 0.44743481278419495,
        },
      ],
    },
  },
  {
    id: "227",
    key: "QTY",
    resultText: "10",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5823914408683777,
          y: 0.4697224497795105,
        },
        {
          x: 0.5960737466812134,
          y: 0.4697224497795105,
        },
        {
          x: 0.5960737466812134,
          y: 0.47434818744659424,
        },
        {
          x: 0.5823914408683777,
          y: 0.47434818744659424,
        },
      ],
    },
  },
  {
    id: "228",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5812016725540161,
          y: 0.49579477310180664,
        },
        {
          x: 0.5960737466812134,
          y: 0.49579477310180664,
        },
        {
          x: 0.5960737466812134,
          y: 0.5008410215377808,
        },
        {
          x: 0.5812016725540161,
          y: 0.5008410215377808,
        },
      ],
    },
  },
  {
    id: "229",
    key: "QTY",
    resultText: "24",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.5812016725540161,
          y: 0.5227081775665283,
        },
        {
          x: 0.5966686606407166,
          y: 0.5227081775665283,
        },
        {
          x: 0.5966686606407166,
          y: 0.5277544260025024,
        },
        {
          x: 0.5812016725540161,
          y: 0.5277544260025024,
        },
      ],
    },
  },
  {
    id: "230",
    key: "UNIT_PRICE",
    resultText: "1,590.00",
    confidence: 0.9999972581863403,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.31707316637039185,
        },
        {
          x: 0.72218918800354,
          y: 0.31707316637039185,
        },
        {
          x: 0.72218918800354,
          y: 0.32380151748657227,
        },
        {
          x: 0.6781677603721619,
          y: 0.32380151748657227,
        },
      ],
    },
  },
  {
    id: "231",
    key: "UNIT_PRICE",
    resultText: "1,590.00",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6781677603721619,
          y: 0.35239696502685547,
        },
        {
          x: 0.7215942740440369,
          y: 0.35239696502685547,
        },
        {
          x: 0.7215942740440369,
          y: 0.35954582691192627,
        },
        {
          x: 0.6781677603721619,
          y: 0.35954582691192627,
        },
      ],
    },
  },
  {
    id: "232",
    key: "UNIT_PRICE",
    resultText: "635.00",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.3894028663635254,
        },
        {
          x: 0.7209994196891785,
          y: 0.3894028663635254,
        },
        {
          x: 0.7209994196891785,
          y: 0.3948696255683899,
        },
        {
          x: 0.6864961385726929,
          y: 0.3948696255683899,
        },
      ],
    },
  },
  {
    id: "233",
    key: "UNIT_PRICE",
    resultText: "510.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6876859068870544,
          y: 0.4158957004547119,
        },
        {
          x: 0.7204045057296753,
          y: 0.4158957004547119,
        },
        {
          x: 0.7204045057296753,
          y: 0.4209419786930084,
        },
        {
          x: 0.6876859068870544,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "234",
    key: "UNIT_PRICE",
    resultText: "510.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6876859068870544,
          y: 0.4423885643482208,
        },
        {
          x: 0.7204045057296753,
          y: 0.4423885643482208,
        },
        {
          x: 0.7204045057296753,
          y: 0.44743481278419495,
        },
        {
          x: 0.6876859068870544,
          y: 0.44743481278419495,
        },
      ],
    },
  },
  {
    id: "235",
    key: "UNIT_PRICE",
    resultText: "510.00",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6876859068870544,
          y: 0.4693019390106201,
        },
        {
          x: 0.7204045057296753,
          y: 0.4693019390106201,
        },
        {
          x: 0.7204045057296753,
          y: 0.47434818744659424,
        },
        {
          x: 0.6876859068870544,
          y: 0.47434818744659424,
        },
      ],
    },
  },
  {
    id: "236",
    key: "UNIT_PRICE",
    resultText: "720.00",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.49537426233291626,
        },
        {
          x: 0.72218918800354,
          y: 0.49537426233291626,
        },
        {
          x: 0.72218918800354,
          y: 0.5008410215377808,
        },
        {
          x: 0.6864961385726929,
          y: 0.5008410215377808,
        },
      ],
    },
  },
  {
    id: "237",
    key: "UNIT_PRICE",
    resultText: "700.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "3",
      boundingBox: [
        {
          x: 0.6864961385726929,
          y: 0.5222876071929932,
        },
        {
          x: 0.7209994196891785,
          y: 0.5222876071929932,
        },
        {
          x: 0.7209994196891785,
          y: 0.5277544260025024,
        },
        {
          x: 0.6864961385726929,
          y: 0.5277544260025024,
        },
      ],
    },
  },
  {
    id: "242",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9999676942825317,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.1719214767217636,
          y: 0.3015138804912567,
        },
        {
          x: 0.3372992277145386,
          y: 0.3015138804912567,
        },
        {
          x: 0.3372992277145386,
          y: 0.3090832531452179,
        },
        {
          x: 0.1719214767217636,
          y: 0.3090832531452179,
        },
      ],
    },
  },
  {
    id: "243",
    key: "DESCRIPTION",
    resultText: "LE RECOURBE CILS DE CHANEL / EYELASH CURLER",
    confidence: 0.999514639377594,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14098750054836273,
          y: 0.3873002529144287,
        },
        {
          x: 0.44199880957603455,
          y: 0.3873002529144287,
        },
        {
          x: 0.44199880957603455,
          y: 0.39360806345939636,
        },
        {
          x: 0.14098750054836273,
          y: 0.39360806345939636,
        },
      ],
    },
  },
  {
    id: "244",
    key: "DESCRIPTION",
    resultText: "LA BASE CAMELIA PROTECTIVE AND SMOOTHING BASE COAT",
    confidence: 0.9999500513076782,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4070647656917572,
        },
        {
          x: 0.49851280450820923,
          y: 0.4070647656917572,
        },
        {
          x: 0.49851280450820923,
          y: 0.41337257623672485,
        },
        {
          x: 0.14336703717708588,
          y: 0.41337257623672485,
        },
      ],
    },
  },
  {
    id: "245",
    key: "DESCRIPTION",
    resultText: "LE GEL COAT",
    confidence: 0.9999687671661377,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4264087378978729,
        },
        {
          x: 0.2195121943950653,
          y: 0.4264087378978729,
        },
        {
          x: 0.2195121943950653,
          y: 0.43313708901405334,
        },
        {
          x: 0.14336703717708588,
          y: 0.43313708901405334,
        },
      ],
    },
  },
  {
    id: "246",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET INTENSE",
    confidence: 0.9999966621398926,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4461732506752014,
        },
        {
          x: 0.336109459400177,
          y: 0.4461732506752014,
        },
        {
          x: 0.336109459400177,
          y: 0.45290160179138184,
        },
        {
          x: 0.14336703717708588,
          y: 0.45290160179138184,
        },
      ],
    },
  },
  {
    id: "247",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET FLAMBOYANTE",
    confidence: 0.9999924898147583,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4659377634525299,
        },
        {
          x: 0.37299227714538574,
          y: 0.4659377634525299,
        },
        {
          x: 0.37299227714538574,
          y: 0.4730866253376007,
        },
        {
          x: 0.14336703717708588,
          y: 0.4730866253376007,
        },
      ],
    },
  },
  {
    id: "248",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET ARDENTE",
    confidence: 0.9999970197677612,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4857022762298584,
        },
        {
          x: 0.34205830097198486,
          y: 0.4857022762298584,
        },
        {
          x: 0.34205830097198486,
          y: 0.4928511381149292,
        },
        {
          x: 0.14336703717708588,
          y: 0.4928511381149292,
        },
      ],
    },
  },
  {
    id: "249",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET LEGENDAIRE",
    confidence: 0.9999834299087524,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.5050462484359741,
        },
        {
          x: 0.36109459400177,
          y: 0.5050462484359741,
        },
        {
          x: 0.36109459400177,
          y: 0.5126156210899353,
        },
        {
          x: 0.14396192133426666,
          y: 0.5126156210899353,
        },
      ],
    },
  },
  {
    id: "250",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET INSPIRANTE",
    confidence: 0.9999935626983643,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5256518125534058,
        },
        {
          x: 0.35633552074432373,
          y: 0.5256518125534058,
        },
        {
          x: 0.35633552074432373,
          y: 0.5323801636695862,
        },
        {
          x: 0.14336703717708588,
          y: 0.5323801636695862,
        },
      ],
    },
  },
  {
    id: "251",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET PARADOXALE",
    confidence: 0.9999876022338867,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5454162955284119,
        },
        {
          x: 0.3658536672592163,
          y: 0.5454162955284119,
        },
        {
          x: 0.3658536672592163,
          y: 0.5521446466445923,
        },
        {
          x: 0.14336703717708588,
          y: 0.5521446466445923,
        },
      ],
    },
  },
  {
    id: "252",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET SOPHISTIQUEE",
    confidence: 0.9999936819076538,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5651808381080627,
        },
        {
          x: 0.37358716130256653,
          y: 0.5651808381080627,
        },
        {
          x: 0.37358716130256653,
          y: 0.5719091892242432,
        },
        {
          x: 0.14336703717708588,
          y: 0.5719091892242432,
        },
      ],
    },
  },
  {
    id: "253",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET INTEMPORELLE",
    confidence: 0.999996542930603,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5849453210830688,
        },
        {
          x: 0.37656158208847046,
          y: 0.5849453210830688,
        },
        {
          x: 0.37656158208847046,
          y: 0.5916736721992493,
        },
        {
          x: 0.14336703717708588,
          y: 0.5916736721992493,
        },
      ],
    },
  },
  {
    id: "254",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET INTUITIVE",
    confidence: 0.9999958276748657,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6047098636627197,
        },
        {
          x: 0.34205830097198486,
          y: 0.6047098636627197,
        },
        {
          x: 0.34205830097198486,
          y: 0.6114381551742554,
        },
        {
          x: 0.14336703717708588,
          y: 0.6114381551742554,
        },
      ],
    },
  },
  {
    id: "255",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET ESSENTIELLE",
    confidence: 0.9999942779541016,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6341463327407837,
        },
        {
          x: 0.36466389894485474,
          y: 0.6341463327407837,
        },
        {
          x: 0.36466389894485474,
          y: 0.6408746838569641,
        },
        {
          x: 0.14336703717708588,
          y: 0.6408746838569641,
        },
      ],
    },
  },
  {
    id: "256",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE VELVET ETERNELLE",
    confidence: 0.9999961853027344,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6539108753204346,
        },
        {
          x: 0.35455086827278137,
          y: 0.6539108753204346,
        },
        {
          x: 0.35455086827278137,
          y: 0.6610597372055054,
        },
        {
          x: 0.14336703717708588,
          y: 0.6610597372055054,
        },
      ],
    },
  },
  {
    id: "257",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE STILL",
    confidence: 0.999993085861206,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6736753582954407,
        },
        {
          x: 0.311124324798584,
          y: 0.6736753582954407,
        },
        {
          x: 0.311124324798584,
          y: 0.6804037094116211,
        },
        {
          x: 0.14336703717708588,
          y: 0.6804037094116211,
        },
      ],
    },
  },
  {
    id: "258",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE ULTIMATE",
    confidence: 0.9999955892562866,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6930193305015564,
        },
        {
          x: 0.33848899602890015,
          y: 0.6930193305015564,
        },
        {
          x: 0.33848899602890015,
          y: 0.7005887031555176,
        },
        {
          x: 0.14336703717708588,
          y: 0.7005887031555176,
        },
      ],
    },
  },
  {
    id: "259",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE EXIGENCE",
    confidence: 0.9999948740005493,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7224558591842651,
        },
        {
          x: 0.3408685326576233,
          y: 0.7224558591842651,
        },
        {
          x: 0.3408685326576233,
          y: 0.7291842103004456,
        },
        {
          x: 0.14336703717708588,
          y: 0.7291842103004456,
        },
      ],
    },
  },
  {
    id: "260",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE IMPERTURBABLE",
    confidence: 0.9999961853027344,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7422203421592712,
        },
        {
          x: 0.3789411187171936,
          y: 0.7422203421592712,
        },
        {
          x: 0.3789411187171936,
          y: 0.7489486932754517,
        },
        {
          x: 0.14336703717708588,
          y: 0.7489486932754517,
        },
      ],
    },
  },
  {
    id: "261",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE PERMANENT",
    confidence: 0.9999957084655762,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7619848847389221,
        },
        {
          x: 0.3539559841156006,
          y: 0.7619848847389221,
        },
        {
          x: 0.3539559841156006,
          y: 0.7687131762504578,
        },
        {
          x: 0.14336703717708588,
          y: 0.7687131762504578,
        },
      ],
    },
  },
  {
    id: "262",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE UNLIMITED",
    confidence: 0.9999949932098389,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7821698784828186,
        },
        {
          x: 0.34324806928634644,
          y: 0.7821698784828186,
        },
        {
          x: 0.34324806928634644,
          y: 0.788898229598999,
        },
        {
          x: 0.14336703717708588,
          y: 0.788898229598999,
        },
      ],
    },
  },
  {
    id: "263",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE ICONIQUE",
    confidence: 0.9999966621398926,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.8015138506889343,
        },
        {
          x: 0.33908388018608093,
          y: 0.8015138506889343,
        },
        {
          x: 0.33908388018608093,
          y: 0.8090832829475403,
        },
        {
          x: 0.14396192133426666,
          y: 0.8090832829475403,
        },
      ],
    },
  },
  {
    id: "264",
    key: "DESCRIPTION",
    resultText: "ROUGE ALLURE LAQUE INVINCIBLE",
    confidence: 0.9999958276748657,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.8216989040374756,
        },
        {
          x: 0.3456276059150696,
          y: 0.8216989040374756,
        },
        {
          x: 0.3456276059150696,
          y: 0.828427255153656,
        },
        {
          x: 0.14336703717708588,
          y: 0.828427255153656,
        },
      ],
    },
  },
  {
    id: "265",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BAUME",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.8414633870124817,
        },
        {
          x: 0.26888757944107056,
          y: 0.8414633870124817,
        },
        {
          x: 0.26888757944107056,
          y: 0.8481917381286621,
        },
        {
          x: 0.14336703717708588,
          y: 0.8481917381286621,
        },
      ],
    },
  },
  {
    id: "266",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BAUME MY ROSE",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.861648440361023,
        },
        {
          x: 0.32599642872810364,
          y: 0.861648440361023,
        },
        {
          x: 0.32599642872810364,
          y: 0.867956280708313,
        },
        {
          x: 0.14396192133426666,
          y: 0.867956280708313,
        },
      ],
    },
  },
  {
    id: "267",
    key: "INVOICE_NO",
    resultText: "SIN0064394",
    confidence: 0.999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.80904221534729,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.1980656087398529,
        },
        {
          x: 0.80904221534729,
          y: 0.1980656087398529,
        },
      ],
    },
  },
  {
    id: "268",
    key: "ITEM",
    resultText: "0137880",
    confidence: 0.9999942779541016,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.3877207636833191,
        },
        {
          x: 0.0945865586400032,
          y: 0.3877207636833191,
        },
        {
          x: 0.0945865586400032,
          y: 0.393187552690506,
        },
        {
          x: 0.04521118476986885,
          y: 0.393187552690506,
        },
      ],
    },
  },
  {
    id: "269",
    key: "ITEM",
    resultText: "0158255",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.4070647656917572,
        },
        {
          x: 0.0945865586400032,
          y: 0.4070647656917572,
        },
        {
          x: 0.0945865586400032,
          y: 0.41337257623672485,
        },
        {
          x: 0.04521118476986885,
          y: 0.41337257623672485,
        },
      ],
    },
  },
  {
    id: "270",
    key: "ITEM",
    resultText: "0158368",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.4268292784690857,
        },
        {
          x: 0.0945865586400032,
          y: 0.4268292784690857,
        },
        {
          x: 0.0945865586400032,
          y: 0.43271657824516296,
        },
        {
          x: 0.04521118476986885,
          y: 0.43271657824516296,
        },
      ],
    },
  },
  {
    id: "271",
    key: "ITEM",
    resultText: "0162457",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.4465937614440918,
        },
        {
          x: 0.09220702201128006,
          y: 0.4465937614440918,
        },
        {
          x: 0.09220702201128006,
          y: 0.45248109102249146,
        },
        {
          x: 0.04580606892704964,
          y: 0.45248109102249146,
        },
      ],
    },
  },
  {
    id: "272",
    key: "ITEM",
    resultText: "0162477",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.46677881479263306,
        },
        {
          x: 0.09220702201128006,
          y: 0.46677881479263306,
        },
        {
          x: 0.09220702201128006,
          y: 0.47224557399749756,
        },
        {
          x: 0.04580606892704964,
          y: 0.47224557399749756,
        },
      ],
    },
  },
  {
    id: "273",
    key: "ITEM",
    resultText: "0162487",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.4861227869987488,
        },
        {
          x: 0.09220702201128006,
          y: 0.4861227869987488,
        },
        {
          x: 0.09220702201128006,
          y: 0.49201008677482605,
        },
        {
          x: 0.04580606892704964,
          y: 0.49201008677482605,
        },
      ],
    },
  },
  {
    id: "274",
    key: "ITEM",
    resultText: "0162517",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5058873295783997,
        },
        {
          x: 0.09220702201128006,
          y: 0.5058873295783997,
        },
        {
          x: 0.09220702201128006,
          y: 0.5117745995521545,
        },
        {
          x: 0.04580606892704964,
          y: 0.5117745995521545,
        },
      ],
    },
  },
  {
    id: "275",
    key: "ITEM",
    resultText: "0162537",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5260723233222961,
        },
        {
          x: 0.09220702201128006,
          y: 0.5260723233222961,
        },
        {
          x: 0.09220702201128006,
          y: 0.5319596529006958,
        },
        {
          x: 0.04580606892704964,
          y: 0.5319596529006958,
        },
      ],
    },
  },
  {
    id: "276",
    key: "ITEM",
    resultText: "0162547",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.545836865901947,
        },
        {
          x: 0.09280190616846085,
          y: 0.545836865901947,
        },
        {
          x: 0.09280190616846085,
          y: 0.5517241358757019,
        },
        {
          x: 0.04521118476986885,
          y: 0.5517241358757019,
        },
      ],
    },
  },
  {
    id: "277",
    key: "ITEM",
    resultText: "0162557",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5656013488769531,
        },
        {
          x: 0.09220702201128006,
          y: 0.5656013488769531,
        },
        {
          x: 0.09220702201128006,
          y: 0.571488618850708,
        },
        {
          x: 0.04580606892704964,
          y: 0.571488618850708,
        },
      ],
    },
  },
  {
    id: "278",
    key: "ITEM",
    resultText: "0162607",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5853658318519592,
        },
        {
          x: 0.09220702201128006,
          y: 0.5853658318519592,
        },
        {
          x: 0.09220702201128006,
          y: 0.5916736721992493,
        },
        {
          x: 0.04580606892704964,
          y: 0.5916736721992493,
        },
      ],
    },
  },
  {
    id: "279",
    key: "ITEM",
    resultText: "0162617",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.6051303744316101,
        },
        {
          x: 0.09280190616846085,
          y: 0.6051303744316101,
        },
        {
          x: 0.09280190616846085,
          y: 0.611017644405365,
        },
        {
          x: 0.04580606892704964,
          y: 0.611017644405365,
        },
      ],
    },
  },
  {
    id: "280",
    key: "ITEM",
    resultText: "0162637",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.6345668435096741,
        },
        {
          x: 0.09220702201128006,
          y: 0.6345668435096741,
        },
        {
          x: 0.09220702201128006,
          y: 0.6404541730880737,
        },
        {
          x: 0.04580606892704964,
          y: 0.6404541730880737,
        },
      ],
    },
  },
  {
    id: "281",
    key: "ITEM",
    resultText: "0162647",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.654331386089325,
        },
        {
          x: 0.09220702201128006,
          y: 0.654331386089325,
        },
        {
          x: 0.09220702201128006,
          y: 0.6602186560630798,
        },
        {
          x: 0.04580606892704964,
          y: 0.6602186560630798,
        },
      ],
    },
  },
  {
    id: "282",
    key: "ITEM",
    resultText: "0165062",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.674095869064331,
        },
        {
          x: 0.0945865586400032,
          y: 0.674095869064331,
        },
        {
          x: 0.0945865586400032,
          y: 0.6799831986427307,
        },
        {
          x: 0.04521118476986885,
          y: 0.6799831986427307,
        },
      ],
    },
  },
  {
    id: "283",
    key: "ITEM",
    resultText: "0165063",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.6938604116439819,
        },
        {
          x: 0.09399167448282242,
          y: 0.6938604116439819,
        },
        {
          x: 0.09399167448282242,
          y: 0.7001681923866272,
        },
        {
          x: 0.04580606892704964,
          y: 0.7001681923866272,
        },
      ],
    },
  },
  {
    id: "284",
    key: "ITEM",
    resultText: "0165064",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7228763699531555,
        },
        {
          x: 0.09339679032564163,
          y: 0.7228763699531555,
        },
        {
          x: 0.09339679032564163,
          y: 0.7291842103004456,
        },
        {
          x: 0.04521118476986885,
          y: 0.7291842103004456,
        },
      ],
    },
  },
  {
    id: "285",
    key: "ITEM",
    resultText: "0165065",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7426408529281616,
        },
        {
          x: 0.0945865586400032,
          y: 0.7426408529281616,
        },
        {
          x: 0.0945865586400032,
          y: 0.7485281825065613,
        },
        {
          x: 0.04521118476986885,
          y: 0.7485281825065613,
        },
      ],
    },
  },
  {
    id: "286",
    key: "ITEM",
    resultText: "0165066",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7624053955078125,
        },
        {
          x: 0.0945865586400032,
          y: 0.7624053955078125,
        },
        {
          x: 0.0945865586400032,
          y: 0.7682926654815674,
        },
        {
          x: 0.04521118476986885,
          y: 0.7682926654815674,
        },
      ],
    },
  },
  {
    id: "287",
    key: "ITEM",
    resultText: "0165068",
    confidence: 0.9999978542327881,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7821698784828186,
        },
        {
          x: 0.0945865586400032,
          y: 0.7821698784828186,
        },
        {
          x: 0.0945865586400032,
          y: 0.788898229598999,
        },
        {
          x: 0.04521118476986885,
          y: 0.788898229598999,
        },
      ],
    },
  },
  {
    id: "288",
    key: "ITEM",
    resultText: "0165072",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8023549318313599,
        },
        {
          x: 0.0945865586400032,
          y: 0.8023549318313599,
        },
        {
          x: 0.0945865586400032,
          y: 0.8082422018051147,
        },
        {
          x: 0.04521118476986885,
          y: 0.8082422018051147,
        },
      ],
    },
  },
  {
    id: "289",
    key: "ITEM",
    resultText: "0165073",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.822119414806366,
        },
        {
          x: 0.09339679032564163,
          y: 0.822119414806366,
        },
        {
          x: 0.09339679032564163,
          y: 0.828427255153656,
        },
        {
          x: 0.04521118476986885,
          y: 0.828427255153656,
        },
      ],
    },
  },
  {
    id: "290",
    key: "ITEM",
    resultText: "0171900",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8414633870124817,
        },
        {
          x: 0.09339679032564163,
          y: 0.8414633870124817,
        },
        {
          x: 0.09339679032564163,
          y: 0.8477712273597717,
        },
        {
          x: 0.04521118476986885,
          y: 0.8477712273597717,
        },
      ],
    },
  },
  {
    id: "291",
    key: "ITEM",
    resultText: "0171918",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8620689511299133,
        },
        {
          x: 0.09518144279718399,
          y: 0.8620689511299133,
        },
        {
          x: 0.09518144279718399,
          y: 0.8675357699394226,
        },
        {
          x: 0.04521118476986885,
          y: 0.8675357699394226,
        },
      ],
    },
  },
  {
    id: "292",
    key: "MADE_IN",
    resultText: "JP",
    confidence: 0.9998955726623535,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8810232281684875,
          y: 0.3877207636833191,
        },
        {
          x: 0.8947055339813232,
          y: 0.3877207636833191,
        },
        {
          x: 0.8947055339813232,
          y: 0.393187552690506,
        },
        {
          x: 0.8810232281684875,
          y: 0.393187552690506,
        },
      ],
    },
  },
  {
    id: "293",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999879598617554,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.4074852764606476,
        },
        {
          x: 0.8947055339813232,
          y: 0.4074852764606476,
        },
        {
          x: 0.8947055339813232,
          y: 0.41337257623672485,
        },
        {
          x: 0.879833459854126,
          y: 0.41337257623672485,
        },
      ],
    },
  },
  {
    id: "294",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999849796295166,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.4268292784690857,
        },
        {
          x: 0.8947055339813232,
          y: 0.4268292784690857,
        },
        {
          x: 0.8947055339813232,
          y: 0.43271657824516296,
        },
        {
          x: 0.8786436915397644,
          y: 0.43271657824516296,
        },
      ],
    },
  },
  {
    id: "295",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.4465937614440918,
        },
        {
          x: 0.8947055339813232,
          y: 0.4465937614440918,
        },
        {
          x: 0.8947055339813232,
          y: 0.45248109102249146,
        },
        {
          x: 0.8786436915397644,
          y: 0.45248109102249146,
        },
      ],
    },
  },
  {
    id: "296",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.46677881479263306,
        },
        {
          x: 0.8941106200218201,
          y: 0.46677881479263306,
        },
        {
          x: 0.8941106200218201,
          y: 0.47224557399749756,
        },
        {
          x: 0.8786436915397644,
          y: 0.47224557399749756,
        },
      ],
    },
  },
  {
    id: "297",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.48654332756996155,
        },
        {
          x: 0.8953003883361816,
          y: 0.48654332756996155,
        },
        {
          x: 0.8953003883361816,
          y: 0.4924306273460388,
        },
        {
          x: 0.879833459854126,
          y: 0.4924306273460388,
        },
      ],
    },
  },
  {
    id: "298",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.50630784034729,
        },
        {
          x: 0.8947055339813232,
          y: 0.50630784034729,
        },
        {
          x: 0.8947055339813232,
          y: 0.5117745995521545,
        },
        {
          x: 0.8786436915397644,
          y: 0.5117745995521545,
        },
      ],
    },
  },
  {
    id: "299",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.5264928340911865,
        },
        {
          x: 0.8947055339813232,
          y: 0.5264928340911865,
        },
        {
          x: 0.8947055339813232,
          y: 0.5319596529006958,
        },
        {
          x: 0.8786436915397644,
          y: 0.5319596529006958,
        },
      ],
    },
  },
  {
    id: "300",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.5462573766708374,
        },
        {
          x: 0.8947055339813232,
          y: 0.5462573766708374,
        },
        {
          x: 0.8947055339813232,
          y: 0.5521446466445923,
        },
        {
          x: 0.879833459854126,
          y: 0.5521446466445923,
        },
      ],
    },
  },
  {
    id: "301",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.5660218596458435,
        },
        {
          x: 0.8947055339813232,
          y: 0.5660218596458435,
        },
        {
          x: 0.8947055339813232,
          y: 0.571488618850708,
        },
        {
          x: 0.8792385458946228,
          y: 0.571488618850708,
        },
      ],
    },
  },
  {
    id: "302",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.5857864022254944,
        },
        {
          x: 0.8947055339813232,
          y: 0.5857864022254944,
        },
        {
          x: 0.8947055339813232,
          y: 0.5916736721992493,
        },
        {
          x: 0.879833459854126,
          y: 0.5916736721992493,
        },
      ],
    },
  },
  {
    id: "303",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6055508852005005,
        },
        {
          x: 0.8947055339813232,
          y: 0.6055508852005005,
        },
        {
          x: 0.8947055339813232,
          y: 0.611017644405365,
        },
        {
          x: 0.879833459854126,
          y: 0.611017644405365,
        },
      ],
    },
  },
  {
    id: "304",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.6349874138832092,
        },
        {
          x: 0.8947055339813232,
          y: 0.6349874138832092,
        },
        {
          x: 0.8947055339813232,
          y: 0.6404541730880737,
        },
        {
          x: 0.8786436915397644,
          y: 0.6404541730880737,
        },
      ],
    },
  },
  {
    id: "305",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6547518968582153,
        },
        {
          x: 0.8953003883361816,
          y: 0.6547518968582153,
        },
        {
          x: 0.8953003883361816,
          y: 0.6606391668319702,
        },
        {
          x: 0.879833459854126,
          y: 0.6606391668319702,
        },
      ],
    },
  },
  {
    id: "306",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.6745163798332214,
        },
        {
          x: 0.8947055339813232,
          y: 0.6745163798332214,
        },
        {
          x: 0.8947055339813232,
          y: 0.6799831986427307,
        },
        {
          x: 0.8786436915397644,
          y: 0.6799831986427307,
        },
      ],
    },
  },
  {
    id: "307",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6942809224128723,
        },
        {
          x: 0.8947055339813232,
          y: 0.6942809224128723,
        },
        {
          x: 0.8947055339813232,
          y: 0.6997476816177368,
        },
        {
          x: 0.879833459854126,
          y: 0.6997476816177368,
        },
      ],
    },
  },
  {
    id: "308",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.7228763699531555,
        },
        {
          x: 0.8941106200218201,
          y: 0.7228763699531555,
        },
        {
          x: 0.8941106200218201,
          y: 0.7287636399269104,
        },
        {
          x: 0.879833459854126,
          y: 0.7287636399269104,
        },
      ],
    },
  },
  {
    id: "309",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.7426408529281616,
        },
        {
          x: 0.8947055339813232,
          y: 0.7426408529281616,
        },
        {
          x: 0.8947055339813232,
          y: 0.7485281825065613,
        },
        {
          x: 0.8786436915397644,
          y: 0.7485281825065613,
        },
      ],
    },
  },
  {
    id: "310",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.7628259062767029,
        },
        {
          x: 0.8941106200218201,
          y: 0.7628259062767029,
        },
        {
          x: 0.8941106200218201,
          y: 0.7682926654815674,
        },
        {
          x: 0.8786436915397644,
          y: 0.7682926654815674,
        },
      ],
    },
  },
  {
    id: "311",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.7830109596252441,
        },
        {
          x: 0.8947055339813232,
          y: 0.7830109596252441,
        },
        {
          x: 0.8947055339813232,
          y: 0.7884777188301086,
        },
        {
          x: 0.8786436915397644,
          y: 0.7884777188301086,
        },
      ],
    },
  },
  {
    id: "312",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.8027754426002502,
        },
        {
          x: 0.8953003883361816,
          y: 0.8027754426002502,
        },
        {
          x: 0.8953003883361816,
          y: 0.8086627125740051,
        },
        {
          x: 0.879833459854126,
          y: 0.8086627125740051,
        },
      ],
    },
  },
  {
    id: "313",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.8225399255752563,
        },
        {
          x: 0.8947055339813232,
          y: 0.8225399255752563,
        },
        {
          x: 0.8947055339813232,
          y: 0.8280067443847656,
        },
        {
          x: 0.8786436915397644,
          y: 0.8280067443847656,
        },
      ],
    },
  },
  {
    id: "314",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.8423044681549072,
        },
        {
          x: 0.8947055339813232,
          y: 0.8423044681549072,
        },
        {
          x: 0.8947055339813232,
          y: 0.8481917381286621,
        },
        {
          x: 0.879833459854126,
          y: 0.8481917381286621,
        },
      ],
    },
  },
  {
    id: "315",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.8620689511299133,
        },
        {
          x: 0.8947055339813232,
          y: 0.8620689511299133,
        },
        {
          x: 0.8947055339813232,
          y: 0.8675357699394226,
        },
        {
          x: 0.8792385458946228,
          y: 0.8675357699394226,
        },
      ],
    },
  },
  {
    id: "316",
    key: "QTY",
    resultText: "60",
    confidence: 0.999994158744812,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.3877207636833191,
        },
        {
          x: 0.5859607458114624,
          y: 0.3877207636833191,
        },
        {
          x: 0.5859607458114624,
          y: 0.393187552690506,
        },
        {
          x: 0.5698988437652588,
          y: 0.393187552690506,
        },
      ],
    },
  },
  {
    id: "317",
    key: "QTY",
    resultText: "24",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.4074852764606476,
        },
        {
          x: 0.587150514125824,
          y: 0.4074852764606476,
        },
        {
          x: 0.587150514125824,
          y: 0.41337257623672485,
        },
        {
          x: 0.570493757724762,
          y: 0.41337257623672485,
        },
      ],
    },
  },
  {
    id: "318",
    key: "QTY",
    resultText: "60",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.4272497892379761,
        },
        {
          x: 0.5859607458114624,
          y: 0.4272497892379761,
        },
        {
          x: 0.5859607458114624,
          y: 0.43271657824516296,
        },
        {
          x: 0.570493757724762,
          y: 0.43271657824516296,
        },
      ],
    },
  },
  {
    id: "319",
    key: "QTY",
    resultText: "60",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.44701430201530457,
        },
        {
          x: 0.5859607458114624,
          y: 0.44701430201530457,
        },
        {
          x: 0.5859607458114624,
          y: 0.45248109102249146,
        },
        {
          x: 0.570493757724762,
          y: 0.45248109102249146,
        },
      ],
    },
  },
  {
    id: "320",
    key: "QTY",
    resultText: "48",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.46677881479263306,
        },
        {
          x: 0.5859607458114624,
          y: 0.46677881479263306,
        },
        {
          x: 0.5859607458114624,
          y: 0.47224557399749756,
        },
        {
          x: 0.5698988437652588,
          y: 0.47224557399749756,
        },
      ],
    },
  },
  {
    id: "321",
    key: "QTY",
    resultText: "45",
    confidence: 0.9999997615814209,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.48654332756996155,
        },
        {
          x: 0.5865556001663208,
          y: 0.48654332756996155,
        },
        {
          x: 0.5865556001663208,
          y: 0.49201008677482605,
        },
        {
          x: 0.5698988437652588,
          y: 0.49201008677482605,
        },
      ],
    },
  },
  {
    id: "322",
    key: "QTY",
    resultText: "420",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5633551478385925,
          y: 0.50630784034729,
        },
        {
          x: 0.5865556001663208,
          y: 0.50630784034729,
        },
        {
          x: 0.5865556001663208,
          y: 0.5117745995521545,
        },
        {
          x: 0.5633551478385925,
          y: 0.5117745995521545,
        },
      ],
    },
  },
  {
    id: "323",
    key: "QTY",
    resultText: "24",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.5269133448600769,
        },
        {
          x: 0.5859607458114624,
          y: 0.5269133448600769,
        },
        {
          x: 0.5859607458114624,
          y: 0.5319596529006958,
        },
        {
          x: 0.570493757724762,
          y: 0.5319596529006958,
        },
      ],
    },
  },
  {
    id: "324",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.5462573766708374,
        },
        {
          x: 0.5865556001663208,
          y: 0.5462573766708374,
        },
        {
          x: 0.5865556001663208,
          y: 0.5513036251068115,
        },
        {
          x: 0.5710886120796204,
          y: 0.5513036251068115,
        },
      ],
    },
  },
  {
    id: "325",
    key: "QTY",
    resultText: "72",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.5660218596458435,
        },
        {
          x: 0.5865556001663208,
          y: 0.5660218596458435,
        },
        {
          x: 0.5865556001663208,
          y: 0.571488618850708,
        },
        {
          x: 0.570493757724762,
          y: 0.571488618850708,
        },
      ],
    },
  },
  {
    id: "326",
    key: "QTY",
    resultText: "40",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.5857864022254944,
        },
        {
          x: 0.5865556001663208,
          y: 0.5857864022254944,
        },
        {
          x: 0.5865556001663208,
          y: 0.5912531614303589,
        },
        {
          x: 0.5698988437652588,
          y: 0.5912531614303589,
        },
      ],
    },
  },
  {
    id: "327",
    key: "QTY",
    resultText: "120",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.6055508852005005,
        },
        {
          x: 0.5865556001663208,
          y: 0.6055508852005005,
        },
        {
          x: 0.5865556001663208,
          y: 0.611017644405365,
        },
        {
          x: 0.5639500021934509,
          y: 0.611017644405365,
        },
      ],
    },
  },
  {
    id: "328",
    key: "QTY",
    resultText: "480",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5633551478385925,
          y: 0.6349874138832092,
        },
        {
          x: 0.5859607458114624,
          y: 0.6349874138832092,
        },
        {
          x: 0.5859607458114624,
          y: 0.6404541730880737,
        },
        {
          x: 0.5633551478385925,
          y: 0.6404541730880737,
        },
      ],
    },
  },
  {
    id: "329",
    key: "QTY",
    resultText: "60",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.6547518968582153,
        },
        {
          x: 0.5859607458114624,
          y: 0.6547518968582153,
        },
        {
          x: 0.5859607458114624,
          y: 0.6602186560630798,
        },
        {
          x: 0.570493757724762,
          y: 0.6602186560630798,
        },
      ],
    },
  },
  {
    id: "330",
    key: "QTY",
    resultText: "50",
    confidence: 0.9999998807907104,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5716835260391235,
          y: 0.6745163798332214,
        },
        {
          x: 0.5859607458114624,
          y: 0.6745163798332214,
        },
        {
          x: 0.5859607458114624,
          y: 0.6799831986427307,
        },
        {
          x: 0.5716835260391235,
          y: 0.6799831986427307,
        },
      ],
    },
  },
  {
    id: "331",
    key: "QTY",
    resultText: "270",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.6942809224128723,
        },
        {
          x: 0.5865556001663208,
          y: 0.6942809224128723,
        },
        {
          x: 0.5865556001663208,
          y: 0.6997476816177368,
        },
        {
          x: 0.5639500021934509,
          y: 0.6997476816177368,
        },
      ],
    },
  },
  {
    id: "332",
    key: "QTY",
    resultText: "45",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.7232968807220459,
        },
        {
          x: 0.5865556001663208,
          y: 0.7232968807220459,
        },
        {
          x: 0.5865556001663208,
          y: 0.7287636399269104,
        },
        {
          x: 0.5698988437652588,
          y: 0.7287636399269104,
        },
      ],
    },
  },
  {
    id: "333",
    key: "QTY",
    resultText: "20",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5716835260391235,
          y: 0.7430614233016968,
        },
        {
          x: 0.5859607458114624,
          y: 0.7430614233016968,
        },
        {
          x: 0.5859607458114624,
          y: 0.7485281825065613,
        },
        {
          x: 0.5716835260391235,
          y: 0.7485281825065613,
        },
      ],
    },
  },
  {
    id: "334",
    key: "QTY",
    resultText: "60",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.7628259062767029,
        },
        {
          x: 0.5865556001663208,
          y: 0.7628259062767029,
        },
        {
          x: 0.5865556001663208,
          y: 0.7682926654815674,
        },
        {
          x: 0.570493757724762,
          y: 0.7682926654815674,
        },
      ],
    },
  },
  {
    id: "335",
    key: "QTY",
    resultText: "12",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.7830109596252441,
        },
        {
          x: 0.5865556001663208,
          y: 0.7830109596252441,
        },
        {
          x: 0.5865556001663208,
          y: 0.7884777188301086,
        },
        {
          x: 0.570493757724762,
          y: 0.7884777188301086,
        },
      ],
    },
  },
  {
    id: "336",
    key: "QTY",
    resultText: "150",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.8027754426002502,
        },
        {
          x: 0.5859607458114624,
          y: 0.8027754426002502,
        },
        {
          x: 0.5859607458114624,
          y: 0.8086627125740051,
        },
        {
          x: 0.5639500021934509,
          y: 0.8086627125740051,
        },
      ],
    },
  },
  {
    id: "337",
    key: "QTY",
    resultText: "10",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.8225399255752563,
        },
        {
          x: 0.5859607458114624,
          y: 0.8225399255752563,
        },
        {
          x: 0.5859607458114624,
          y: 0.8280067443847656,
        },
        {
          x: 0.5710886120796204,
          y: 0.8280067443847656,
        },
      ],
    },
  },
  {
    id: "338",
    key: "QTY",
    resultText: "140",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.8418839573860168,
        },
        {
          x: 0.5859607458114624,
          y: 0.8418839573860168,
        },
        {
          x: 0.5859607458114624,
          y: 0.8477712273597717,
        },
        {
          x: 0.5639500021934509,
          y: 0.8477712273597717,
        },
      ],
    },
  },
  {
    id: "339",
    key: "QTY",
    resultText: "240",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.5633551478385925,
          y: 0.8620689511299133,
        },
        {
          x: 0.5865556001663208,
          y: 0.8620689511299133,
        },
        {
          x: 0.5865556001663208,
          y: 0.8675357699394226,
        },
        {
          x: 0.5633551478385925,
          y: 0.8675357699394226,
        },
      ],
    },
  },
  {
    id: "340",
    key: "UNIT_PRICE",
    resultText: "445.00",
    confidence: 0.9999960660934448,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.3873002529144287,
        },
        {
          x: 0.7144556641578674,
          y: 0.3873002529144287,
        },
        {
          x: 0.7144556641578674,
          y: 0.39360806345939636,
        },
        {
          x: 0.6751933097839355,
          y: 0.39360806345939636,
        },
      ],
    },
  },
  {
    id: "341",
    key: "UNIT_PRICE",
    resultText: "510.00",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.4070647656917572,
        },
        {
          x: 0.7150505781173706,
          y: 0.4070647656917572,
        },
        {
          x: 0.7150505781173706,
          y: 0.41337257623672485,
        },
        {
          x: 0.6763830780982971,
          y: 0.41337257623672485,
        },
      ],
    },
  },
  {
    id: "342",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.4268292784690857,
        },
        {
          x: 0.7144556641578674,
          y: 0.4268292784690857,
        },
        {
          x: 0.7144556641578674,
          y: 0.43271657824516296,
        },
        {
          x: 0.6751933097839355,
          y: 0.43271657824516296,
        },
      ],
    },
  },
  {
    id: "343",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.44701430201530457,
        },
        {
          x: 0.7132658958435059,
          y: 0.44701430201530457,
        },
        {
          x: 0.7132658958435059,
          y: 0.45248109102249146,
        },
        {
          x: 0.6763830780982971,
          y: 0.45248109102249146,
        },
      ],
    },
  },
  {
    id: "344",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.46677881479263306,
        },
        {
          x: 0.7132658958435059,
          y: 0.46677881479263306,
        },
        {
          x: 0.7132658958435059,
          y: 0.47224557399749756,
        },
        {
          x: 0.6763830780982971,
          y: 0.47224557399749756,
        },
      ],
    },
  },
  {
    id: "345",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.48654332756996155,
        },
        {
          x: 0.713860809803009,
          y: 0.48654332756996155,
        },
        {
          x: 0.713860809803009,
          y: 0.49201008677482605,
        },
        {
          x: 0.6763830780982971,
          y: 0.49201008677482605,
        },
      ],
    },
  },
  {
    id: "346",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5058873295783997,
        },
        {
          x: 0.713860809803009,
          y: 0.5058873295783997,
        },
        {
          x: 0.713860809803009,
          y: 0.5117745995521545,
        },
        {
          x: 0.6763830780982971,
          y: 0.5117745995521545,
        },
      ],
    },
  },
  {
    id: "347",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5260723233222961,
        },
        {
          x: 0.7132658958435059,
          y: 0.5260723233222961,
        },
        {
          x: 0.7132658958435059,
          y: 0.5319596529006958,
        },
        {
          x: 0.6763830780982971,
          y: 0.5319596529006958,
        },
      ],
    },
  },
  {
    id: "348",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999996423721313,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.545836865901947,
        },
        {
          x: 0.7132658958435059,
          y: 0.545836865901947,
        },
        {
          x: 0.7132658958435059,
          y: 0.5517241358757019,
        },
        {
          x: 0.6769779920578003,
          y: 0.5517241358757019,
        },
      ],
    },
  },
  {
    id: "349",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999995231628418,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5660218596458435,
        },
        {
          x: 0.713860809803009,
          y: 0.5660218596458435,
        },
        {
          x: 0.713860809803009,
          y: 0.571488618850708,
        },
        {
          x: 0.6763830780982971,
          y: 0.571488618850708,
        },
      ],
    },
  },
  {
    id: "350",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5857864022254944,
        },
        {
          x: 0.7144556641578674,
          y: 0.5857864022254944,
        },
        {
          x: 0.7144556641578674,
          y: 0.5912531614303589,
        },
        {
          x: 0.6763830780982971,
          y: 0.5912531614303589,
        },
      ],
    },
  },
  {
    id: "351",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.6055508852005005,
        },
        {
          x: 0.7132658958435059,
          y: 0.6055508852005005,
        },
        {
          x: 0.7132658958435059,
          y: 0.611017644405365,
        },
        {
          x: 0.6769779920578003,
          y: 0.611017644405365,
        },
      ],
    },
  },
  {
    id: "352",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.6349874138832092,
        },
        {
          x: 0.7132658958435059,
          y: 0.6349874138832092,
        },
        {
          x: 0.7132658958435059,
          y: 0.6404541730880737,
        },
        {
          x: 0.6763830780982971,
          y: 0.6404541730880737,
        },
      ],
    },
  },
  {
    id: "353",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.654331386089325,
        },
        {
          x: 0.713860809803009,
          y: 0.654331386089325,
        },
        {
          x: 0.713860809803009,
          y: 0.6602186560630798,
        },
        {
          x: 0.6763830780982971,
          y: 0.6602186560630798,
        },
      ],
    },
  },
  {
    id: "354",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.674095869064331,
        },
        {
          x: 0.713860809803009,
          y: 0.674095869064331,
        },
        {
          x: 0.713860809803009,
          y: 0.6799831986427307,
        },
        {
          x: 0.6763830780982971,
          y: 0.6799831986427307,
        },
      ],
    },
  },
  {
    id: "355",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.6938604116439819,
        },
        {
          x: 0.7132658958435059,
          y: 0.6938604116439819,
        },
        {
          x: 0.7132658958435059,
          y: 0.6997476816177368,
        },
        {
          x: 0.6769779920578003,
          y: 0.6997476816177368,
        },
      ],
    },
  },
  {
    id: "356",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.7232968807220459,
        },
        {
          x: 0.7132658958435059,
          y: 0.7232968807220459,
        },
        {
          x: 0.7132658958435059,
          y: 0.7287636399269104,
        },
        {
          x: 0.6769779920578003,
          y: 0.7287636399269104,
        },
      ],
    },
  },
  {
    id: "357",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999998927116394,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.7430614233016968,
        },
        {
          x: 0.7132658958435059,
          y: 0.7430614233016968,
        },
        {
          x: 0.7132658958435059,
          y: 0.7485281825065613,
        },
        {
          x: 0.6763830780982971,
          y: 0.7485281825065613,
        },
      ],
    },
  },
  {
    id: "358",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.7628259062767029,
        },
        {
          x: 0.7132658958435059,
          y: 0.7628259062767029,
        },
        {
          x: 0.7132658958435059,
          y: 0.7682926654815674,
        },
        {
          x: 0.6763830780982971,
          y: 0.7682926654815674,
        },
      ],
    },
  },
  {
    id: "359",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.7830109596252441,
        },
        {
          x: 0.7132658958435059,
          y: 0.7830109596252441,
        },
        {
          x: 0.7132658958435059,
          y: 0.7884777188301086,
        },
        {
          x: 0.6763830780982971,
          y: 0.7884777188301086,
        },
      ],
    },
  },
  {
    id: "360",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.8027754426002502,
        },
        {
          x: 0.713860809803009,
          y: 0.8027754426002502,
        },
        {
          x: 0.713860809803009,
          y: 0.8082422018051147,
        },
        {
          x: 0.6763830780982971,
          y: 0.8082422018051147,
        },
      ],
    },
  },
  {
    id: "361",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.822119414806366,
        },
        {
          x: 0.7132658958435059,
          y: 0.822119414806366,
        },
        {
          x: 0.7132658958435059,
          y: 0.8280067443847656,
        },
        {
          x: 0.6763830780982971,
          y: 0.8280067443847656,
        },
      ],
    },
  },
  {
    id: "362",
    key: "UNIT_PRICE",
    resultText: "635.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.8418839573860168,
        },
        {
          x: 0.7150505781173706,
          y: 0.8418839573860168,
        },
        {
          x: 0.7150505781173706,
          y: 0.8477712273597717,
        },
        {
          x: 0.6763830780982971,
          y: 0.8477712273597717,
        },
      ],
    },
  },
  {
    id: "363",
    key: "UNIT_PRICE",
    resultText: "635.00",
    confidence: 0.9999971389770508,
    hightLightZone: {
      page: "4",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.861648440361023,
        },
        {
          x: 0.7144556641578674,
          y: 0.861648440361023,
        },
        {
          x: 0.7144556641578674,
          y: 0.867956280708313,
        },
        {
          x: 0.6751933097839355,
          y: 0.867956280708313,
        },
      ],
    },
  },
  {
    id: "365",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9974376559257507,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.1719214767217636,
          y: 0.3015138804912567,
        },
        {
          x: 0.33789411187171936,
          y: 0.3015138804912567,
        },
        {
          x: 0.33789411187171936,
          y: 0.3086627423763275,
        },
        {
          x: 0.1719214767217636,
          y: 0.3086627423763275,
        },
      ],
    },
  },
  {
    id: "366",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BLOOM OPPORTUNITY",
    confidence: 0.9996350407600403,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.3751051425933838,
        },
        {
          x: 0.3575252890586853,
          y: 0.3751051425933838,
        },
        {
          x: 0.3575252890586853,
          y: 0.3818334639072418,
        },
        {
          x: 0.14336703717708588,
          y: 0.3818334639072418,
        },
      ],
    },
  },
  {
    id: "367",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BLOOM DREAM",
    confidence: 0.9999864101409912,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.39529016613960266,
        },
        {
          x: 0.3152885138988495,
          y: 0.39529016613960266,
        },
        {
          x: 0.3152885138988495,
          y: 0.4015979766845703,
        },
        {
          x: 0.14336703717708588,
          y: 0.4015979766845703,
        },
      ],
    },
  },
  {
    id: "368",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BLOOM MAGIC",
    confidence: 0.9999759197235107,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4146341383457184,
        },
        {
          x: 0.3105294406414032,
          y: 0.4146341383457184,
        },
        {
          x: 0.3105294406414032,
          y: 0.4213624894618988,
        },
        {
          x: 0.14336703717708588,
          y: 0.4213624894618988,
        },
      ],
    },
  },
  {
    id: "369",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BLOOM ALIVE",
    confidence: 0.9999929666519165,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.4343986511230469,
        },
        {
          x: 0.3051754832267761,
          y: 0.4343986511230469,
        },
        {
          x: 0.3051754832267761,
          y: 0.4411270022392273,
        },
        {
          x: 0.14336703717708588,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "370",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO BLOOM BRIGHT 3G",
    confidence: 0.9999808073043823,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.45416316390037537,
        },
        {
          x: 0.33491969108581543,
          y: 0.45416316390037537,
        },
        {
          x: 0.33491969108581543,
          y: 0.4608915150165558,
        },
        {
          x: 0.14336703717708588,
          y: 0.4608915150165558,
        },
      ],
    },
  },
  {
    id: "371",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH FERVEUR",
    confidence: 0.999977707862854,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.474768728017807,
        },
        {
          x: 0.3242117762565613,
          y: 0.474768728017807,
        },
        {
          x: 0.3242117762565613,
          y: 0.48107653856277466,
        },
        {
          x: 0.14336703717708588,
          y: 0.48107653856277466,
        },
      ],
    },
  },
  {
    id: "372",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH BEAT",
    confidence: 0.9999929666519165,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.49411270022392273,
        },
        {
          x: 0.2986317574977875,
          y: 0.49411270022392273,
        },
        {
          x: 0.2986317574977875,
          y: 0.5008410215377808,
        },
        {
          x: 0.14336703717708588,
          y: 0.5008410215377808,
        },
      ],
    },
  },
  {
    id: "373",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH PULSE",
    confidence: 0.9999947547912598,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5138772130012512,
        },
        {
          x: 0.3063652515411377,
          y: 0.5138772130012512,
        },
        {
          x: 0.3063652515411377,
          y: 0.5206055641174316,
        },
        {
          x: 0.14336703717708588,
          y: 0.5206055641174316,
        },
      ],
    },
  },
  {
    id: "374",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH ULTIME",
    confidence: 0.9999960660934448,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5336416959762573,
        },
        {
          x: 0.3105294406414032,
          y: 0.5336416959762573,
        },
        {
          x: 0.3105294406414032,
          y: 0.5403700470924377,
        },
        {
          x: 0.14336703717708588,
          y: 0.5403700470924377,
        },
      ],
    },
  },
  {
    id: "375",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH ATTITUDE",
    confidence: 0.9999898672103882,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5534062385559082,
        },
        {
          x: 0.32540154457092285,
          y: 0.5534062385559082,
        },
        {
          x: 0.32540154457092285,
          y: 0.5601345896720886,
        },
        {
          x: 0.14336703717708588,
          y: 0.5601345896720886,
        },
      ],
    },
  },
  {
    id: "376",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH EMOTION",
    confidence: 0.9999954700469971,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.5731707215309143,
        },
        {
          x: 0.32183223962783813,
          y: 0.5731707215309143,
        },
        {
          x: 0.32183223962783813,
          y: 0.5798990726470947,
        },
        {
          x: 0.14336703717708588,
          y: 0.5798990726470947,
        },
      ],
    },
  },
  {
    id: "377",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH BOHEME",
    confidence: 0.9999910593032837,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.5933557748794556,
        },
        {
          x: 0.3182629346847534,
          y: 0.5933557748794556,
        },
        {
          x: 0.3182629346847534,
          y: 0.600084125995636,
        },
        {
          x: 0.14396192133426666,
          y: 0.600084125995636,
        },
      ],
    },
  },
  {
    id: "378",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH LIVE",
    confidence: 0.9999910593032837,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6223717331886292,
        },
        {
          x: 0.2926829159259796,
          y: 0.6223717331886292,
        },
        {
          x: 0.2926829159259796,
          y: 0.6291000843048096,
        },
        {
          x: 0.14336703717708588,
          y: 0.6291000843048096,
        },
      ],
    },
  },
  {
    id: "379",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH JOUR",
    confidence: 0.9999938011169434,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.64213627576828,
        },
        {
          x: 0.29982152581214905,
          y: 0.64213627576828,
        },
        {
          x: 0.29982152581214905,
          y: 0.6492851376533508,
        },
        {
          x: 0.14396192133426666,
          y: 0.6492851376533508,
        },
      ],
    },
  },
  {
    id: "380",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH AMOUR",
    confidence: 0.9999897480010986,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6619007587432861,
        },
        {
          x: 0.31171920895576477,
          y: 0.6619007587432861,
        },
        {
          x: 0.31171920895576477,
          y: 0.6686291098594666,
        },
        {
          x: 0.14336703717708588,
          y: 0.6686291098594666,
        },
      ],
    },
  },
  {
    id: "381",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH DOMINANT",
    confidence: 0.999988317489624,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.6816652417182922,
        },
        {
          x: 0.3307555019855499,
          y: 0.6816652417182922,
        },
        {
          x: 0.3307555019855499,
          y: 0.6883935928344727,
        },
        {
          x: 0.14336703717708588,
          y: 0.6883935928344727,
        },
      ],
    },
  },
  {
    id: "382",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH EASY 3 G",
    confidence: 0.9999517202377319,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7014297842979431,
        },
        {
          x: 0.32123735547065735,
          y: 0.7014297842979431,
        },
        {
          x: 0.32123735547065735,
          y: 0.7081581354141235,
        },
        {
          x: 0.14336703717708588,
          y: 0.7081581354141235,
        },
      ],
    },
  },
  {
    id: "383",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH FREEZE 3G",
    confidence: 0.9999905824661255,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7211942672729492,
        },
        {
          x: 0.336109459400177,
          y: 0.7211942672729492,
        },
        {
          x: 0.336109459400177,
          y: 0.7279226183891296,
        },
        {
          x: 0.14336703717708588,
          y: 0.7279226183891296,
        },
      ],
    },
  },
  {
    id: "384",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH PLAY 3 G",
    confidence: 0.9999904632568359,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.750630795955658,
        },
        {
          x: 0.3200475871562958,
          y: 0.750630795955658,
        },
        {
          x: 0.3200475871562958,
          y: 0.7573591470718384,
        },
        {
          x: 0.14336703717708588,
          y: 0.7573591470718384,
        },
      ],
    },
  },
  {
    id: "385",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH VIBRANT 3 G",
    confidence: 0.9999903440475464,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.7703952789306641,
        },
        {
          x: 0.3402736485004425,
          y: 0.7703952789306641,
        },
        {
          x: 0.3402736485004425,
          y: 0.7771236300468445,
        },
        {
          x: 0.14336703717708588,
          y: 0.7771236300468445,
        },
      ],
    },
  },
  {
    id: "386",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH MOVE 3 G",
    confidence: 0.9999926090240479,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.7901598215103149,
        },
        {
          x: 0.3242117762565613,
          y: 0.7901598215103149,
        },
        {
          x: 0.3242117762565613,
          y: 0.7968881130218506,
        },
        {
          x: 0.14396192133426666,
          y: 0.7968881130218506,
        },
      ],
    },
  },
  {
    id: "387",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH LIVELY 3 G",
    confidence: 0.9999924898147583,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14396192133426666,
          y: 0.809924304485321,
        },
        {
          x: 0.32956573367118835,
          y: 0.809924304485321,
        },
        {
          x: 0.32956573367118835,
          y: 0.8166526556015015,
        },
        {
          x: 0.14396192133426666,
          y: 0.8166526556015015,
        },
      ],
    },
  },
  {
    id: "388",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH SHAKE 3 G",
    confidence: 0.9999911785125732,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.8296887874603271,
        },
        {
          x: 0.32956573367118835,
          y: 0.8296887874603271,
        },
        {
          x: 0.32956573367118835,
          y: 0.8364171385765076,
        },
        {
          x: 0.14336703717708588,
          y: 0.8364171385765076,
        },
      ],
    },
  },
  {
    id: "389",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH FLAME",
    confidence: 0.9999927282333374,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.8591253161430359,
        },
        {
          x: 0.3069601356983185,
          y: 0.8591253161430359,
        },
        {
          x: 0.3069601356983185,
          y: 0.8654331564903259,
        },
        {
          x: 0.14336703717708588,
          y: 0.8654331564903259,
        },
      ],
    },
  },
  {
    id: "390",
    key: "DESCRIPTION",
    resultText: "ROUGE COCO FLASH DESTINATION",
    confidence: 0.999988317489624,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.8793103694915771,
        },
        {
          x: 0.34681737422943115,
          y: 0.8793103694915771,
        },
        {
          x: 0.34681737422943115,
          y: 0.8856181502342224,
        },
        {
          x: 0.14336703717708588,
          y: 0.8856181502342224,
        },
      ],
    },
  },
  {
    id: "391",
    key: "INVOICE_NO",
    resultText: "SIN0064394",
    confidence: 0.9999940395355225,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.80904221534729,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.1980656087398529,
        },
        {
          x: 0.80904221534729,
          y: 0.1980656087398529,
        },
      ],
    },
  },
  {
    id: "392",
    key: "ITEM",
    resultText: "0172112",
    confidence: 0.999981164932251,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.37594616413116455,
        },
        {
          x: 0.0945865586400032,
          y: 0.37594616413116455,
        },
        {
          x: 0.0945865586400032,
          y: 0.38099244236946106,
        },
        {
          x: 0.04580606892704964,
          y: 0.38099244236946106,
        },
      ],
    },
  },
  {
    id: "393",
    key: "ITEM",
    resultText: "0172116",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.39529016613960266,
        },
        {
          x: 0.0945865586400032,
          y: 0.39529016613960266,
        },
        {
          x: 0.0945865586400032,
          y: 0.40117746591567993,
        },
        {
          x: 0.04521118476986885,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "394",
    key: "ITEM",
    resultText: "0172128",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.41505467891693115,
        },
        {
          x: 0.09399167448282242,
          y: 0.41505467891693115,
        },
        {
          x: 0.09399167448282242,
          y: 0.4209419786930084,
        },
        {
          x: 0.04521118476986885,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "395",
    key: "ITEM",
    resultText: "0172140",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.43481916189193726,
        },
        {
          x: 0.0945865586400032,
          y: 0.43481916189193726,
        },
        {
          x: 0.0945865586400032,
          y: 0.4407064616680145,
        },
        {
          x: 0.04521118476986885,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "396",
    key: "ITEM",
    resultText: "0172158",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.45458367466926575,
        },
        {
          x: 0.0945865586400032,
          y: 0.45458367466926575,
        },
        {
          x: 0.0945865586400032,
          y: 0.460470974445343,
        },
        {
          x: 0.04521118476986885,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "397",
    key: "ITEM",
    resultText: "0174058",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.474768728017807,
        },
        {
          x: 0.0945865586400032,
          y: 0.474768728017807,
        },
        {
          x: 0.0945865586400032,
          y: 0.4806560277938843,
        },
        {
          x: 0.04521118476986885,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "398",
    key: "ITEM",
    resultText: "0174060",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.4945332109928131,
        },
        {
          x: 0.0945865586400032,
          y: 0.4945332109928131,
        },
        {
          x: 0.0945865586400032,
          y: 0.5004205107688904,
        },
        {
          x: 0.04521118476986885,
          y: 0.5004205107688904,
        },
      ],
    },
  },
  {
    id: "399",
    key: "ITEM",
    resultText: "0174066",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.5142977237701416,
        },
        {
          x: 0.0945865586400032,
          y: 0.5142977237701416,
        },
        {
          x: 0.0945865586400032,
          y: 0.5201850533485413,
        },
        {
          x: 0.04521118476986885,
          y: 0.5201850533485413,
        },
      ],
    },
  },
  {
    id: "400",
    key: "ITEM",
    resultText: "0174068",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.5340622663497925,
        },
        {
          x: 0.0945865586400032,
          y: 0.5340622663497925,
        },
        {
          x: 0.0945865586400032,
          y: 0.5399495363235474,
        },
        {
          x: 0.04521118476986885,
          y: 0.5399495363235474,
        },
      ],
    },
  },
  {
    id: "401",
    key: "ITEM",
    resultText: "0174070",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5538267493247986,
        },
        {
          x: 0.0945865586400032,
          y: 0.5538267493247986,
        },
        {
          x: 0.0945865586400032,
          y: 0.5597140192985535,
        },
        {
          x: 0.04580606892704964,
          y: 0.5597140192985535,
        },
      ],
    },
  },
  {
    id: "402",
    key: "ITEM",
    resultText: "0174078",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5735912322998047,
        },
        {
          x: 0.0945865586400032,
          y: 0.5735912322998047,
        },
        {
          x: 0.0945865586400032,
          y: 0.5798990726470947,
        },
        {
          x: 0.04580606892704964,
          y: 0.5798990726470947,
        },
      ],
    },
  },
  {
    id: "403",
    key: "ITEM",
    resultText: "0174080",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.593776285648346,
        },
        {
          x: 0.0945865586400032,
          y: 0.593776285648346,
        },
        {
          x: 0.0945865586400032,
          y: 0.5996635556221008,
        },
        {
          x: 0.04580606892704964,
          y: 0.5996635556221008,
        },
      ],
    },
  },
  {
    id: "404",
    key: "ITEM",
    resultText: "0174082",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.6227922439575195,
        },
        {
          x: 0.09518144279718399,
          y: 0.6227922439575195,
        },
        {
          x: 0.09518144279718399,
          y: 0.6286795735359192,
        },
        {
          x: 0.04521118476986885,
          y: 0.6286795735359192,
        },
      ],
    },
  },
  {
    id: "405",
    key: "ITEM",
    resultText: "0174090",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.64213627576828,
        },
        {
          x: 0.0945865586400032,
          y: 0.64213627576828,
        },
        {
          x: 0.0945865586400032,
          y: 0.6484440565109253,
        },
        {
          x: 0.04521118476986885,
          y: 0.6484440565109253,
        },
      ],
    },
  },
  {
    id: "406",
    key: "ITEM",
    resultText: "0174092",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.6623212695121765,
        },
        {
          x: 0.0945865586400032,
          y: 0.6623212695121765,
        },
        {
          x: 0.0945865586400032,
          y: 0.6686291098594666,
        },
        {
          x: 0.04580606892704964,
          y: 0.6686291098594666,
        },
      ],
    },
  },
  {
    id: "407",
    key: "ITEM",
    resultText: "0174106",
    confidence: 0.9999964237213135,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.6820858120918274,
        },
        {
          x: 0.0945865586400032,
          y: 0.6820858120918274,
        },
        {
          x: 0.0945865586400032,
          y: 0.6879730820655823,
        },
        {
          x: 0.04521118476986885,
          y: 0.6879730820655823,
        },
      ],
    },
  },
  {
    id: "408",
    key: "ITEM",
    resultText: "0174116",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7022708058357239,
        },
        {
          x: 0.0945865586400032,
          y: 0.7022708058357239,
        },
        {
          x: 0.0945865586400032,
          y: 0.7077375650405884,
        },
        {
          x: 0.04521118476986885,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "409",
    key: "ITEM",
    resultText: "0174118",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7220353484153748,
        },
        {
          x: 0.0945865586400032,
          y: 0.7220353484153748,
        },
        {
          x: 0.0945865586400032,
          y: 0.7275021076202393,
        },
        {
          x: 0.04521118476986885,
          y: 0.7275021076202393,
        },
      ],
    },
  },
  {
    id: "410",
    key: "ITEM",
    resultText: "0174122",
    confidence: 0.9999873638153076,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7510513067245483,
        },
        {
          x: 0.0945865586400032,
          y: 0.7510513067245483,
        },
        {
          x: 0.0945865586400032,
          y: 0.7569385766983032,
        },
        {
          x: 0.04521118476986885,
          y: 0.7569385766983032,
        },
      ],
    },
  },
  {
    id: "411",
    key: "ITEM",
    resultText: "0174124",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7712363600730896,
        },
        {
          x: 0.09280190616846085,
          y: 0.7712363600730896,
        },
        {
          x: 0.09280190616846085,
          y: 0.7767031192779541,
        },
        {
          x: 0.04521118476986885,
          y: 0.7767031192779541,
        },
      ],
    },
  },
  {
    id: "412",
    key: "ITEM",
    resultText: "0174144",
    confidence: 0.9999961853027344,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.7905803322792053,
        },
        {
          x: 0.09339679032564163,
          y: 0.7905803322792053,
        },
        {
          x: 0.09339679032564163,
          y: 0.7968881130218506,
        },
        {
          x: 0.04521118476986885,
          y: 0.7968881130218506,
        },
      ],
    },
  },
  {
    id: "413",
    key: "ITEM",
    resultText: "0174148",
    confidence: 0.9999970197677612,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8107653260231018,
        },
        {
          x: 0.09339679032564163,
          y: 0.8107653260231018,
        },
        {
          x: 0.09339679032564163,
          y: 0.8162321448326111,
        },
        {
          x: 0.04521118476986885,
          y: 0.8162321448326111,
        },
      ],
    },
  },
  {
    id: "414",
    key: "ITEM",
    resultText: "0174152",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8301093578338623,
        },
        {
          x: 0.0945865586400032,
          y: 0.8301093578338623,
        },
        {
          x: 0.0945865586400032,
          y: 0.8359966278076172,
        },
        {
          x: 0.04521118476986885,
          y: 0.8359966278076172,
        },
      ],
    },
  },
  {
    id: "415",
    key: "ITEM",
    resultText: "0174164",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8591253161430359,
        },
        {
          x: 0.09280190616846085,
          y: 0.8591253161430359,
        },
        {
          x: 0.09280190616846085,
          y: 0.8650125861167908,
        },
        {
          x: 0.04521118476986885,
          y: 0.8650125861167908,
        },
      ],
    },
  },
  {
    id: "416",
    key: "ITEM",
    resultText: "0174174",
    confidence: 0.9999967813491821,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.8797308802604675,
        },
        {
          x: 0.09339679032564163,
          y: 0.8797308802604675,
        },
        {
          x: 0.09339679032564163,
          y: 0.885197639465332,
        },
        {
          x: 0.04521118476986885,
          y: 0.885197639465332,
        },
      ],
    },
  },
  {
    id: "417",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9998748302459717,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.37552565336227417,
        },
        {
          x: 0.8947055339813232,
          y: 0.37552565336227417,
        },
        {
          x: 0.8947055339813232,
          y: 0.38141295313835144,
        },
        {
          x: 0.8792385458946228,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "418",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999970197677612,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.39571067690849304,
        },
        {
          x: 0.8947055339813232,
          y: 0.39571067690849304,
        },
        {
          x: 0.8947055339813232,
          y: 0.4015979766845703,
        },
        {
          x: 0.879833459854126,
          y: 0.4015979766845703,
        },
      ],
    },
  },
  {
    id: "419",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.41547518968582153,
        },
        {
          x: 0.8947055339813232,
          y: 0.41547518968582153,
        },
        {
          x: 0.8947055339813232,
          y: 0.4209419786930084,
        },
        {
          x: 0.8786436915397644,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "420",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.43523970246315,
        },
        {
          x: 0.8947055339813232,
          y: 0.43523970246315,
        },
        {
          x: 0.8947055339813232,
          y: 0.4407064616680145,
        },
        {
          x: 0.8786436915397644,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "421",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.4550042152404785,
        },
        {
          x: 0.8953003883361816,
          y: 0.4550042152404785,
        },
        {
          x: 0.8953003883361816,
          y: 0.460470974445343,
        },
        {
          x: 0.8792385458946228,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "422",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.4751892387866974,
        },
        {
          x: 0.8947055339813232,
          y: 0.4751892387866974,
        },
        {
          x: 0.8947055339813232,
          y: 0.4806560277938843,
        },
        {
          x: 0.8792385458946228,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "423",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.4949537515640259,
        },
        {
          x: 0.8947055339813232,
          y: 0.4949537515640259,
        },
        {
          x: 0.8947055339813232,
          y: 0.5004205107688904,
        },
        {
          x: 0.8792385458946228,
          y: 0.5004205107688904,
        },
      ],
    },
  },
  {
    id: "424",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.514718234539032,
        },
        {
          x: 0.8941106200218201,
          y: 0.514718234539032,
        },
        {
          x: 0.8941106200218201,
          y: 0.5201850533485413,
        },
        {
          x: 0.879833459854126,
          y: 0.5201850533485413,
        },
      ],
    },
  },
  {
    id: "425",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999977350234985,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.5340622663497925,
        },
        {
          x: 0.8947055339813232,
          y: 0.5340622663497925,
        },
        {
          x: 0.8947055339813232,
          y: 0.5399495363235474,
        },
        {
          x: 0.8786436915397644,
          y: 0.5399495363235474,
        },
      ],
    },
  },
  {
    id: "426",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.554247260093689,
        },
        {
          x: 0.8947055339813232,
          y: 0.554247260093689,
        },
        {
          x: 0.8947055339813232,
          y: 0.5597140192985535,
        },
        {
          x: 0.8786436915397644,
          y: 0.5597140192985535,
        },
      ],
    },
  },
  {
    id: "427",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999990463256836,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.5740118026733398,
        },
        {
          x: 0.8953003883361816,
          y: 0.5740118026733398,
        },
        {
          x: 0.8953003883361816,
          y: 0.5798990726470947,
        },
        {
          x: 0.879833459854126,
          y: 0.5798990726470947,
        },
      ],
    },
  },
  {
    id: "428",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.5941967964172363,
        },
        {
          x: 0.8947055339813232,
          y: 0.5941967964172363,
        },
        {
          x: 0.8947055339813232,
          y: 0.5996635556221008,
        },
        {
          x: 0.8792385458946228,
          y: 0.5996635556221008,
        },
      ],
    },
  },
  {
    id: "429",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999942779541016,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.6232127547264099,
        },
        {
          x: 0.8947055339813232,
          y: 0.6232127547264099,
        },
        {
          x: 0.8947055339813232,
          y: 0.6286795735359192,
        },
        {
          x: 0.8792385458946228,
          y: 0.6286795735359192,
        },
      ],
    },
  },
  {
    id: "430",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.6429772973060608,
        },
        {
          x: 0.8947055339813232,
          y: 0.6429772973060608,
        },
        {
          x: 0.8947055339813232,
          y: 0.6484440565109253,
        },
        {
          x: 0.8792385458946228,
          y: 0.6484440565109253,
        },
      ],
    },
  },
  {
    id: "431",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6623212695121765,
        },
        {
          x: 0.8941106200218201,
          y: 0.6623212695121765,
        },
        {
          x: 0.8941106200218201,
          y: 0.6682085990905762,
        },
        {
          x: 0.879833459854126,
          y: 0.6682085990905762,
        },
      ],
    },
  },
  {
    id: "432",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.6820858120918274,
        },
        {
          x: 0.8947055339813232,
          y: 0.6820858120918274,
        },
        {
          x: 0.8947055339813232,
          y: 0.6879730820655823,
        },
        {
          x: 0.8786436915397644,
          y: 0.6879730820655823,
        },
      ],
    },
  },
  {
    id: "433",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.7022708058357239,
        },
        {
          x: 0.8947055339813232,
          y: 0.7022708058357239,
        },
        {
          x: 0.8947055339813232,
          y: 0.7077375650405884,
        },
        {
          x: 0.8786436915397644,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "434",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.7220353484153748,
        },
        {
          x: 0.8947055339813232,
          y: 0.7220353484153748,
        },
        {
          x: 0.8947055339813232,
          y: 0.7275021076202393,
        },
        {
          x: 0.8792385458946228,
          y: 0.7275021076202393,
        },
      ],
    },
  },
  {
    id: "435",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.7514718174934387,
        },
        {
          x: 0.8947055339813232,
          y: 0.7514718174934387,
        },
        {
          x: 0.8947055339813232,
          y: 0.7569385766983032,
        },
        {
          x: 0.8786436915397644,
          y: 0.7569385766983032,
        },
      ],
    },
  },
  {
    id: "436",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999985694885254,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.7712363600730896,
        },
        {
          x: 0.8953003883361816,
          y: 0.7712363600730896,
        },
        {
          x: 0.8953003883361816,
          y: 0.7767031192779541,
        },
        {
          x: 0.8792385458946228,
          y: 0.7767031192779541,
        },
      ],
    },
  },
  {
    id: "437",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.7910008430480957,
        },
        {
          x: 0.8947055339813232,
          y: 0.7910008430480957,
        },
        {
          x: 0.8947055339813232,
          y: 0.7964676022529602,
        },
        {
          x: 0.8792385458946228,
          y: 0.7964676022529602,
        },
      ],
    },
  },
  {
    id: "438",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.8103448152542114,
        },
        {
          x: 0.8941106200218201,
          y: 0.8103448152542114,
        },
        {
          x: 0.8941106200218201,
          y: 0.8162321448326111,
        },
        {
          x: 0.879833459854126,
          y: 0.8162321448326111,
        },
      ],
    },
  },
  {
    id: "439",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.8301093578338623,
        },
        {
          x: 0.8947055339813232,
          y: 0.8301093578338623,
        },
        {
          x: 0.8947055339813232,
          y: 0.8359966278076172,
        },
        {
          x: 0.8786436915397644,
          y: 0.8359966278076172,
        },
      ],
    },
  },
  {
    id: "440",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999988079071045,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.8595458269119263,
        },
        {
          x: 0.8947055339813232,
          y: 0.8595458269119263,
        },
        {
          x: 0.8947055339813232,
          y: 0.8650125861167908,
        },
        {
          x: 0.8786436915397644,
          y: 0.8650125861167908,
        },
      ],
    },
  },
  {
    id: "441",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9999977350234985,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.8797308802604675,
        },
        {
          x: 0.8947055339813232,
          y: 0.8797308802604675,
        },
        {
          x: 0.8947055339813232,
          y: 0.885197639465332,
        },
        {
          x: 0.8786436915397644,
          y: 0.885197639465332,
        },
      ],
    },
  },
  {
    id: "442",
    key: "QTY",
    resultText: "180",
    confidence: 0.9999911785125732,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5645449161529541,
          y: 0.37594616413116455,
        },
        {
          x: 0.5859607458114624,
          y: 0.37594616413116455,
        },
        {
          x: 0.5859607458114624,
          y: 0.38141295313835144,
        },
        {
          x: 0.5645449161529541,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "443",
    key: "QTY",
    resultText: "390",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5645449161529541,
          y: 0.39571067690849304,
        },
        {
          x: 0.5865556001663208,
          y: 0.39571067690849304,
        },
        {
          x: 0.5865556001663208,
          y: 0.40117746591567993,
        },
        {
          x: 0.5645449161529541,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "444",
    key: "QTY",
    resultText: "24",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.41547518968582153,
        },
        {
          x: 0.5865556001663208,
          y: 0.41547518968582153,
        },
        {
          x: 0.5865556001663208,
          y: 0.4209419786930084,
        },
        {
          x: 0.5698988437652588,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "445",
    key: "QTY",
    resultText: "59",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5716835260391235,
          y: 0.43481916189193726,
        },
        {
          x: 0.5865556001663208,
          y: 0.43481916189193726,
        },
        {
          x: 0.5865556001663208,
          y: 0.4411270022392273,
        },
        {
          x: 0.5716835260391235,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "446",
    key: "QTY",
    resultText: "80",
    confidence: 0.9999994039535522,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.4550042152404785,
        },
        {
          x: 0.5859607458114624,
          y: 0.4550042152404785,
        },
        {
          x: 0.5859607458114624,
          y: 0.460470974445343,
        },
        {
          x: 0.5698988437652588,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "447",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.4751892387866974,
        },
        {
          x: 0.5859607458114624,
          y: 0.4751892387866974,
        },
        {
          x: 0.5859607458114624,
          y: 0.4802354872226715,
        },
        {
          x: 0.570493757724762,
          y: 0.4802354872226715,
        },
      ],
    },
  },
  {
    id: "448",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999992847442627,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.4949537515640259,
        },
        {
          x: 0.5859607458114624,
          y: 0.4949537515640259,
        },
        {
          x: 0.5859607458114624,
          y: 0.5004205107688904,
        },
        {
          x: 0.570493757724762,
          y: 0.5004205107688904,
        },
      ],
    },
  },
  {
    id: "449",
    key: "QTY",
    resultText: "75",
    confidence: 0.9999991655349731,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.514718234539032,
        },
        {
          x: 0.5865556001663208,
          y: 0.514718234539032,
        },
        {
          x: 0.5865556001663208,
          y: 0.5206055641174316,
        },
        {
          x: 0.5710886120796204,
          y: 0.5206055641174316,
        },
      ],
    },
  },
  {
    id: "450",
    key: "QTY",
    resultText: "60",
    confidence: 0.9999978542327881,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.5344827771186829,
        },
        {
          x: 0.5859607458114624,
          y: 0.5344827771186829,
        },
        {
          x: 0.5859607458114624,
          y: 0.5399495363235474,
        },
        {
          x: 0.570493757724762,
          y: 0.5399495363235474,
        },
      ],
    },
  },
  {
    id: "451",
    key: "QTY",
    resultText: "45",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.554247260093689,
        },
        {
          x: 0.5865556001663208,
          y: 0.554247260093689,
        },
        {
          x: 0.5865556001663208,
          y: 0.5597140192985535,
        },
        {
          x: 0.5698988437652588,
          y: 0.5597140192985535,
        },
      ],
    },
  },
  {
    id: "452",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999977350234985,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.5744323134422302,
        },
        {
          x: 0.5859607458114624,
          y: 0.5744323134422302,
        },
        {
          x: 0.5859607458114624,
          y: 0.5794785618782043,
        },
        {
          x: 0.5710886120796204,
          y: 0.5794785618782043,
        },
      ],
    },
  },
  {
    id: "453",
    key: "QTY",
    resultText: "270",
    confidence: 0.9999972581863403,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.5941967964172363,
        },
        {
          x: 0.5865556001663208,
          y: 0.5941967964172363,
        },
        {
          x: 0.5865556001663208,
          y: 0.5996635556221008,
        },
        {
          x: 0.5639500021934509,
          y: 0.5996635556221008,
        },
      ],
    },
  },
  {
    id: "454",
    key: "QTY",
    resultText: "10",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.6232127547264099,
        },
        {
          x: 0.5859607458114624,
          y: 0.6232127547264099,
        },
        {
          x: 0.5859607458114624,
          y: 0.6286795735359192,
        },
        {
          x: 0.570493757724762,
          y: 0.6286795735359192,
        },
      ],
    },
  },
  {
    id: "455",
    key: "QTY",
    resultText: "410",
    confidence: 0.9999973773956299,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5633551478385925,
          y: 0.6425567865371704,
        },
        {
          x: 0.5865556001663208,
          y: 0.6425567865371704,
        },
        {
          x: 0.5865556001663208,
          y: 0.6488645672798157,
        },
        {
          x: 0.5633551478385925,
          y: 0.6488645672798157,
        },
      ],
    },
  },
  {
    id: "456",
    key: "QTY",
    resultText: "120",
    confidence: 0.9999971389770508,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.6627417802810669,
        },
        {
          x: 0.5865556001663208,
          y: 0.6627417802810669,
        },
        {
          x: 0.5865556001663208,
          y: 0.6682085990905762,
        },
        {
          x: 0.5639500021934509,
          y: 0.6682085990905762,
        },
      ],
    },
  },
  {
    id: "457",
    key: "QTY",
    resultText: "40",
    confidence: 0.9999966621398926,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.6825063228607178,
        },
        {
          x: 0.5865556001663208,
          y: 0.6825063228607178,
        },
        {
          x: 0.5865556001663208,
          y: 0.6879730820655823,
        },
        {
          x: 0.5698988437652588,
          y: 0.6879730820655823,
        },
      ],
    },
  },
  {
    id: "458",
    key: "QTY",
    resultText: "20",
    confidence: 0.9999986886978149,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.7022708058357239,
        },
        {
          x: 0.5865556001663208,
          y: 0.7022708058357239,
        },
        {
          x: 0.5865556001663208,
          y: 0.7077375650405884,
        },
        {
          x: 0.5698988437652588,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "459",
    key: "QTY",
    resultText: "240",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5645449161529541,
          y: 0.7224558591842651,
        },
        {
          x: 0.5859607458114624,
          y: 0.7224558591842651,
        },
        {
          x: 0.5859607458114624,
          y: 0.7275021076202393,
        },
        {
          x: 0.5645449161529541,
          y: 0.7275021076202393,
        },
      ],
    },
  },
  {
    id: "460",
    key: "QTY",
    resultText: "30",
    confidence: 0.9999972581863403,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.7514718174934387,
        },
        {
          x: 0.5859607458114624,
          y: 0.7514718174934387,
        },
        {
          x: 0.5859607458114624,
          y: 0.7569385766983032,
        },
        {
          x: 0.570493757724762,
          y: 0.7569385766983032,
        },
      ],
    },
  },
  {
    id: "461",
    key: "QTY",
    resultText: "90",
    confidence: 0.9999966621398926,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.7708157896995544,
        },
        {
          x: 0.5865556001663208,
          y: 0.7708157896995544,
        },
        {
          x: 0.5865556001663208,
          y: 0.7767031192779541,
        },
        {
          x: 0.5698988437652588,
          y: 0.7767031192779541,
        },
      ],
    },
  },
  {
    id: "462",
    key: "QTY",
    resultText: "390",
    confidence: 0.9999966621398926,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.7905803322792053,
        },
        {
          x: 0.5865556001663208,
          y: 0.7905803322792053,
        },
        {
          x: 0.5865556001663208,
          y: 0.7964676022529602,
        },
        {
          x: 0.5639500021934509,
          y: 0.7964676022529602,
        },
      ],
    },
  },
  {
    id: "463",
    key: "QTY",
    resultText: "10",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.8107653260231018,
        },
        {
          x: 0.5859607458114624,
          y: 0.8107653260231018,
        },
        {
          x: 0.5859607458114624,
          y: 0.8162321448326111,
        },
        {
          x: 0.5710886120796204,
          y: 0.8162321448326111,
        },
      ],
    },
  },
  {
    id: "464",
    key: "QTY",
    resultText: "450",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5633551478385925,
          y: 0.8305298686027527,
        },
        {
          x: 0.5865556001663208,
          y: 0.8305298686027527,
        },
        {
          x: 0.5865556001663208,
          y: 0.8359966278076172,
        },
        {
          x: 0.5633551478385925,
          y: 0.8359966278076172,
        },
      ],
    },
  },
  {
    id: "465",
    key: "QTY",
    resultText: "18",
    confidence: 0.9999967813491821,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.8591253161430359,
        },
        {
          x: 0.5865556001663208,
          y: 0.8591253161430359,
        },
        {
          x: 0.5865556001663208,
          y: 0.8650125861167908,
        },
        {
          x: 0.5710886120796204,
          y: 0.8650125861167908,
        },
      ],
    },
  },
  {
    id: "466",
    key: "QTY",
    resultText: "90",
    confidence: 0.999996542930603,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.8793103694915771,
        },
        {
          x: 0.5865556001663208,
          y: 0.8793103694915771,
        },
        {
          x: 0.5865556001663208,
          y: 0.885197639465332,
        },
        {
          x: 0.5710886120796204,
          y: 0.885197639465332,
        },
      ],
    },
  },
  {
    id: "467",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999926090240479,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.37552565336227417,
        },
        {
          x: 0.7132658958435059,
          y: 0.37552565336227417,
        },
        {
          x: 0.7132658958435059,
          y: 0.38141295313835144,
        },
        {
          x: 0.6769779920578003,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "468",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.39571067690849304,
        },
        {
          x: 0.713860809803009,
          y: 0.39571067690849304,
        },
        {
          x: 0.713860809803009,
          y: 0.40117746591567993,
        },
        {
          x: 0.6763830780982971,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "469",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999978542327881,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.41505467891693115,
        },
        {
          x: 0.7132658958435059,
          y: 0.41505467891693115,
        },
        {
          x: 0.7132658958435059,
          y: 0.4209419786930084,
        },
        {
          x: 0.6763830780982971,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "470",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.43481916189193726,
        },
        {
          x: 0.7132658958435059,
          y: 0.43481916189193726,
        },
        {
          x: 0.7132658958435059,
          y: 0.4407064616680145,
        },
        {
          x: 0.6769779920578003,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "471",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.45458367466926575,
        },
        {
          x: 0.713860809803009,
          y: 0.45458367466926575,
        },
        {
          x: 0.713860809803009,
          y: 0.460470974445343,
        },
        {
          x: 0.6763830780982971,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "472",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999979734420776,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.4751892387866974,
        },
        {
          x: 0.713860809803009,
          y: 0.4751892387866974,
        },
        {
          x: 0.713860809803009,
          y: 0.4806560277938843,
        },
        {
          x: 0.6763830780982971,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "473",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.4949537515640259,
        },
        {
          x: 0.7144556641578674,
          y: 0.4949537515640259,
        },
        {
          x: 0.7144556641578674,
          y: 0.5004205107688904,
        },
        {
          x: 0.6763830780982971,
          y: 0.5004205107688904,
        },
      ],
    },
  },
  {
    id: "474",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999980926513672,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.514718234539032,
        },
        {
          x: 0.7132658958435059,
          y: 0.514718234539032,
        },
        {
          x: 0.7132658958435059,
          y: 0.5201850533485413,
        },
        {
          x: 0.6769779920578003,
          y: 0.5201850533485413,
        },
      ],
    },
  },
  {
    id: "475",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5344827771186829,
        },
        {
          x: 0.7132658958435059,
          y: 0.5344827771186829,
        },
        {
          x: 0.7132658958435059,
          y: 0.5399495363235474,
        },
        {
          x: 0.6763830780982971,
          y: 0.5399495363235474,
        },
      ],
    },
  },
  {
    id: "476",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999984502792358,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.554247260093689,
        },
        {
          x: 0.7132658958435059,
          y: 0.554247260093689,
        },
        {
          x: 0.7132658958435059,
          y: 0.5597140192985535,
        },
        {
          x: 0.6763830780982971,
          y: 0.5597140192985535,
        },
      ],
    },
  },
  {
    id: "477",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5740118026733398,
        },
        {
          x: 0.713860809803009,
          y: 0.5740118026733398,
        },
        {
          x: 0.713860809803009,
          y: 0.5794785618782043,
        },
        {
          x: 0.6763830780982971,
          y: 0.5794785618782043,
        },
      ],
    },
  },
  {
    id: "478",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999982118606567,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.5941967964172363,
        },
        {
          x: 0.713860809803009,
          y: 0.5941967964172363,
        },
        {
          x: 0.713860809803009,
          y: 0.5996635556221008,
        },
        {
          x: 0.6763830780982971,
          y: 0.5996635556221008,
        },
      ],
    },
  },
  {
    id: "479",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999970197677612,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.6232127547264099,
        },
        {
          x: 0.713860809803009,
          y: 0.6232127547264099,
        },
        {
          x: 0.713860809803009,
          y: 0.6286795735359192,
        },
        {
          x: 0.6763830780982971,
          y: 0.6286795735359192,
        },
      ],
    },
  },
  {
    id: "480",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999977350234985,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.6429772973060608,
        },
        {
          x: 0.7132658958435059,
          y: 0.6429772973060608,
        },
        {
          x: 0.7132658958435059,
          y: 0.6484440565109253,
        },
        {
          x: 0.6769779920578003,
          y: 0.6484440565109253,
        },
      ],
    },
  },
  {
    id: "481",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999983310699463,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.6627417802810669,
        },
        {
          x: 0.713860809803009,
          y: 0.6627417802810669,
        },
        {
          x: 0.713860809803009,
          y: 0.6682085990905762,
        },
        {
          x: 0.6763830780982971,
          y: 0.6682085990905762,
        },
      ],
    },
  },
  {
    id: "482",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999974966049194,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.6825063228607178,
        },
        {
          x: 0.7132658958435059,
          y: 0.6825063228607178,
        },
        {
          x: 0.7132658958435059,
          y: 0.6879730820655823,
        },
        {
          x: 0.6763830780982971,
          y: 0.6879730820655823,
        },
      ],
    },
  },
  {
    id: "483",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.7022708058357239,
        },
        {
          x: 0.7132658958435059,
          y: 0.7022708058357239,
        },
        {
          x: 0.7132658958435059,
          y: 0.7077375650405884,
        },
        {
          x: 0.6763830780982971,
          y: 0.7077375650405884,
        },
      ],
    },
  },
  {
    id: "484",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999971389770508,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.7220353484153748,
        },
        {
          x: 0.713860809803009,
          y: 0.7220353484153748,
        },
        {
          x: 0.713860809803009,
          y: 0.7275021076202393,
        },
        {
          x: 0.6763830780982971,
          y: 0.7275021076202393,
        },
      ],
    },
  },
  {
    id: "485",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999977350234985,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.7510513067245483,
        },
        {
          x: 0.7132658958435059,
          y: 0.7510513067245483,
        },
        {
          x: 0.7132658958435059,
          y: 0.7569385766983032,
        },
        {
          x: 0.6769779920578003,
          y: 0.7569385766983032,
        },
      ],
    },
  },
  {
    id: "486",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999972581863403,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.7712363600730896,
        },
        {
          x: 0.713860809803009,
          y: 0.7712363600730896,
        },
        {
          x: 0.713860809803009,
          y: 0.7767031192779541,
        },
        {
          x: 0.6763830780982971,
          y: 0.7767031192779541,
        },
      ],
    },
  },
  {
    id: "487",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999997615814209,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.7910008430480957,
        },
        {
          x: 0.7132658958435059,
          y: 0.7910008430480957,
        },
        {
          x: 0.7132658958435059,
          y: 0.7964676022529602,
        },
        {
          x: 0.6769779920578003,
          y: 0.7964676022529602,
        },
      ],
    },
  },
  {
    id: "488",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999966621398926,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.8107653260231018,
        },
        {
          x: 0.7132658958435059,
          y: 0.8107653260231018,
        },
        {
          x: 0.7132658958435059,
          y: 0.8162321448326111,
        },
        {
          x: 0.6769779920578003,
          y: 0.8162321448326111,
        },
      ],
    },
  },
  {
    id: "489",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999959468841553,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.8305298686027527,
        },
        {
          x: 0.7132658958435059,
          y: 0.8305298686027527,
        },
        {
          x: 0.7132658958435059,
          y: 0.8359966278076172,
        },
        {
          x: 0.6763830780982971,
          y: 0.8359966278076172,
        },
      ],
    },
  },
  {
    id: "490",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.9999960660934448,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.8591253161430359,
        },
        {
          x: 0.7132658958435059,
          y: 0.8591253161430359,
        },
        {
          x: 0.7132658958435059,
          y: 0.8650125861167908,
        },
        {
          x: 0.6763830780982971,
          y: 0.8650125861167908,
        },
      ],
    },
  },
  {
    id: "491",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 0.999994158744812,
    hightLightZone: {
      page: "5",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.8793103694915771,
        },
        {
          x: 0.7132658958435059,
          y: 0.8793103694915771,
        },
        {
          x: 0.7132658958435059,
          y: 0.885197639465332,
        },
        {
          x: 0.6763830780982971,
          y: 0.885197639465332,
        },
      ],
    },
  },
  {
    id: "495",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9999665021896362,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1725163608789444,
          y: 0.3015138804912567,
        },
        {
          x: 0.33789411187171936,
          y: 0.3015138804912567,
        },
        {
          x: 0.33789411187171936,
          y: 0.3090832531452179,
        },
        {
          x: 0.1725163608789444,
          y: 0.3090832531452179,
        },
      ],
    },
  },
  {
    id: "496",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE STRAWBERRY RED",
    confidence: 0.9902322292327881,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1427721530199051,
          y: 0.3751051425933838,
        },
        {
          x: 0.4306960105895996,
          y: 0.3751051425933838,
        },
        {
          x: 0.4306960105895996,
          y: 0.3818334639072418,
        },
        {
          x: 0.1427721530199051,
          y: 0.3818334639072418,
        },
      ],
    },
  },
  {
    id: "497",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE SOFT CANDY",
    confidence: 0.999846339225769,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1427721530199051,
          y: 0.39529016613960266,
        },
        {
          x: 0.3973824977874756,
          y: 0.39529016613960266,
        },
        {
          x: 0.3973824977874756,
          y: 0.4015979766845703,
        },
        {
          x: 0.1427721530199051,
          y: 0.4015979766845703,
        },
      ],
    },
  },
  {
    id: "498",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE RADIANT PINK",
    confidence: 0.9999675750732422,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1427721530199051,
          y: 0.4146341383457184,
        },
        {
          x: 0.40273645520210266,
          y: 0.4146341383457184,
        },
        {
          x: 0.40273645520210266,
          y: 0.4213624894618988,
        },
        {
          x: 0.1427721530199051,
          y: 0.4213624894618988,
        },
      ],
    },
  },
  {
    id: "499",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE DARING RED",
    confidence: 0.9999806880950928,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1421772688627243,
          y: 0.4343986511230469,
        },
        {
          x: 0.3932183086872101,
          y: 0.4343986511230469,
        },
        {
          x: 0.3932183086872101,
          y: 0.4411270022392273,
        },
        {
          x: 0.1421772688627243,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "500",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE EVER RED",
    confidence: 0.9999805688858032,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1427721530199051,
          y: 0.45416316390037537,
        },
        {
          x: 0.3795360028743744,
          y: 0.45416316390037537,
        },
        {
          x: 0.3795360028743744,
          y: 0.46131202578544617,
        },
        {
          x: 0.1427721530199051,
          y: 0.46131202578544617,
        },
      ],
    },
  },
  {
    id: "501",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE ENDLESS PINK",
    confidence: 0.9998363256454468,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1427721530199051,
          y: 0.47434818744659424,
        },
        {
          x: 0.4063057601451874,
          y: 0.47434818744659424,
        },
        {
          x: 0.4063057601451874,
          y: 0.48149704933166504,
        },
        {
          x: 0.1427721530199051,
          y: 0.48149704933166504,
        },
      ],
    },
  },
  {
    id: "502",
    key: "DESCRIPTION",
    resultText: "LE ROUGE DUO ULTRA TENUE LIGHT BROWN",
    confidence: 0.9999191761016846,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1427721530199051,
          y: 0.503784716129303,
        },
        {
          x: 0.40273645520210266,
          y: 0.503784716129303,
        },
        {
          x: 0.40273645520210266,
          y: 0.5100924968719482,
        },
        {
          x: 0.1427721530199051,
          y: 0.5100924968719482,
        },
      ],
    },
  },
  {
    id: "503",
    key: "DESCRIPTION",
    resultText: "LE VERNIS BALLERINA",
    confidence: 0.9999444484710693,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1421772688627243,
          y: 0.5328006744384766,
        },
        {
          x: 0.2718620002269745,
          y: 0.5328006744384766,
        },
        {
          x: 0.2718620002269745,
          y: 0.5391085147857666,
        },
        {
          x: 0.1421772688627243,
          y: 0.5391085147857666,
        },
      ],
    },
  },
  {
    id: "504",
    key: "DESCRIPTION",
    resultText: "LE VERNIS FAUSSAIRE",
    confidence: 0.9999945163726807,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1421772688627243,
          y: 0.5525651574134827,
        },
        {
          x: 0.27364665269851685,
          y: 0.5525651574134827,
        },
        {
          x: 0.27364665269851685,
          y: 0.5588729977607727,
        },
        {
          x: 0.1421772688627243,
          y: 0.5588729977607727,
        },
      ],
    },
  },
  {
    id: "505",
    key: "DESCRIPTION",
    resultText: "LE VERNIS FABULISTE",
    confidence: 0.9999880790710449,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.14158238470554352,
          y: 0.5723296999931335,
        },
        {
          x: 0.27007734775543213,
          y: 0.5723296999931335,
        },
        {
          x: 0.27007734775543213,
          y: 0.579058051109314,
        },
        {
          x: 0.14158238470554352,
          y: 0.579058051109314,
        },
      ],
    },
  },
  {
    id: "506",
    key: "DESCRIPTION",
    resultText: "LE VERNIS IMMORTELLE",
    confidence: 0.9999808073043823,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.14158238470554352,
          y: 0.5920941829681396,
        },
        {
          x: 0.28316476941108704,
          y: 0.5920941829681396,
        },
        {
          x: 0.28316476941108704,
          y: 0.5988225340843201,
        },
        {
          x: 0.14158238470554352,
          y: 0.5988225340843201,
        },
      ],
    },
  },
  {
    id: "507",
    key: "DESCRIPTION",
    resultText: "LE VERNIS SORCIERE",
    confidence: 0.9999829530715942,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.1421772688627243,
          y: 0.6122792363166809,
        },
        {
          x: 0.267697811126709,
          y: 0.6122792363166809,
        },
        {
          x: 0.267697811126709,
          y: 0.618587076663971,
        },
        {
          x: 0.1421772688627243,
          y: 0.618587076663971,
        },
      ],
    },
  },
  {
    id: "508",
    key: "DESCRIPTION",
    resultText: "LE VERNIS POMPIER",
    confidence: 0.9999967813491821,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.14158238470554352,
          y: 0.632043719291687,
        },
        {
          x: 0.26055920124053955,
          y: 0.632043719291687,
        },
        {
          x: 0.26055920124053955,
          y: 0.638351559638977,
        },
        {
          x: 0.14158238470554352,
          y: 0.638351559638977,
        },
      ],
    },
  },
  {
    id: "509",
    key: "DESCRIPTION",
    resultText: "LE VERNIS ROUGE NOIR",
    confidence: 0.9999852180480957,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.14158238470554352,
          y: 0.6513876914978027,
        },
        {
          x: 0.28256988525390625,
          y: 0.6513876914978027,
        },
        {
          x: 0.28256988525390625,
          y: 0.6581160426139832,
        },
        {
          x: 0.14158238470554352,
          y: 0.6581160426139832,
        },
      ],
    },
  },
  {
    id: "510",
    key: "DESCRIPTION",
    resultText: "LE VERNIS REVEUSE 13ml",
    confidence: 0.9999223947525024,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.14158238470554352,
          y: 0.671572744846344,
        },
        {
          x: 0.29149314761161804,
          y: 0.671572744846344,
        },
        {
          x: 0.29149314761161804,
          y: 0.677880585193634,
        },
        {
          x: 0.14158238470554352,
          y: 0.677880585193634,
        },
      ],
    },
  },
  {
    id: "511",
    key: "INVOICE_NO",
    resultText: "SIN0064394",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.80904221534729,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.1980656087398529,
        },
        {
          x: 0.80904221534729,
          y: 0.1980656087398529,
        },
      ],
    },
  },
  {
    id: "512",
    key: "INVOICE_NO",
    resultText: "SIN0064394",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.80904221534729,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.19175778329372406,
        },
        {
          x: 0.8738846182823181,
          y: 0.1980656087398529,
        },
        {
          x: 0.80904221534729,
          y: 0.1980656087398529,
        },
      ],
    },
  },
  {
    id: "513",
    key: "ITEM",
    resultText: "0175118",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.37552565336227417,
        },
        {
          x: 0.0945865586400032,
          y: 0.37552565336227417,
        },
        {
          x: 0.0945865586400032,
          y: 0.38141295313835144,
        },
        {
          x: 0.04521118476986885,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "514",
    key: "ITEM",
    resultText: "0175124",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04640095308423042,
          y: 0.39529016613960266,
        },
        {
          x: 0.09280190616846085,
          y: 0.39529016613960266,
        },
        {
          x: 0.09280190616846085,
          y: 0.4015979766845703,
        },
        {
          x: 0.04640095308423042,
          y: 0.4015979766845703,
        },
      ],
    },
  },
  {
    id: "515",
    key: "ITEM",
    resultText: "0175126",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.41505467891693115,
        },
        {
          x: 0.0945865586400032,
          y: 0.41505467891693115,
        },
        {
          x: 0.0945865586400032,
          y: 0.4209419786930084,
        },
        {
          x: 0.04521118476986885,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "516",
    key: "ITEM",
    resultText: "0175134",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.43481916189193726,
        },
        {
          x: 0.09399167448282242,
          y: 0.43481916189193726,
        },
        {
          x: 0.09399167448282242,
          y: 0.4407064616680145,
        },
        {
          x: 0.04521118476986885,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "517",
    key: "ITEM",
    resultText: "0175136",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.45458367466926575,
        },
        {
          x: 0.0945865586400032,
          y: 0.45458367466926575,
        },
        {
          x: 0.0945865586400032,
          y: 0.460470974445343,
        },
        {
          x: 0.04521118476986885,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "518",
    key: "ITEM",
    resultText: "0175174",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.4751892387866974,
        },
        {
          x: 0.09399167448282242,
          y: 0.4751892387866974,
        },
        {
          x: 0.09399167448282242,
          y: 0.4806560277938843,
        },
        {
          x: 0.04521118476986885,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "519",
    key: "ITEM",
    resultText: "0175182",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.503784716129303,
        },
        {
          x: 0.0945865586400032,
          y: 0.503784716129303,
        },
        {
          x: 0.0945865586400032,
          y: 0.5096719861030579,
        },
        {
          x: 0.04521118476986885,
          y: 0.5096719861030579,
        },
      ],
    },
  },
  {
    id: "520",
    key: "ITEM",
    resultText: "0179111",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04580606892704964,
          y: 0.5328006744384766,
        },
        {
          x: 0.09101725369691849,
          y: 0.5328006744384766,
        },
        {
          x: 0.09101725369691849,
          y: 0.5386879444122314,
        },
        {
          x: 0.04580606892704964,
          y: 0.5386879444122314,
        },
      ],
    },
  },
  {
    id: "521",
    key: "ITEM",
    resultText: "0179113",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.5525651574134827,
        },
        {
          x: 0.09399167448282242,
          y: 0.5525651574134827,
        },
        {
          x: 0.09399167448282242,
          y: 0.5588729977607727,
        },
        {
          x: 0.04521118476986885,
          y: 0.5588729977607727,
        },
      ],
    },
  },
  {
    id: "522",
    key: "ITEM",
    resultText: "0179123",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.5727502107620239,
        },
        {
          x: 0.09280190616846085,
          y: 0.5727502107620239,
        },
        {
          x: 0.09280190616846085,
          y: 0.5786374807357788,
        },
        {
          x: 0.04521118476986885,
          y: 0.5786374807357788,
        },
      ],
    },
  },
  {
    id: "523",
    key: "ITEM",
    resultText: "0179135",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.5920941829681396,
        },
        {
          x: 0.0945865586400032,
          y: 0.5920941829681396,
        },
        {
          x: 0.0945865586400032,
          y: 0.5984020233154297,
        },
        {
          x: 0.04521118476986885,
          y: 0.5984020233154297,
        },
      ],
    },
  },
  {
    id: "524",
    key: "ITEM",
    resultText: "0179137",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.6122792363166809,
        },
        {
          x: 0.09220702201128006,
          y: 0.6122792363166809,
        },
        {
          x: 0.09220702201128006,
          y: 0.618587076663971,
        },
        {
          x: 0.04521118476986885,
          y: 0.618587076663971,
        },
      ],
    },
  },
  {
    id: "525",
    key: "ITEM",
    resultText: "0179153",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.6316232085227966,
        },
        {
          x: 0.0945865586400032,
          y: 0.6316232085227966,
        },
        {
          x: 0.0945865586400032,
          y: 0.638351559638977,
        },
        {
          x: 0.04521118476986885,
          y: 0.638351559638977,
        },
      ],
    },
  },
  {
    id: "526",
    key: "ITEM",
    resultText: "0179155",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.6518082618713379,
        },
        {
          x: 0.0945865586400032,
          y: 0.6518082618713379,
        },
        {
          x: 0.0945865586400032,
          y: 0.6576955318450928,
        },
        {
          x: 0.04521118476986885,
          y: 0.6576955318450928,
        },
      ],
    },
  },
  {
    id: "527",
    key: "ITEM",
    resultText: "0179183",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.671572744846344,
        },
        {
          x: 0.0945865586400032,
          y: 0.671572744846344,
        },
        {
          x: 0.0945865586400032,
          y: 0.6774600744247437,
        },
        {
          x: 0.04521118476986885,
          y: 0.6774600744247437,
        },
      ],
    },
  },
  {
    id: "528",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.37552565336227417,
        },
        {
          x: 0.8941106200218201,
          y: 0.37552565336227417,
        },
        {
          x: 0.8941106200218201,
          y: 0.38141295313835144,
        },
        {
          x: 0.8851873874664307,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "529",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.39529016613960266,
        },
        {
          x: 0.8947055339813232,
          y: 0.39529016613960266,
        },
        {
          x: 0.8947055339813232,
          y: 0.4015979766845703,
        },
        {
          x: 0.8851873874664307,
          y: 0.4015979766845703,
        },
      ],
    },
  },
  {
    id: "530",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.41505467891693115,
        },
        {
          x: 0.8947055339813232,
          y: 0.41505467891693115,
        },
        {
          x: 0.8947055339813232,
          y: 0.4209419786930084,
        },
        {
          x: 0.8851873874664307,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "531",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.43523970246315,
        },
        {
          x: 0.8947055339813232,
          y: 0.43523970246315,
        },
        {
          x: 0.8947055339813232,
          y: 0.4407064616680145,
        },
        {
          x: 0.8851873874664307,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "532",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.4550042152404785,
        },
        {
          x: 0.8947055339813232,
          y: 0.4550042152404785,
        },
        {
          x: 0.8947055339813232,
          y: 0.460470974445343,
        },
        {
          x: 0.8851873874664307,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "533",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8851873874664307,
          y: 0.4751892387866974,
        },
        {
          x: 0.8947055339813232,
          y: 0.4751892387866974,
        },
        {
          x: 0.8947055339813232,
          y: 0.4806560277938843,
        },
        {
          x: 0.8851873874664307,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "534",
    key: "MADE_IN",
    resultText: "IT",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8845925331115723,
          y: 0.5042052268981934,
        },
        {
          x: 0.8953003883361816,
          y: 0.5042052268981934,
        },
        {
          x: 0.8953003883361816,
          y: 0.5096719861030579,
        },
        {
          x: 0.8845925331115723,
          y: 0.5096719861030579,
        },
      ],
    },
  },
  {
    id: "535",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.5332211852073669,
        },
        {
          x: 0.8947055339813232,
          y: 0.5332211852073669,
        },
        {
          x: 0.8947055339813232,
          y: 0.5386879444122314,
        },
        {
          x: 0.8786436915397644,
          y: 0.5386879444122314,
        },
      ],
    },
  },
  {
    id: "536",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.5529857277870178,
        },
        {
          x: 0.8947055339813232,
          y: 0.5529857277870178,
        },
        {
          x: 0.8947055339813232,
          y: 0.5584524869918823,
        },
        {
          x: 0.879833459854126,
          y: 0.5584524869918823,
        },
      ],
    },
  },
  {
    id: "537",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.5731707215309143,
        },
        {
          x: 0.8947055339813232,
          y: 0.5731707215309143,
        },
        {
          x: 0.8947055339813232,
          y: 0.579058051109314,
        },
        {
          x: 0.879833459854126,
          y: 0.579058051109314,
        },
      ],
    },
  },
  {
    id: "538",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.5929352641105652,
        },
        {
          x: 0.8947055339813232,
          y: 0.5929352641105652,
        },
        {
          x: 0.8947055339813232,
          y: 0.5988225340843201,
        },
        {
          x: 0.8792385458946228,
          y: 0.5988225340843201,
        },
      ],
    },
  },
  {
    id: "539",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6126997470855713,
        },
        {
          x: 0.8947055339813232,
          y: 0.6126997470855713,
        },
        {
          x: 0.8947055339813232,
          y: 0.618587076663971,
        },
        {
          x: 0.879833459854126,
          y: 0.618587076663971,
        },
      ],
    },
  },
  {
    id: "540",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6324642300605774,
        },
        {
          x: 0.8947055339813232,
          y: 0.6324642300605774,
        },
        {
          x: 0.8947055339813232,
          y: 0.6379310488700867,
        },
        {
          x: 0.879833459854126,
          y: 0.6379310488700867,
        },
      ],
    },
  },
  {
    id: "541",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6518082618713379,
        },
        {
          x: 0.8941106200218201,
          y: 0.6518082618713379,
        },
        {
          x: 0.8941106200218201,
          y: 0.6576955318450928,
        },
        {
          x: 0.879833459854126,
          y: 0.6576955318450928,
        },
      ],
    },
  },
  {
    id: "542",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6719932556152344,
        },
        {
          x: 0.8947055339813232,
          y: 0.6719932556152344,
        },
        {
          x: 0.8947055339813232,
          y: 0.6774600744247437,
        },
        {
          x: 0.879833459854126,
          y: 0.6774600744247437,
        },
      ],
    },
  },
  {
    id: "543",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6324642300605774,
        },
        {
          x: 0.8947055339813232,
          y: 0.6324642300605774,
        },
        {
          x: 0.8947055339813232,
          y: 0.6379310488700867,
        },
        {
          x: 0.879833459854126,
          y: 0.6379310488700867,
        },
      ],
    },
  },
  {
    id: "544",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6518082618713379,
        },
        {
          x: 0.8941106200218201,
          y: 0.6518082618713379,
        },
        {
          x: 0.8941106200218201,
          y: 0.6576955318450928,
        },
        {
          x: 0.879833459854126,
          y: 0.6576955318450928,
        },
      ],
    },
  },
  {
    id: "545",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.6719932556152344,
        },
        {
          x: 0.8947055339813232,
          y: 0.6719932556152344,
        },
        {
          x: 0.8947055339813232,
          y: 0.6774600744247437,
        },
        {
          x: 0.879833459854126,
          y: 0.6774600744247437,
        },
      ],
    },
  },
  {
    id: "546",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8786436915397644,
          y: 0.5332211852073669,
        },
        {
          x: 0.8947055339813232,
          y: 0.5332211852073669,
        },
        {
          x: 0.8947055339813232,
          y: 0.5386879444122314,
        },
        {
          x: 0.8786436915397644,
          y: 0.5386879444122314,
        },
      ],
    },
  },
  {
    id: "547",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.879833459854126,
          y: 0.5529857277870178,
        },
        {
          x: 0.8947055339813232,
          y: 0.5529857277870178,
        },
        {
          x: 0.8947055339813232,
          y: 0.5584524869918823,
        },
        {
          x: 0.879833459854126,
          y: 0.5584524869918823,
        },
      ],
    },
  },
  {
    id: "548",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.5929352641105652,
        },
        {
          x: 0.8947055339813232,
          y: 0.5929352641105652,
        },
        {
          x: 0.8947055339813232,
          y: 0.5988225340843201,
        },
        {
          x: 0.8792385458946228,
          y: 0.5988225340843201,
        },
      ],
    },
  },
  {
    id: "549",
    key: "QTY",
    resultText: "260",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5645449161529541,
          y: 0.37594616413116455,
        },
        {
          x: 0.5865556001663208,
          y: 0.37594616413116455,
        },
        {
          x: 0.5865556001663208,
          y: 0.38141295313835144,
        },
        {
          x: 0.5645449161529541,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "550",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.39571067690849304,
        },
        {
          x: 0.5859607458114624,
          y: 0.39571067690849304,
        },
        {
          x: 0.5859607458114624,
          y: 0.40117746591567993,
        },
        {
          x: 0.570493757724762,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "551",
    key: "QTY",
    resultText: "40",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.41547518968582153,
        },
        {
          x: 0.5859607458114624,
          y: 0.41547518968582153,
        },
        {
          x: 0.5859607458114624,
          y: 0.4209419786930084,
        },
        {
          x: 0.5698988437652588,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "552",
    key: "QTY",
    resultText: "190",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5639500021934509,
          y: 0.43481916189193726,
        },
        {
          x: 0.5865556001663208,
          y: 0.43481916189193726,
        },
        {
          x: 0.5865556001663208,
          y: 0.4411270022392273,
        },
        {
          x: 0.5639500021934509,
          y: 0.4411270022392273,
        },
      ],
    },
  },
  {
    id: "553",
    key: "QTY",
    resultText: "160",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5633551478385925,
          y: 0.4550042152404785,
        },
        {
          x: 0.5859607458114624,
          y: 0.4550042152404785,
        },
        {
          x: 0.5859607458114624,
          y: 0.460470974445343,
        },
        {
          x: 0.5633551478385925,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "554",
    key: "QTY",
    resultText: "7500",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5580011606216431,
          y: 0.474768728017807,
        },
        {
          x: 0.587150514125824,
          y: 0.474768728017807,
        },
        {
          x: 0.587150514125824,
          y: 0.4806560277938843,
        },
        {
          x: 0.5580011606216431,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "555",
    key: "QTY",
    resultText: "2790",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5574063062667847,
          y: 0.5042052268981934,
        },
        {
          x: 0.5865556001663208,
          y: 0.5042052268981934,
        },
        {
          x: 0.5865556001663208,
          y: 0.5100924968719482,
        },
        {
          x: 0.5574063062667847,
          y: 0.5100924968719482,
        },
      ],
    },
  },
  {
    id: "556",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.5332211852073669,
        },
        {
          x: 0.5859607458114624,
          y: 0.5332211852073669,
        },
        {
          x: 0.5859607458114624,
          y: 0.5382674336433411,
        },
        {
          x: 0.570493757724762,
          y: 0.5382674336433411,
        },
      ],
    },
  },
  {
    id: "557",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.5529857277870178,
        },
        {
          x: 0.5853658318519592,
          y: 0.5529857277870178,
        },
        {
          x: 0.5853658318519592,
          y: 0.5584524869918823,
        },
        {
          x: 0.5710886120796204,
          y: 0.5584524869918823,
        },
      ],
    },
  },
  {
    id: "558",
    key: "QTY",
    resultText: "36",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5716835260391235,
          y: 0.5735912322998047,
        },
        {
          x: 0.5865556001663208,
          y: 0.5735912322998047,
        },
        {
          x: 0.5865556001663208,
          y: 0.5786374807357788,
        },
        {
          x: 0.5716835260391235,
          y: 0.5786374807357788,
        },
      ],
    },
  },
  {
    id: "559",
    key: "QTY",
    resultText: "24",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.5929352641105652,
        },
        {
          x: 0.587150514125824,
          y: 0.5929352641105652,
        },
        {
          x: 0.587150514125824,
          y: 0.5984020233154297,
        },
        {
          x: 0.5698988437652588,
          y: 0.5984020233154297,
        },
      ],
    },
  },
  {
    id: "560",
    key: "QTY",
    resultText: "30",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.6126997470855713,
        },
        {
          x: 0.5859607458114624,
          y: 0.6126997470855713,
        },
        {
          x: 0.5859607458114624,
          y: 0.6177459955215454,
        },
        {
          x: 0.570493757724762,
          y: 0.6177459955215454,
        },
      ],
    },
  },
  {
    id: "561",
    key: "QTY",
    resultText: "50",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.6324642300605774,
        },
        {
          x: 0.5859607458114624,
          y: 0.6324642300605774,
        },
        {
          x: 0.5859607458114624,
          y: 0.638351559638977,
        },
        {
          x: 0.5710886120796204,
          y: 0.638351559638977,
        },
      ],
    },
  },
  {
    id: "562",
    key: "QTY",
    resultText: "45",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5698988437652588,
          y: 0.6522287726402283,
        },
        {
          x: 0.5865556001663208,
          y: 0.6522287726402283,
        },
        {
          x: 0.5865556001663208,
          y: 0.6576955318450928,
        },
        {
          x: 0.5698988437652588,
          y: 0.6576955318450928,
        },
      ],
    },
  },
  {
    id: "563",
    key: "QTY",
    resultText: "50",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.6719932556152344,
        },
        {
          x: 0.5859607458114624,
          y: 0.6719932556152344,
        },
        {
          x: 0.5859607458114624,
          y: 0.677880585193634,
        },
        {
          x: 0.5710886120796204,
          y: 0.677880585193634,
        },
      ],
    },
  },
  {
    id: "564",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.39571067690849304,
        },
        {
          x: 0.5859607458114624,
          y: 0.39571067690849304,
        },
        {
          x: 0.5859607458114624,
          y: 0.40117746591567993,
        },
        {
          x: 0.570493757724762,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "565",
    key: "QTY",
    resultText: "50",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.5710886120796204,
          y: 0.6719932556152344,
        },
        {
          x: 0.5859607458114624,
          y: 0.6719932556152344,
        },
        {
          x: 0.5859607458114624,
          y: 0.677880585193634,
        },
        {
          x: 0.5710886120796204,
          y: 0.677880585193634,
        },
      ],
    },
  },
  {
    id: "566",
    key: "QTY",
    resultText: "60",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.570493757724762,
          y: 0.5332211852073669,
        },
        {
          x: 0.5859607458114624,
          y: 0.5332211852073669,
        },
        {
          x: 0.5859607458114624,
          y: 0.5382674336433411,
        },
        {
          x: 0.570493757724762,
          y: 0.5382674336433411,
        },
      ],
    },
  },
  {
    id: "567",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.37594616413116455,
        },
        {
          x: 0.7132658958435059,
          y: 0.37594616413116455,
        },
        {
          x: 0.7132658958435059,
          y: 0.38141295313835144,
        },
        {
          x: 0.6769779920578003,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "568",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.39571067690849304,
        },
        {
          x: 0.713860809803009,
          y: 0.39571067690849304,
        },
        {
          x: 0.713860809803009,
          y: 0.40117746591567993,
        },
        {
          x: 0.6763830780982971,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "569",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.41505467891693115,
        },
        {
          x: 0.713860809803009,
          y: 0.41505467891693115,
        },
        {
          x: 0.713860809803009,
          y: 0.4209419786930084,
        },
        {
          x: 0.6763830780982971,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "570",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.43481916189193726,
        },
        {
          x: 0.7132658958435059,
          y: 0.43481916189193726,
        },
        {
          x: 0.7132658958435059,
          y: 0.4407064616680145,
        },
        {
          x: 0.6769779920578003,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "571",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.45458367466926575,
        },
        {
          x: 0.713860809803009,
          y: 0.45458367466926575,
        },
        {
          x: 0.713860809803009,
          y: 0.460470974445343,
        },
        {
          x: 0.6763830780982971,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "572",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.4751892387866974,
        },
        {
          x: 0.713860809803009,
          y: 0.4751892387866974,
        },
        {
          x: 0.713860809803009,
          y: 0.4806560277938843,
        },
        {
          x: 0.6763830780982971,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "573",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.5042052268981934,
        },
        {
          x: 0.7132658958435059,
          y: 0.5042052268981934,
        },
        {
          x: 0.7132658958435059,
          y: 0.5096719861030579,
        },
        {
          x: 0.6769779920578003,
          y: 0.5096719861030579,
        },
      ],
    },
  },
  {
    id: "574",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.5332211852073669,
        },
        {
          x: 0.7144556641578674,
          y: 0.5332211852073669,
        },
        {
          x: 0.7144556641578674,
          y: 0.5386879444122314,
        },
        {
          x: 0.6751933097839355,
          y: 0.5386879444122314,
        },
      ],
    },
  },
  {
    id: "575",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.5529857277870178,
        },
        {
          x: 0.7144556641578674,
          y: 0.5529857277870178,
        },
        {
          x: 0.7144556641578674,
          y: 0.5588729977607727,
        },
        {
          x: 0.6751933097839355,
          y: 0.5588729977607727,
        },
      ],
    },
  },
  {
    id: "576",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.5731707215309143,
        },
        {
          x: 0.7144556641578674,
          y: 0.5731707215309143,
        },
        {
          x: 0.7144556641578674,
          y: 0.579058051109314,
        },
        {
          x: 0.6751933097839355,
          y: 0.579058051109314,
        },
      ],
    },
  },
  {
    id: "577",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.59251469373703,
        },
        {
          x: 0.7150505781173706,
          y: 0.59251469373703,
        },
        {
          x: 0.7150505781173706,
          y: 0.5984020233154297,
        },
        {
          x: 0.6751933097839355,
          y: 0.5984020233154297,
        },
      ],
    },
  },
  {
    id: "578",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.6122792363166809,
        },
        {
          x: 0.7144556641578674,
          y: 0.6122792363166809,
        },
        {
          x: 0.7144556641578674,
          y: 0.618587076663971,
        },
        {
          x: 0.6751933097839355,
          y: 0.618587076663971,
        },
      ],
    },
  },
  {
    id: "579",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.6324642300605774,
        },
        {
          x: 0.7150505781173706,
          y: 0.6324642300605774,
        },
        {
          x: 0.7150505781173706,
          y: 0.6379310488700867,
        },
        {
          x: 0.6751933097839355,
          y: 0.6379310488700867,
        },
      ],
    },
  },
  {
    id: "580",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.6522287726402283,
        },
        {
          x: 0.7150505781173706,
          y: 0.6522287726402283,
        },
        {
          x: 0.7150505781173706,
          y: 0.6576955318450928,
        },
        {
          x: 0.6751933097839355,
          y: 0.6576955318450928,
        },
      ],
    },
  },
  {
    id: "581",
    key: "UNIT_PRICE",
    resultText: "485.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6751933097839355,
          y: 0.6719932556152344,
        },
        {
          x: 0.7150505781173706,
          y: 0.6719932556152344,
        },
        {
          x: 0.7150505781173706,
          y: 0.6774600744247437,
        },
        {
          x: 0.6751933097839355,
          y: 0.6774600744247437,
        },
      ],
    },
  },
  {
    id: "582",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.5042052268981934,
        },
        {
          x: 0.7132658958435059,
          y: 0.5042052268981934,
        },
        {
          x: 0.7132658958435059,
          y: 0.5096719861030579,
        },
        {
          x: 0.6769779920578003,
          y: 0.5096719861030579,
        },
      ],
    },
  },
  {
    id: "583",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.37594616413116455,
        },
        {
          x: 0.7132658958435059,
          y: 0.37594616413116455,
        },
        {
          x: 0.7132658958435059,
          y: 0.38141295313835144,
        },
        {
          x: 0.6769779920578003,
          y: 0.38141295313835144,
        },
      ],
    },
  },
  {
    id: "584",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.39571067690849304,
        },
        {
          x: 0.713860809803009,
          y: 0.39571067690849304,
        },
        {
          x: 0.713860809803009,
          y: 0.40117746591567993,
        },
        {
          x: 0.6763830780982971,
          y: 0.40117746591567993,
        },
      ],
    },
  },
  {
    id: "585",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.41505467891693115,
        },
        {
          x: 0.713860809803009,
          y: 0.41505467891693115,
        },
        {
          x: 0.713860809803009,
          y: 0.4209419786930084,
        },
        {
          x: 0.6763830780982971,
          y: 0.4209419786930084,
        },
      ],
    },
  },
  {
    id: "586",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6769779920578003,
          y: 0.43481916189193726,
        },
        {
          x: 0.7132658958435059,
          y: 0.43481916189193726,
        },
        {
          x: 0.7132658958435059,
          y: 0.4407064616680145,
        },
        {
          x: 0.6769779920578003,
          y: 0.4407064616680145,
        },
      ],
    },
  },
  {
    id: "587",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.45458367466926575,
        },
        {
          x: 0.713860809803009,
          y: 0.45458367466926575,
        },
        {
          x: 0.713860809803009,
          y: 0.460470974445343,
        },
        {
          x: 0.6763830780982971,
          y: 0.460470974445343,
        },
      ],
    },
  },
  {
    id: "588",
    key: "UNIT_PRICE",
    resultText: "740.00",
    confidence: 1,
    hightLightZone: {
      page: "6",
      boundingBox: [
        {
          x: 0.6763830780982971,
          y: 0.4751892387866974,
        },
        {
          x: 0.713860809803009,
          y: 0.4751892387866974,
        },
        {
          x: 0.713860809803009,
          y: 0.4806560277938843,
        },
        {
          x: 0.6763830780982971,
          y: 0.4806560277938843,
        },
      ],
    },
  },
  {
    id: "593",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9844036102294922,
    hightLightZone: {
      page: "7",
      boundingBox: [
        {
          x: 0.1719214767217636,
          y: 0.3015138804912567,
        },
        {
          x: 0.3372992277145386,
          y: 0.3015138804912567,
        },
        {
          x: 0.3372992277145386,
          y: 0.3090832531452179,
        },
        {
          x: 0.1719214767217636,
          y: 0.3090832531452179,
        },
      ],
    },
  },
  {
    id: "598",
    key: "CUSTOMER_REF",
    resultText: "THDF_COAUG24_2011002125",
    confidence: 0.9811807870864868,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.1719214767217636,
          y: 0.3015138804912567,
        },
        {
          x: 0.33789411187171936,
          y: 0.3015138804912567,
        },
        {
          x: 0.33789411187171936,
          y: 0.3086627423763275,
        },
        {
          x: 0.1719214767217636,
          y: 0.3086627423763275,
        },
      ],
    },
  },
  {
    id: "599",
    key: "DESCRIPTION",
    resultText: "LA MOUSSE 150 ML",
    confidence: 0.9999277591705322,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.14336703717708588,
          y: 0.3877207636833191,
        },
        {
          x: 0.25282570719718933,
          y: 0.3877207636833191,
        },
        {
          x: 0.25282570719718933,
          y: 0.39360806345939636,
        },
        {
          x: 0.14336703717708588,
          y: 0.39360806345939636,
        },
      ],
    },
  },
  {
    id: "600",
    key: "INVOICE_NO",
    resultText: "SIN0064468",
    confidence: 0.9999903440475464,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.80904221534729,
          y: 0.19175778329372406,
        },
        {
          x: 0.8732897043228149,
          y: 0.19175778329372406,
        },
        {
          x: 0.8732897043228149,
          y: 0.1980656087398529,
        },
        {
          x: 0.80904221534729,
          y: 0.1980656087398529,
        },
      ],
    },
  },
  {
    id: "601",
    key: "ITEM",
    resultText: "0141450",
    confidence: 0.9999959468841553,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.04521118476986885,
          y: 0.3877207636833191,
        },
        {
          x: 0.0945865586400032,
          y: 0.3877207636833191,
        },
        {
          x: 0.0945865586400032,
          y: 0.39360806345939636,
        },
        {
          x: 0.04521118476986885,
          y: 0.39360806345939636,
        },
      ],
    },
  },
  {
    id: "602",
    key: "MADE_IN",
    resultText: "FR",
    confidence: 0.9998713731765747,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.8792385458946228,
          y: 0.3873002529144287,
        },
        {
          x: 0.8953003883361816,
          y: 0.3873002529144287,
        },
        {
          x: 0.8953003883361816,
          y: 0.39360806345939636,
        },
        {
          x: 0.8792385458946228,
          y: 0.39360806345939636,
        },
      ],
    },
  },
  {
    id: "603",
    key: "QTY",
    resultText: "6720",
    confidence: 0.9999821186065674,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.5568113923072815,
          y: 0.3873002529144287,
        },
        {
          x: 0.5865556001663208,
          y: 0.3873002529144287,
        },
        {
          x: 0.5865556001663208,
          y: 0.3927670419216156,
        },
        {
          x: 0.5568113923072815,
          y: 0.3927670419216156,
        },
      ],
    },
  },
  {
    id: "604",
    key: "UNIT_PRICE",
    resultText: "890.00",
    confidence: 0.9999954700469971,
    hightLightZone: {
      page: "8",
      boundingBox: [
        {
          x: 0.6757882237434387,
          y: 0.38687974214553833,
        },
        {
          x: 0.713860809803009,
          y: 0.38687974214553833,
        },
        {
          x: 0.713860809803009,
          y: 0.393187552690506,
        },
        {
          x: 0.6757882237434387,
          y: 0.393187552690506,
        },
      ],
    },
  },
];

// [
//   {
//     id: "4",
//     key: "CUSTOMER_REF",
//     resultText: "THDF_COAUG24_2011002125",
//     confidence: 0.9999891519546509,
//     hightLightZone: {
//       page: "1",
//       boundingBox: [
//         {
//           x: 0.19214753806591034,
//           y: 0.27039527893066406,
//         },
//         {
//           x: 0.34205830097198486,
//           y: 0.27039527893066406,
//         },
//         {
//           x: 0.34205830097198486,
//           y: 0.2771236300468445,
//         },
//         {
//           x: 0.19214753806591034,
//           y: 0.2771236300468445,
//         },
//       ],
//     },
//   },

//   {
//     id: "110",
//     key: "CUSTOMER_REF",
//     resultText: "THDF_COAUG24_2011002125",
//     confidence: 0.9999896287918091,
//     hightLightZone: {
//       page: "2",
//       boundingBox: [
//         {
//           x: 0.19274242222309113,
//           y: 0.27039527893066406,
//         },
//         {
//           x: 0.34205830097198486,
//           y: 0.27039527893066406,
//         },
//         {
//           x: 0.34205830097198486,
//           y: 0.2771236300468445,
//         },
//         {
//           x: 0.19274242222309113,
//           y: 0.2771236300468445,
//         },
//       ],
//     },
//   },
// ];
