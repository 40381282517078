
import { KeyProcessDetailEnum, resultProcessEnum } from "../enum/processType";
import {
  IDataKingPowerMap,
} from "../interface/mapData";
import { IProcessResultProps } from "../interface/processResult";
import * as XLSX from "xlsx";

export interface IExportCsvKingPowerReturn {
  dataInvoiceCsv: XLSX.WorkBook;
  dataInvoiceFileSize: number;
}

interface GroupedData {
  [page: string]: IProcessResultProps[];
}

const normalizeString = (str: string): string => {
  return str
    .replace(/[АΑΔΑ]/g, "A")
    .replace(/[ВΒ]/g, "B")
    .replace(/[СϹΓϹ]/g, "C")
    .replace(/[РЕΡР]/g, "P")
    .replace(/[ЕΕЕ]/g, "E")
    .replace(/[ΘΕЕ]/g, "F")
    .replace(/[ΟО]/g, "O")
    .replace(/[ΝⲚⲚΙ]/g, "N")
    .replace(/[ΗΗ]/g, "H")
    .replace(/[ІΙ]/g, "I")
    .replace(/[ЈЈ]/g, "J")
    .replace(/[КΚ]/g, "K")
    .replace(/[ГⲄᏀԌ]/g, "G")
    .replace(/[ⲘΜ]/g, "M")
    .replace(/[ТΤԎᎢ]/g, "T")
    .replace(/[ЅЅ]/g, "S")
    .replace(/[ЦᏟᏕⲤ]/g, "C")
    .replace(/[ᏙᎠⅤ]/g, "V")
    .replace(/[ЏѠ]/g, "W")
    .replace(/[ΧΧ]/g, "X")
    .replace(/[ΥҮ]/g, "Y")
    .replace(/[Ζ]/g, "Z")
    .replace(/[аα]/g, "a")
    .replace(/[ъЬЬ]/g, "b")
    .replace(/[сς]/g, "c")
    .replace(/[ԁⅾ]/g, "d")
    .replace(/[еε]/g, "e")
    .replace(/[𝖿]/g, "f")
    .replace(/[ɡ]/g, "g")
    .replace(/[һ]/g, "h")
    .replace(/[іі]/g, "i")
    .replace(/[ј]/g, "j")
    .replace(/[κк]/g, "k")
    .replace(/[ӏ]/g, "l")
    .replace(/[ⅿ]/g, "m")
    .replace(/[ոռ]/g, "n")
    .replace(/[оο]/g, "o")
    .replace(/[р]/g, "p")
    .replace(/[ԛ]/g, "q")
    .replace(/[гᴦ]/g, "r")
    .replace(/[ѕ]/g, "s")
    .replace(/[τ]/g, "t")
    .replace(/[ս]/g, "u")
    .replace(/[ν]/g, "v")
    .replace(/[ѡ]/g, "w")
    .replace(/[х]/g, "x")
    .replace(/[у]/g, "y")
    .replace(/[ᴢ]/g, "z");
};

export function exportDataKKingPowerToCsv(
  processInvoiceResult: IProcessResultProps[]
): IExportCsvKingPowerReturn {
  const mapData: IDataKingPowerMap[] = [];

  const groupData = processInvoiceResult.reduce<GroupedData>((acc, item) => {
    const page = item.hightLightZone.page;
    if (!acc[page]) {
      acc[page] = [];
    }
    acc[page].push(item);
    return acc;
  }, {});

  const getKeyPage = Object.keys(groupData);

  for (let index = 0; index < getKeyPage.length; index++) {
    const invoiceNo = groupData[getKeyPage[index]].find(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_INVOICE_NO
    );

    const customerRef = groupData[getKeyPage[index]].find(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_CUSTOMER_REF
    );

    const description = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_DESCRIPTION
    );

    const item = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_ITEM
    );

    const madeIn = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_MADE_IN
    );

    const qty = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_QTY
    );

    const unitPrice = groupData[getKeyPage[index]].filter(
      (e) => e.key === KeyProcessDetailEnum.KING_POWER_INVOICE_UNIT_PRICE
    );

    item.forEach((e, indexItem) => {
      mapData.push({
        Invoice: invoiceNo ? invoiceNo.resultText ?? "" : "",
        Ref: e.resultText ?? "",
        description: description[indexItem].resultText ?? "",
        QTY: qty[indexItem].resultText ?? "",
        Price: unitPrice[indexItem].resultText ?? "",
        "Customs item": (indexItem + 1) as unknown as string,
        Origin: madeIn[indexItem].resultText ?? "",
        Search: "",
        "Invoice Unit": "",
        PO: customerRef ? customerRef.resultText?.split("_").pop() ?? "" : "",
      });
    });
  }
  // Convert the array of objects to a worksheet
  const dataSheet = XLSX.utils.json_to_sheet(mapData);

  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Append worksheets to the workbook
  XLSX.utils.book_append_sheet(wb, dataSheet, "data");

  // Write the workbook to a file and trigger the download
  const dataCsv = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(dataCsv, dataSheet, "data");

  // Convert the workbook to a binary string
  const dataCsvBinary = XLSX.write(dataCsv, { type: "binary" });

  // Calculate the size of the binary string
  const dataCsvSize = dataCsvBinary.length;

  return {
    dataInvoiceCsv: dataCsv,
    dataInvoiceFileSize: dataCsvSize,
  };
}
