export const mockupDataTypeProcess1 = [
    {
        "id": "0",
        "key": "PAYTERM",
        "resultText": "O/A 15 Days",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6180844902992249,
                    "y": 0.26313576102256775
                },
                {
                    "x": 0.7102915048599243,
                    "y": 0.26313576102256775
                },
                {
                    "x": 0.7102915048599243,
                    "y": 0.27406471967697144
                },
                {
                    "x": 0.6180844902992249,
                    "y": 0.27406471967697144
                }
            ]
        }
    },
    {
        "id": "1",
        "key": "AEO_NO",
        "resultText": "THIE570073",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.7685901522636414,
                    "y": 0.12147961556911469
                },
                {
                    "x": 0.8774539232254028,
                    "y": 0.12147961556911469
                },
                {
                    "x": 0.8774539232254028,
                    "y": 0.12946616113185883
                },
                {
                    "x": 0.7685901522636414,
                    "y": 0.12946616113185883
                }
            ]
        }
    },
    {
        "id": "3",
        "key": "TRAN_MODE",
        "resultText": "AIR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6085663437843323,
                    "y": 0.14838168025016785
                },
                {
                    "x": 0.6353361010551453,
                    "y": 0.14838168025016785
                },
                {
                    "x": 0.6353361010551453,
                    "y": 0.15931063890457153
                },
                {
                    "x": 0.6085663437843323,
                    "y": 0.15931063890457153
                }
            ]
        }
    },
    {
        "id": "4",
        "key": "INVQTY",
        "resultText": "2,138PCE",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.48899465799331665,
                    "y": 0.49600672721862793
                },
                {
                    "x": 0.5562165379524231,
                    "y": 0.49600672721862793
                },
                {
                    "x": 0.5562165379524231,
                    "y": 0.5048339366912842
                },
                {
                    "x": 0.48899465799331665,
                    "y": 0.5048339366912842
                }
            ]
        }
    },
    {
        "id": "5",
        "key": "INVQTY",
        "resultText": "1,120NPR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.48899465799331665,
                    "y": 0.5531736016273499
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.5531736016273499
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.5620008111000061
                },
                {
                    "x": 0.48899465799331665,
                    "y": 0.5620008111000061
                }
            ]
        }
    },
    {
        "id": "6",
        "key": "INVQTY",
        "resultText": "1,120NPR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.48958954215049744,
                    "y": 0.6099201440811157
                },
                {
                    "x": 0.5574063062667847,
                    "y": 0.6099201440811157
                },
                {
                    "x": 0.5574063062667847,
                    "y": 0.618747353553772
                },
                {
                    "x": 0.48958954215049744,
                    "y": 0.618747353553772
                }
            ]
        }
    },
    {
        "id": "7",
        "key": "INVQTY",
        "resultText": "760NPR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5062462687492371,
                    "y": 0.6670870184898376
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.6670870184898376
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.6746532320976257
                },
                {
                    "x": 0.5062462687492371,
                    "y": 0.6746532320976257
                }
            ]
        }
    },
    {
        "id": "8",
        "key": "INVQTY",
        "resultText": "3,000PCE",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.48899465799331665,
                    "y": 0.7242538928985596
                },
                {
                    "x": 0.5562165379524231,
                    "y": 0.7242538928985596
                },
                {
                    "x": 0.5562165379524231,
                    "y": 0.7330811023712158
                },
                {
                    "x": 0.48899465799331665,
                    "y": 0.7330811023712158
                }
            ]
        }
    },
    {
        "id": "9",
        "key": "INVQTY",
        "resultText": "2,508NPR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.48899465799331665,
                    "y": 0.7810004353523254
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.7810004353523254
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.7902479767799377
                },
                {
                    "x": 0.48899465799331665,
                    "y": 0.7902479767799377
                }
            ]
        }
    },
    {
        "id": "10",
        "key": "INVQTY",
        "resultText": "692NPR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5068411827087402,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.5574063062667847,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.5574063062667847,
                    "y": 0.8461538553237915
                },
                {
                    "x": 0.5068411827087402,
                    "y": 0.8461538553237915
                }
            ]
        }
    },
    {
        "id": "11",
        "key": "ORICTRY",
        "resultText": "THAILAND",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6603212356567383,
                    "y": 0.23455233871936798
                },
                {
                    "x": 0.7281380295753479,
                    "y": 0.23455233871936798
                },
                {
                    "x": 0.7281380295753479,
                    "y": 0.24548129737377167
                },
                {
                    "x": 0.6603212356567383,
                    "y": 0.24548129737377167
                }
            ]
        }
    },
    {
        "id": "12",
        "key": "PURCTRY",
        "resultText": "MYANMAR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2534205913543701,
                    "y": 0.20638923346996307
                },
                {
                    "x": 0.31231409311294556,
                    "y": 0.20638923346996307
                },
                {
                    "x": 0.31231409311294556,
                    "y": 0.21395544707775116
                },
                {
                    "x": 0.2534205913543701,
                    "y": 0.21395544707775116
                }
            ]
        }
    },
    {
        "id": "13",
        "key": "TERM",
        "resultText": "DDP",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.14396192133426666,
                    "y": 0.37704917788505554
                },
                {
                    "x": 0.16894705593585968,
                    "y": 0.37704917788505554
                },
                {
                    "x": 0.16894705593585968,
                    "y": 0.3854560852050781
                },
                {
                    "x": 0.14396192133426666,
                    "y": 0.3854560852050781
                }
            ]
        }
    },
    {
        "id": "14",
        "key": "DESCTRY",
        "resultText": "MYANMAR",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5496728420257568,
                    "y": 0.1929382085800171
                },
                {
                    "x": 0.6103509664535522,
                    "y": 0.1929382085800171
                },
                {
                    "x": 0.6103509664535522,
                    "y": 0.20008407533168793
                },
                {
                    "x": 0.5496728420257568,
                    "y": 0.20008407533168793
                }
            ]
        }
    },
    {
        "id": "16",
        "key": "AMOUNT",
        "resultText": "64.14",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8744794726371765,
                    "y": 0.49516603350639343
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.49516603350639343
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.503572940826416
                },
                {
                    "x": 0.8744794726371765,
                    "y": 0.503572940826416
                }
            ]
        }
    },
    {
        "id": "17",
        "key": "AMOUNT",
        "resultText": "89.60",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8720999360084534,
                    "y": 0.5523329377174377
                },
                {
                    "x": 0.9196906685829163,
                    "y": 0.5523329377174377
                },
                {
                    "x": 0.9196906685829163,
                    "y": 0.561160147190094
                },
                {
                    "x": 0.8720999360084534,
                    "y": 0.561160147190094
                }
            ]
        }
    },
    {
        "id": "18",
        "key": "AMOUNT",
        "resultText": "89.60",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8726948499679565,
                    "y": 0.6086590886116028
                },
                {
                    "x": 0.9185009002685547,
                    "y": 0.6086590886116028
                },
                {
                    "x": 0.9185009002685547,
                    "y": 0.6174863576889038
                },
                {
                    "x": 0.8726948499679565,
                    "y": 0.6174863576889038
                }
            ]
        }
    },
    {
        "id": "19",
        "key": "AMOUNT",
        "resultText": "60.80",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8738846182823181,
                    "y": 0.6658259630203247
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.6658259630203247
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.6746532320976257
                },
                {
                    "x": 0.8738846182823181,
                    "y": 0.6746532320976257
                }
            ]
        }
    },
    {
        "id": "20",
        "key": "AMOUNT",
        "resultText": "150.00",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8655562400817871,
                    "y": 0.7234131693840027
                },
                {
                    "x": 0.9173111319541931,
                    "y": 0.7234131693840027
                },
                {
                    "x": 0.9173111319541931,
                    "y": 0.7322404384613037
                },
                {
                    "x": 0.8655562400817871,
                    "y": 0.7322404384613037
                }
            ]
        }
    },
    {
        "id": "21",
        "key": "AMOUNT",
        "resultText": "75.24",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8744794726371765,
                    "y": 0.7810004353523254
                },
                {
                    "x": 0.9190957546234131,
                    "y": 0.7810004353523254
                },
                {
                    "x": 0.9190957546234131,
                    "y": 0.7885666489601135
                },
                {
                    "x": 0.8744794726371765,
                    "y": 0.7885666489601135
                }
            ]
        }
    },
    {
        "id": "22",
        "key": "AMOUNT",
        "resultText": "20.76",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.8732897043228149,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.8461538553237915
                },
                {
                    "x": 0.8732897043228149,
                    "y": 0.8461538553237915
                }
            ]
        }
    },
    {
        "id": "25",
        "key": "DES1",
        "resultText": "BOBBIN LUG PM9630 EP13 H 9P L1=3.0+/-0.2",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.042831648141145706,
                    "y": 0.5376208424568176
                },
                {
                    "x": 0.3789411187171936,
                    "y": 0.5376208424568176
                },
                {
                    "x": 0.3789411187171936,
                    "y": 0.5472887754440308
                },
                {
                    "x": 0.042831648141145706,
                    "y": 0.5472887754440308
                }
            ]
        }
    },
    {
        "id": "26",
        "key": "DES1",
        "resultText": "CORE MZ EE25 25.4*18.9*6.3 A10 U10000",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04342653229832649,
                    "y": 0.5926859974861145
                },
                {
                    "x": 0.3539559841156006,
                    "y": 0.5926859974861145
                },
                {
                    "x": 0.3539559841156006,
                    "y": 0.6040353178977966
                },
                {
                    "x": 0.04342653229832649,
                    "y": 0.6040353178977966
                }
            ]
        }
    },
    {
        "id": "27",
        "key": "DES1",
        "resultText": "CORE MZ EE25 25.4*18.9*6.3 A10 U10000",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04342653229832649,
                    "y": 0.6519545912742615
                },
                {
                    "x": 0.3539559841156006,
                    "y": 0.6519545912742615
                },
                {
                    "x": 0.3539559841156006,
                    "y": 0.6612021923065186
                },
                {
                    "x": 0.04342653229832649,
                    "y": 0.6612021923065186
                }
            ]
        }
    },
    {
        "id": "28",
        "key": "DES1",
        "resultText": "CORE MZ EE25 25.4*18.9*6.3 A10 U10000",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04342653229832649,
                    "y": 0.7095417976379395
                },
                {
                    "x": 0.35455086827278137,
                    "y": 0.7095417976379395
                },
                {
                    "x": 0.35455086827278137,
                    "y": 0.7183690667152405
                },
                {
                    "x": 0.04342653229832649,
                    "y": 0.7183690667152405
                }
            ]
        }
    },
    {
        "id": "29",
        "key": "DES1",
        "resultText": "BOBBIN LUG PM9630 EE25 H 4P",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04223676398396492,
                    "y": 0.7658680081367493
                },
                {
                    "x": 0.27007734775543213,
                    "y": 0.7658680081367493
                },
                {
                    "x": 0.27007734775543213,
                    "y": 0.7742748856544495
                },
                {
                    "x": 0.04223676398396492,
                    "y": 0.7742748856544495
                }
            ]
        }
    },
    {
        "id": "30",
        "key": "DES1",
        "resultText": "CORE MZ EP13 12.5*13*8.8 NH9 U3300",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.042831648141145706,
                    "y": 0.8802017569541931
                },
                {
                    "x": 0.327781081199646,
                    "y": 0.8802017569541931
                },
                {
                    "x": 0.327781081199646,
                    "y": 0.8894493579864502
                },
                {
                    "x": 0.042831648141145706,
                    "y": 0.8894493579864502
                }
            ]
        }
    },
    {
        "id": "31",
        "key": "INVDATE",
        "resultText": "05/22/2023",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6097561120986938,
                    "y": 0.13408994674682617
                },
                {
                    "x": 0.6924449801445007,
                    "y": 0.13408994674682617
                },
                {
                    "x": 0.6924449801445007,
                    "y": 0.1437578797340393
                },
                {
                    "x": 0.6097561120986938,
                    "y": 0.1437578797340393
                }
            ]
        }
    },
    {
        "id": "32",
        "key": "PRICE",
        "resultText": "0.03",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.49516603350639343
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.49516603350639343
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.503572940826416
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.503572940826416
                }
            ]
        }
    },
    {
        "id": "33",
        "key": "PRICE",
        "resultText": "0.08",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.5527532696723938
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.5527532696723938
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.561160147190094
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.561160147190094
                }
            ]
        }
    },
    {
        "id": "34",
        "key": "PRICE",
        "resultText": "0.08",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.6094998121261597
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.6094998121261597
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.6174863576889038
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.6174863576889038
                }
            ]
        }
    },
    {
        "id": "35",
        "key": "PRICE",
        "resultText": "0.08",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.6666666865348816
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.6666666865348816
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.6746532320976257
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.6746532320976257
                }
            ]
        }
    },
    {
        "id": "36",
        "key": "PRICE",
        "resultText": "0.05",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.7242538928985596
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.7242538928985596
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.7318201065063477
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.7318201065063477
                }
            ]
        }
    },
    {
        "id": "37",
        "key": "PRICE",
        "resultText": "0.03",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.7805801033973694
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.7805801033973694
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.7885666489601135
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.7885666489601135
                }
            ]
        }
    },
    {
        "id": "38",
        "key": "PRICE",
        "resultText": "0.03",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.8457335233688354
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.8457335233688354
                }
            ]
        }
    },
    {
        "id": "39",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5722783803939819,
                    "y": 0.49600672721862793
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.49600672721862793
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.5048339366912842
                },
                {
                    "x": 0.5722783803939819,
                    "y": 0.5048339366912842
                }
            ]
        }
    },
    {
        "id": "40",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5722783803939819,
                    "y": 0.5531736016273499
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.5531736016273499
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.5620008111000061
                },
                {
                    "x": 0.5722783803939819,
                    "y": 0.5620008111000061
                }
            ]
        }
    },
    {
        "id": "41",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5722783803939819,
                    "y": 0.6099201440811157
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.6099201440811157
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.618747353553772
                },
                {
                    "x": 0.5722783803939819,
                    "y": 0.618747353553772
                }
            ]
        }
    },
    {
        "id": "42",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5722783803939819,
                    "y": 0.6670870184898376
                },
                {
                    "x": 0.5978584289550781,
                    "y": 0.6670870184898376
                },
                {
                    "x": 0.5978584289550781,
                    "y": 0.6746532320976257
                },
                {
                    "x": 0.5722783803939819,
                    "y": 0.6746532320976257
                }
            ]
        }
    },
    {
        "id": "43",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5716835260391235,
                    "y": 0.7242538928985596
                },
                {
                    "x": 0.5978584289550781,
                    "y": 0.7242538928985596
                },
                {
                    "x": 0.5978584289550781,
                    "y": 0.7330811023712158
                },
                {
                    "x": 0.5716835260391235,
                    "y": 0.7330811023712158
                }
            ]
        }
    },
    {
        "id": "44",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5722783803939819,
                    "y": 0.7810004353523254
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.7810004353523254
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.7902479767799377
                },
                {
                    "x": 0.5722783803939819,
                    "y": 0.7902479767799377
                }
            ]
        }
    },
    {
        "id": "45",
        "key": "INVCURR",
        "resultText": "USD",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.5722783803939819,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.8377469778060913
                },
                {
                    "x": 0.5984532833099365,
                    "y": 0.8461538553237915
                },
                {
                    "x": 0.5722783803939819,
                    "y": 0.8461538553237915
                }
            ]
        }
    },
    {
        "id": "46",
        "key": "SOLDCODE",
        "resultText": "D0047243",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.14455680549144745,
                    "y": 0.12105926871299744
                },
                {
                    "x": 0.21058893203735352,
                    "y": 0.12105926871299744
                },
                {
                    "x": 0.21058893203735352,
                    "y": 0.12946616113185883
                },
                {
                    "x": 0.14455680549144745,
                    "y": 0.12946616113185883
                }
            ]
        }
    },
    {
        "id": "47",
        "key": "INVNO",
        "resultText": "9411293151",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6103509664535522,
                    "y": 0.12063892185688019
                },
                {
                    "x": 0.6924449801445007,
                    "y": 0.12063892185688019
                },
                {
                    "x": 0.6924449801445007,
                    "y": 0.12946616113185883
                },
                {
                    "x": 0.6103509664535522,
                    "y": 0.12946616113185883
                }
            ]
        }
    },
    {
        "id": "48",
        "key": "PRODUCT_ID",
        "resultText": "(5171470500)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04580606892704964,
                    "y": 0.5098780989646912
                },
                {
                    "x": 0.13979773223400116,
                    "y": 0.5098780989646912
                },
                {
                    "x": 0.13979773223400116,
                    "y": 0.5187053680419922
                },
                {
                    "x": 0.04580606892704964,
                    "y": 0.5187053680419922
                }
            ]
        }
    },
    {
        "id": "49",
        "key": "PRODUCT_ID",
        "resultText": "(4120604019)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04580606892704964,
                    "y": 0.566624641418457
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.566624641418457
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.5762925744056702
                },
                {
                    "x": 0.04580606892704964,
                    "y": 0.5762925744056702
                }
            ]
        }
    },
    {
        "id": "50",
        "key": "PRODUCT_ID",
        "resultText": "(4120604019)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04580606892704964,
                    "y": 0.623791515827179
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.623791515827179
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.6334594488143921
                },
                {
                    "x": 0.04580606892704964,
                    "y": 0.6334594488143921
                }
            ]
        }
    },
    {
        "id": "51",
        "key": "PRODUCT_ID",
        "resultText": "(4120604019)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04402141645550728,
                    "y": 0.6805380582809448
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.6805380582809448
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.690205991268158
                },
                {
                    "x": 0.04402141645550728,
                    "y": 0.690205991268158
                }
            ]
        }
    },
    {
        "id": "52",
        "key": "PRODUCT_ID",
        "resultText": "(5170310100)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04640095308423042,
                    "y": 0.7381252646446228
                },
                {
                    "x": 0.13979773223400116,
                    "y": 0.7381252646446228
                },
                {
                    "x": 0.13979773223400116,
                    "y": 0.7473728656768799
                },
                {
                    "x": 0.04640095308423042,
                    "y": 0.7473728656768799
                }
            ]
        }
    },
    {
        "id": "53",
        "key": "PRODUCT_ID",
        "resultText": "(4150085200)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04521118476986885,
                    "y": 0.7952921390533447
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.7952921390533447
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.804119348526001
                },
                {
                    "x": 0.04521118476986885,
                    "y": 0.804119348526001
                }
            ]
        }
    },
    {
        "id": "54",
        "key": "PRODUCT_ID",
        "resultText": "(4150085200)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.04580606892704964,
                    "y": 0.8520386815071106
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.8520386815071106
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.8608658909797668
                },
                {
                    "x": 0.04580606892704964,
                    "y": 0.8608658909797668
                }
            ]
        }
    },
    {
        "id": "56",
        "key": "AMOUNT",
        "resultText": "406.16",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.8649613261222839,
                    "y": 0.4497688114643097
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.4497688114643097
                },
                {
                    "x": 0.9179059863090515,
                    "y": 0.4573350250720978
                },
                {
                    "x": 0.8649613261222839,
                    "y": 0.4573350250720978
                }
            ]
        }
    },
    {
        "id": "57",
        "key": "AMOUNT",
        "resultText": "956.30",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.8667460083961487,
                    "y": 0.5208070874214172
                },
                {
                    "x": 0.9167162179946899,
                    "y": 0.5208070874214172
                },
                {
                    "x": 0.9167162179946899,
                    "y": 0.5300546288490295
                },
                {
                    "x": 0.8667460083961487,
                    "y": 0.5300546288490295
                }
            ]
        }
    },
    {
        "id": "58",
        "key": "TOTGROW",
        "resultText": "69.600\nKGS.",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.3884592652320862,
                    "y": 0.6485918164253235
                },
                {
                    "x": 0.48542535305023193,
                    "y": 0.6485918164253235
                },
                {
                    "x": 0.48542535305023193,
                    "y": 0.6574190855026245
                },
                {
                    "x": 0.3884592652320862,
                    "y": 0.6574190855026245
                }
            ]
        }
    },
    {
        "id": "60",
        "key": "DES1",
        "resultText": "WIRE PFA 211989 #228 GRN 180 600V",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.042831648141145706,
                    "y": 0.49180328845977783
                },
                {
                    "x": 0.32183223962783813,
                    "y": 0.49180328845977783
                },
                {
                    "x": 0.32183223962783813,
                    "y": 0.5006304979324341
                },
                {
                    "x": 0.042831648141145706,
                    "y": 0.5006304979324341
                }
            ]
        }
    },
    {
        "id": "61",
        "key": "PRICE",
        "resultText": "0.09",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.6983938217163086,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.732302188873291,
                    "y": 0.45775535702705383
                },
                {
                    "x": 0.6983938217163086,
                    "y": 0.45775535702705383
                }
            ]
        }
    },
    {
        "id": "62",
        "key": "SHIPMARK",
        "resultText": "DELTA\nMYANMAR\nC/NO:1-8",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.10053539276123047,
                    "y": 0.5931063294410706
                },
                {
                    "x": 0.1683521717786789,
                    "y": 0.5931063294410706
                },
                {
                    "x": 0.1683521717786789,
                    "y": 0.629256010055542
                },
                {
                    "x": 0.10053539276123047,
                    "y": 0.629256010055542
                }
            ]
        }
    },
    {
        "id": "63",
        "key": "PRODUCT_ID",
        "resultText": "(4033713610)",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.04580606892704964,
                    "y": 0.4636401832103729
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.4636401832103729
                },
                {
                    "x": 0.14039261639118195,
                    "y": 0.4728877544403076
                },
                {
                    "x": 0.04580606892704964,
                    "y": 0.4728877544403076
                }
            ]
        }
    },
    {
        "id": "64",
        "key": "PRODUCT_ID",
        "resultText": "4033713610",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.04223676398396492,
                    "y": 0.4779319167137146
                },
                {
                    "x": 0.12671029567718506,
                    "y": 0.4779319167137146
                },
                {
                    "x": 0.12671029567718506,
                    "y": 0.48591846227645874
                },
                {
                    "x": 0.04223676398396492,
                    "y": 0.48591846227645874
                }
            ]
        }
    },
    {
        "id": "65",
        "key": "FSELLAD1_C",
        "resultText": "PLOT NO. (91) AND (92), MYAY TAING\nQUARTER NO. (25), SHWE LINBAN\nINDUSTRIAL ZONE, HLAING THAR YAR\nTOWNSHIP\n11401 YANGON MYANMAR",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.14336703717708588,
                    "y": 0.2774274945259094
                },
                {
                    "x": 0.4301011264324188,
                    "y": 0.2774274945259094
                },
                {
                    "x": 0.4301011264324188,
                    "y": 0.3425809144973755
                },
                {
                    "x": 0.14336703717708588,
                    "y": 0.3425809144973755
                }
            ]
        }
    },
    {
        "id": "66",
        "key": "TOTNETW",
        "resultText": "64.700\nKGS.",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.3890541195869446,
                    "y": 0.6637242436408997
                },
                {
                    "x": 0.4860202372074127,
                    "y": 0.6637242436408997
                },
                {
                    "x": 0.4860202372074127,
                    "y": 0.6717107892036438
                },
                {
                    "x": 0.3890541195869446,
                    "y": 0.6717107892036438
                }
            ]
        }
    },
    {
        "id": "67",
        "key": "INVQTY",
        "resultText": "4,512.888MTR",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.4533016085624695,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.5568113923072815,
                    "y": 0.45859605073928833
                },
                {
                    "x": 0.4533016085624695,
                    "y": 0.45859605073928833
                }
            ]
        }
    },
    {
        "id": "68",
        "key": "INVQTY",
        "resultText": "5,138PCE & 6,200NPR & 4,512.888MTR",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.2706722319126129,
                    "y": 0.5203866958618164
                },
                {
                    "x": 0.5574063062667847,
                    "y": 0.5203866958618164
                },
                {
                    "x": 0.5574063062667847,
                    "y": 0.5308953523635864
                },
                {
                    "x": 0.2706722319126129,
                    "y": 0.5308953523635864
                }
            ]
        }
    },
    {
        "id": "70",
        "key": "PURCTRY",
        "resultText": "MYANMAR",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.2534205913543701,
                    "y": 0.20638923346996307
                },
                {
                    "x": 0.31231409311294556,
                    "y": 0.20638923346996307
                },
                {
                    "x": 0.31231409311294556,
                    "y": 0.21395544707775116
                },
                {
                    "x": 0.2534205913543701,
                    "y": 0.21395544707775116
                }
            ]
        }
    },
    {
        "id": "72",
        "key": "PURCTRY",
        "resultText": "MYANMAR",
        "confidence": 1,
        "hightLightZone": {
            "page": "2",
            "boundingBox": [
                {
                    "x": 0.2534205913543701,
                    "y": 0.20638923346996307
                },
                {
                    "x": 0.31231409311294556,
                    "y": 0.20638923346996307
                },
                {
                    "x": 0.31231409311294556,
                    "y": 0.21395544707775116
                },
                {
                    "x": 0.2534205913543701,
                    "y": 0.21395544707775116
                }
            ]
        }
    }
]

export const mockupDataTypeProcess2 = [
    {
        "id": "0",
        "key": "WEIGHTU",
        "resultText": "(KGS)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6864961385726929,
                    "y": 0.41782262921333313
                },
                {
                    "x": 0.7227840423583984,
                    "y": 0.41782262921333313
                },
                {
                    "x": 0.7227840423583984,
                    "y": 0.4266498386859894
                },
                {
                    "x": 0.6864961385726929,
                    "y": 0.4266498386859894
                }
            ]
        }
    },
    {
        "id": "1",
        "key": "GROUP_ROW_INDEX",
        "resultText": "T3M1235100 (5171470500)\n5171470500\nBOBBIN LUG PM9630 EP13 Η 9P L1=3.0+/-0.2\n2,138\nPCE\n1.30",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11064842343330383,
                    "y": 0.4779319167137146
                },
                {
                    "x": 0.7174301147460938,
                    "y": 0.4779319167137146
                },
                {
                    "x": 0.7174301147460938,
                    "y": 0.5296342968940735
                },
                {
                    "x": 0.11064842343330383,
                    "y": 0.5296342968940735
                }
            ]
        }
    },
    {
        "id": "2",
        "key": "GROUP_ROW_INDEX",
        "resultText": "T3M1234042 (4120604019)\n4120604019\nCORE MZ EE25 25.4*18.9*6.3 A10 U10000\n1,120\nNPR\n11.90",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11005353927612305,
                    "y": 0.5350987911224365
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.5350987911224365
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.5863808393478394
                },
                {
                    "x": 0.11005353927612305,
                    "y": 0.5863808393478394
                }
            ]
        }
    },
    {
        "id": "3",
        "key": "GROUP_ROW_INDEX",
        "resultText": "T3M1234042 (4120604019)\n4120604019\nCORE MZ EE25 25.4*18.9*6.3 A10 U10000\n1,120\nNPR\n11.90",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11005353927612305,
                    "y": 0.5922656655311584
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.5922656655311584
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.6435477137565613
                },
                {
                    "x": 0.11005353927612305,
                    "y": 0.6435477137565613
                }
            ]
        }
    },
    {
        "id": "4",
        "key": "GROUP_ROW_INDEX",
        "resultText": "T3M1234042 (5170310100)\n5170310100\nBOBBIN LUG PM9630 ΕΕ25 Η 4P\nT3M1235100 3,000\nPCE\n10.80",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11005353927612305,
                    "y": 0.7061790823936462
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.7061790823936462
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.7709121704101562
                },
                {
                    "x": 0.11005353927612305,
                    "y": 0.7709121704101562
                }
            ]
        }
    },
    {
        "id": "5",
        "key": "GROUP_ROW_INDEX",
        "resultText": "(4150085200)\n4150085200\nCORE MZ EP13 12.5*13*8.8 NH9 U3300\n692\nNPR\n4.50 T3M1234042",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.10945865511894226,
                    "y": 0.8343842029571533
                },
                {
                    "x": 0.7168352007865906,
                    "y": 0.8343842029571533
                },
                {
                    "x": 0.7168352007865906,
                    "y": 0.8852459192276001
                },
                {
                    "x": 0.10945865511894226,
                    "y": 0.8852459192276001
                }
            ]
        }
    },
    {
        "id": "6",
        "key": "PRODUCT_ID",
        "resultText": "(5171470500)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.4922236204147339
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.4922236204147339
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.501471221446991
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.501471221446991
                }
            ]
        }
    },
    {
        "id": "7",
        "key": "PRODUCT_ID",
        "resultText": "(4120604019)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.5489701628684998
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.5489701628684998
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.559058427810669
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.559058427810669
                }
            ]
        }
    },
    {
        "id": "8",
        "key": "PRODUCT_ID",
        "resultText": "(4120604019)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.6057167053222656
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.6057167053222656
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.6158049702644348
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.6158049702644348
                }
            ]
        }
    },
    {
        "id": "9",
        "key": "PRODUCT_ID",
        "resultText": "(4120604019)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.6628835797309875
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.6628835797309875
                },
                {
                    "x": 0.3837001919746399,
                    "y": 0.6729718446731567
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.6729718446731567
                }
            ]
        }
    },
    {
        "id": "10",
        "key": "PRODUCT_ID",
        "resultText": "(5170310100)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.7204707860946655
                },
                {
                    "x": 0.3842950761318207,
                    "y": 0.7204707860946655
                },
                {
                    "x": 0.3842950761318207,
                    "y": 0.7292979955673218
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.7292979955673218
                }
            ]
        }
    },
    {
        "id": "11",
        "key": "PRODUCT_ID",
        "resultText": "(4150085200)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.7780579924583435
                },
                {
                    "x": 0.3842950761318207,
                    "y": 0.7780579924583435
                },
                {
                    "x": 0.3842950761318207,
                    "y": 0.7864649295806885
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.7864649295806885
                }
            ]
        }
    },
    {
        "id": "12",
        "key": "PRODUCT_ID",
        "resultText": "(4150085200)",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.2897084951400757,
                    "y": 0.8348045349121094
                },
                {
                    "x": 0.3842950761318207,
                    "y": 0.8348045349121094
                },
                {
                    "x": 0.3842950761318207,
                    "y": 0.8436318039894104
                },
                {
                    "x": 0.2897084951400757,
                    "y": 0.8436318039894104
                }
            ]
        }
    },
    {
        "id": "13",
        "key": "DES",
        "resultText": "BOBBIN LUG PM9630 EP13 Η 9P L1=3.0+/-0.2",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11064842343330383,
                    "y": 0.5195460319519043
                },
                {
                    "x": 0.44556811451911926,
                    "y": 0.5195460319519043
                },
                {
                    "x": 0.44556811451911926,
                    "y": 0.5296342968940735
                },
                {
                    "x": 0.11064842343330383,
                    "y": 0.5296342968940735
                }
            ]
        }
    },
    {
        "id": "14",
        "key": "DES",
        "resultText": "CORE MZ EE25 25.4*18.9*6.3 A10 U10000",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11064842343330383,
                    "y": 0.5779739618301392
                },
                {
                    "x": 0.42117786407470703,
                    "y": 0.5779739618301392
                },
                {
                    "x": 0.42117786407470703,
                    "y": 0.5863808393478394
                },
                {
                    "x": 0.11064842343330383,
                    "y": 0.5863808393478394
                }
            ]
        }
    },
    {
        "id": "15",
        "key": "DES",
        "resultText": "CORE MZ EE25 25.4*18.9*6.3 A10 U10000",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11064842343330383,
                    "y": 0.6347204446792603
                },
                {
                    "x": 0.42058297991752625,
                    "y": 0.6347204446792603
                },
                {
                    "x": 0.42058297991752625,
                    "y": 0.6435477137565613
                },
                {
                    "x": 0.11064842343330383,
                    "y": 0.6435477137565613
                }
            ]
        }
    },
    {
        "id": "16",
        "key": "DES",
        "resultText": "BOBBIN LUG PM9630 ΕΕ25 Η 4P",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11005353927612305,
                    "y": 0.748213529586792
                },
                {
                    "x": 0.3372992277145386,
                    "y": 0.748213529586792
                },
                {
                    "x": 0.3372992277145386,
                    "y": 0.757040798664093
                },
                {
                    "x": 0.11005353927612305,
                    "y": 0.757040798664093
                }
            ]
        }
    },
    {
        "id": "17",
        "key": "DES",
        "resultText": "CORE MZ EP13 12.5*13*8.8 NH9 U3300",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.11005353927612305,
                    "y": 0.8621269464492798
                },
                {
                    "x": 0.39500296115875244,
                    "y": 0.8621269464492798
                },
                {
                    "x": 0.39500296115875244,
                    "y": 0.8713745474815369
                },
                {
                    "x": 0.11005353927612305,
                    "y": 0.8713745474815369
                }
            ]
        }
    },
    {
        "id": "18",
        "key": "WEIGHT",
        "resultText": "1.30",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.680547297000885,
                    "y": 0.4922236204147339
                },
                {
                    "x": 0.7174301147460938,
                    "y": 0.4922236204147339
                },
                {
                    "x": 0.7174301147460938,
                    "y": 0.500210165977478
                },
                {
                    "x": 0.680547297000885,
                    "y": 0.500210165977478
                }
            ]
        }
    },
    {
        "id": "19",
        "key": "WEIGHT",
        "resultText": "11.90",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.672218918800354,
                    "y": 0.5489701628684998
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.5489701628684998
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.5573770403862
                },
                {
                    "x": 0.672218918800354,
                    "y": 0.5573770403862
                }
            ]
        }
    },
    {
        "id": "20",
        "key": "WEIGHT",
        "resultText": "11.90",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6710291504859924,
                    "y": 0.6057167053222656
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.6057167053222656
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.6141235828399658
                },
                {
                    "x": 0.6710291504859924,
                    "y": 0.6141235828399658
                }
            ]
        }
    },
    {
        "id": "21",
        "key": "WEIGHT",
        "resultText": "8.10",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6799523830413818,
                    "y": 0.6637242436408997
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.6637242436408997
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.6712904572486877
                },
                {
                    "x": 0.6799523830413818,
                    "y": 0.6712904572486877
                }
            ]
        }
    },
    {
        "id": "22",
        "key": "WEIGHT",
        "resultText": "10.80",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6716240048408508,
                    "y": 0.7200504541397095
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.7200504541397095
                },
                {
                    "x": 0.715645432472229,
                    "y": 0.7284573316574097
                },
                {
                    "x": 0.6716240048408508,
                    "y": 0.7284573316574097
                }
            ]
        }
    },
    {
        "id": "23",
        "key": "WEIGHT",
        "resultText": "11.50",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.672218918800354,
                    "y": 0.7772173285484314
                },
                {
                    "x": 0.7150505781173706,
                    "y": 0.7772173285484314
                },
                {
                    "x": 0.7150505781173706,
                    "y": 0.7852038741111755
                },
                {
                    "x": 0.672218918800354,
                    "y": 0.7852038741111755
                }
            ]
        }
    },
    {
        "id": "24",
        "key": "WEIGHT",
        "resultText": "4.50",
        "confidence": 1,
        "hightLightZone": {
            "page": "0",
            "boundingBox": [
                {
                    "x": 0.6799523830413818,
                    "y": 0.8348045349121094
                },
                {
                    "x": 0.7168352007865906,
                    "y": 0.8348045349121094
                },
                {
                    "x": 0.7168352007865906,
                    "y": 0.8427910804748535
                },
                {
                    "x": 0.6799523830413818,
                    "y": 0.8427910804748535
                }
            ]
        }
    },
    {
        "id": "29",
        "key": "WEIGHT",
        "resultText": "4.70",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.6799523830413818,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.7162403464317322,
                    "y": 0.45775535702705383
                },
                {
                    "x": 0.6799523830413818,
                    "y": 0.45775535702705383
                }
            ]
        }
    },
    {
        "id": "30",
        "key": "TOTPACK",
        "resultText": "8 CTNS",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.10232004523277283,
                    "y": 0.5355191230773926
                },
                {
                    "x": 0.15169541537761688,
                    "y": 0.5355191230773926
                },
                {
                    "x": 0.15169541537761688,
                    "y": 0.5430853366851807
                },
                {
                    "x": 0.10232004523277283,
                    "y": 0.5430853366851807
                }
            ]
        }
    },
    {
        "id": "31",
        "key": "PRODUCT_ID",
        "resultText": "(4033713610)",
        "confidence": 1,
        "hightLightZone": {
            "page": "1",
            "boundingBox": [
                {
                    "x": 0.2885187268257141,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.38488996028900146,
                    "y": 0.44934847950935364
                },
                {
                    "x": 0.38488996028900146,
                    "y": 0.45859605073928833
                },
                {
                    "x": 0.2885187268257141,
                    "y": 0.45859605073928833
                }
            ]
        }
    }
]